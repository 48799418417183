import Vue from "vue";
import vuetify from "./plugins/vuetify";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import aws_exports from "./aws-exports";
import axios from "axios";
import store from "./store";
import VueAxios from "vue-axios";
import filters from "./filters";
import VueRouter from "vue-router";
import App from "./App.vue";
import FieldEventsApp from "./components/FieldEventsApp.vue";
import Events from "./components/Events.vue";
import Event from "./components/Event.vue";
import Account from "./components/Account.vue";
import About from "./components/About.vue";
import VueScreenSize from "vue-screen-size";
import Terms from "./components/Terms.vue";
import Privacy from "./components/Privacy.vue";
import Info from "./components/Info.vue";
import Faq from "./components/Faq.vue";
import NotFound from "./components/NotFound.vue";
import Doane from "./components/Doane.vue"
import fullscreen from 'vue-fullscreen'

Vue.use(VueRouter);
Amplify.configure(aws_exports);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueAxios, axios);
Vue.use(VueScreenSize);
Vue.use(fullscreen)
Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes: [
    {
      path: "/",
      name: "events",
      props: (route) => ({ query: route.query.meetId }),
      component: Events,
    },
    {
      path: "/event",
      name: "event",
      props: (route) => ({ query: route.query.eventId }),
      component: Event,
    },
    { path: "/app", name: "app", component: FieldEventsApp },
    { path: "/about", name: "about", component: About },
    { path: "/account", name: "account", component: Account },
    { path: "/terms", name: "terms", component: Terms },
    { path: "/privacy", name: "privacy", component: Privacy },
    { path: "/doane", name: "doane", 
      props: (route) => ({ port: route.query.port }),
      component: Doane 
    },
    {
      path: "/info",
      name: "info",
      props: (route) => ({ query: route.query.page }),
      component: Info,
    },
    {
      path: "/faq",
      name: "faq",
      component: Faq,
    },
    { path: "*", name: "notFound", component: NotFound },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

function patchSignIn() {
  let usernameComponent = Vue.component("amplify-username-field");
  let watches = (usernameComponent.options.watch =
    usernameComponent.options.watch || {});
  watches.username = function () {
    this.usernameChanged();
  };
  watches.email = function () {
    this.emailChanged();
  };
}
patchSignIn();
