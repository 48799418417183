<template>
  <div class="info-page">
    <h1 class="info-page-title">{{ pageTitle }}</h1>

    <div v-if="page == 'fieldeventsLive'">
      <p>
        If "FieldEvents Live" is enabled in the app, spectators and athletes can
        view real time the status of who's up, how many attempts are remaining,
        as well as see the competition scoreboard from fieldevents.net or
        through the app.
      </p>
      <p class="info-page-subheader">FieldEvents Live features:</p>
    </div>

    <v-container v-if="$vssWidth > 800">
      <v-row>
        <v-col v-for="item in infoCards.slice(0, 3)" :key="item.title">
          <info-card :item="item" />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="item in infoCards.slice(3, 6)" :key="item.title">
          <info-card :item="item" />
        </v-col>
      </v-row>
    </v-container>
    <!-- tablet cards -->
    <v-container v-if="$vssWidth <= 800 && $vssWidth > 600">
      <v-row>
        <v-col v-for="item in infoCards.slice(0, 2)" :key="item.title">
          <info-card :item="item" />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="item in infoCards.slice(2, 4)" :key="item.title">
          <info-card :item="item" />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="item in infoCards.slice(4, 6)" :key="item.title">
          <info-card :item="item" />
        </v-col>
      </v-row>
    </v-container>
    <!-- mobile cards -->
    <v-container v-if="$vssWidth <= 600">
      <v-row v-for="item in infoCards" :key="item.title">
        <v-col>
          <info-card :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import InfoCard from "./partials/InfoCard.vue";

export default {
  name: "Info",
  components: { InfoCard },
  props: {},
  data() {
    return {};
  },
  computed: {
    page() {
      if (!_.isNil(this.$route.params.page) && this.$route.params.page != "") {
        return this.$route.params.page;
      } else {
        return this.$route.query.page;
      }
    },
    pageTitle() {
      let title = "";
      switch (this.page) {
        case "fieldeventsLive":
          title = "Field Events Live";
          break;
        case "settings":
          title = "Settings";
          break;
        case "vCompetition":
          title = "Vertical Competition";
          break;
        case "hCompetition":
          title = "Horizontal Competition";
          break;
        case "scoreboard":
          title = "Scoreboard";
          break;
        default:
          title = "";
      }

      return title;
    },
    infoCards() {
      let items = [];
      if (this.page != "") {
        switch (this.page) {
          case "fieldeventsLive":
            items = [
              {
                title: "Real time results",
                body: "Competitions can be viewed in real time from the app or the fieldevents.net site.",
              },
              {
                title: "Competitor order",
                body: "Keep track of the current order of competitors to know who the next five competitors are to jump.",
              },
              {
                title: "Scoreboard view",
                body: "Switch to the scoreboard view to display only the current competitor jumping. This is perfect for displaying the competition on a large screen to a large group of spectators.",
              },
              {
                title: "Competitor summary",
                body: "Click on a competitor to see a summary of their progress so far in the competition.",
              },
            ];
            break;
          case "settings":
            items = [
              {
                title: "Meet Name",
                body: "This field is required. Please enter a unique meet name so that your event can easily be found on FieldEvents.net.",
              },
              {
                title: "Competition Name",
                body: "This field is required. Please enter the name of the field event you are creating.",
              },
              {
                title: "Event Type",
                body: 'This field is required. For Pole vault and High jump, please select "Vertical". For all other field events, select "Horizontal".',
              },
              {
                title: "Competition Date",
                body: "Please select the date for your competition.",
              },
              {
                title: "Use Alive Groups",
                body: '(Only seen on High Jump or Pole Vault). This allows the competition to automatically switch to use alive groups if the number of competitors in at a height is greater than the threshold. If this is the case, then only five competitors will be "Active" until a competitor is removed from the height. When there is a large amount of competitors in at a single height, Alive Groups can be beneficial in keeping competitors warmed up and active for their attempts.',
              },
              {
                title: "Threshold",
                body: "	(Only seen on High Jump or Pole Vault) This field changes the number of competitors that must be in at a height before Alive Groups will be enabled. As soon as the number of competitors is less than this value, the height will no longer use Alive Groups.",
              },
              {
                title: "# Flights",
                body: "This field represents the total number of flights in a competition. There must be at least one competitor in each flight.",
              },
              {
                title: "# Attempts",
                body: "This field changes the number of attempts a competitor starts with in a flight.",
              },
              {
                title: "Units Preferred",
                body: "This setting will show which ever selected unit type as priority over the other.",
              },
              {
                title: "Create Final",
                body: 'If Create Final is enabled, after all preliminary flights are completed a final will be created for the event. Depending on the fields "# Athletes in Final" and "# Attempts in Final", the number of athletes and the number of attempts in the final will be set.',
              },
              {
                title: "FieldEvents Live",
                body: 'This feature is only available to users with the "FieldEvents Pro Subscription". If it is enabled, results will be pushed to FieldEvents.net. This site allows anyone to view the competition scoreboard in real time. Please note that a stable internet connection to the device running FieldEvents is required for FieldEvents live to work.',
              },
            ];
            break;
          case "vCompetition":
            items = [
              {
                title: "Menu",
                body: "Pressing the menu button in the top right brings up several options for managing the competition. Each item will be explained below.",
              },
              {
                title: "Competitor Details",
                body: 'The Competitor Details show all the competitors that are active in the competition. Competitors that are in at the current height are marked in blue. As soon as competitors have a mark, their best mark for the competition will also show here. If a competitor is suspended, this will be indicated with the time that they were marked suspended. A competitor\'s name, team, and starting height can be changed by selecting "Edit" in the competitor details, and then clicking on a competitor.',
              },
              {
                title: "Pass to Height",
                body: "Pass to Height allows you to select a height to pass a competitor in the competition to.",
              },
              {
                title: "Set Athlete Up",
                body: "The Set Athlete Up button will allow you to select a competitor that is currently in height. Whichever competitor is selected will then be set to up.",
              },
              {
                title: "Suspend/Unsuspend",
                body: "The Suspend/Unsuspend button will allow you to select/deselect competitors you want to suspend temporarily. This is meant for athletes that are still in the competition but need to be placed in a suspended state. For example, this should be used if a competitor needs to run a race while the current competition is still active. An alert will be shown before advancing to the next flight if there are suspended competitors.",
              },
              {
                title: "Enable/Disable FieldEvents Live",
                body: "The Enable/Disable Alive Groups button allows the official to manually turn off/turn on alive groups if desired. Please note that turning on or off Alive Groups will not take place until the next height.",
              },
              {
                title: "End Competition",
                body: "The End Competition button will finish the competition. This also allows results to be exported from the menu. If you accidently ended the competition, please use the Undo button to re-enable it.",
              },
              {
                title: "Exit Competition",
                body: "The Exit Competition will take you back to the Home screen. Since all competitions are saved you can restart or continue the competition from the Home page in the app.",
              },
              {
                title: "Restart Competition",
                body: "Restarting a competition will set all settings and data for a competition to the intitial start values.",
              },
              {
                title: "Height",
                body: "The Height field represents the current height of the competition. Both Metric and Imperial are displayed.",
              },
              {
                title: "Competitor Up",
                body: "The Competitor Up Field represents the competitor that is currently up in the competition.",
              },
              {
                title: "Make",
                body: "The Make button should be pressed when a competitor clears the bar.",
              },
              {
                title: "Miss",
                body: "The Miss button should be presssed when a competior does not clear the height.",
              },
              {
                title: "Pass",
                body: "The Pass button should be pressed when a competitor wants to pass the current height. If the competitor would like to pass more than one height. Please use the Pass to Height option in the competition menu.",
              },
              {
                title: "Retire",
                body: "The Retire button should be pressed when a competitor would like to retire from the competition. This will remove them from the competition.",
              },
              {
                title: "Order",
                body: "The Order list will show the order of the next five competitors at the height as well as their number of attempts remaining.",
              },
              {
                title: "Undo",
                body: "The Undo button will undo the previous action made to the competition.",
              },
              {
                title: "Scoreboard",
                body: "	The Scoreboard button will take you to the scoreboard view. This will show an overview of the competition as well as the place of competitors. Please note that the place for High Jump and Pole Vault is determined by a competitors highest height cleared as well as total number of misses.",
              },
            ];
            break;
          case "hCompetition":
            items = [
              {
                title: "Menu",
                body: "Pressing the menu button in the top right brings up several options for managing the competition. Each item will be explained below.",
              },
              {
                title: "Competitor Details",
                body: 'The Competitor Details show all the competitors that are active in the competition. Competitors that are in at the current height are marked in blue. As soon as competitors have a mark, their best mark for the competition will also show here. If a competitor is suspended, this will be indicated with the time that they were marked suspended. A competitor\'s name, team, and starting height can be changed by selecting "Edit" in the competitor details, and then clicking on a competitor.',
              },
              {
                title: "Set Athlete Up",
                body: "The Set Athlete Up button will allow you to select a competitor that is currently in height. Whichever competitor is selected will then be set to up.",
              },
              {
                title: "Suspend/Unsuspend",
                body: "The Suspend/Unsuspend button will allow you to select/deselect competitors you want to suspend temporarily. This is meant for athletes that are still in the competition but need to be placed in a suspended state. For example, this should be used if a competitor needs to run a race while the current competition is still active. An alert will be shown before advancing to the next flight if there are suspended competitors.",
              },
              {
                title: "Enable/Disable FieldEvents Live",
                body: "The Enable/Disable Alive Groups button allows the official to manually turn off/turn on alive groups if desired. Please note that turning on or off Alive Groups will not take place until the next height.",
              },
              {
                title: "End Competition",
                body: "The End Competition button will finish the competition. This also allows results to be exported from the menu. If you accidently ended the competition, please use the Undo button to re-enable it.",
              },
              {
                title: "Exit Competition",
                body: "The Exit Competition will take you back to the Home screen. Since all competitions are saved you can restart or continue the competition from the Home page in the app.",
              },
              {
                title: "Restart Competition",
                body: "Restarting a competition will set all settings and data for a competition to the intitial start values.",
              },
              {
                title: "Flight",
                body: "The Flight field represents the current flight of the competition.",
              },
              {
                title: "Competitor Up",
                body: "The Competitor Up Field represents the competitor that is currently up in the competition.",
              },
              {
                title: "Mark",
                body: 'The Mark button should be pressed after a value is entered in the mark text field. If units preferred was set to Metric, please use Meters to the nearsest hundredth (ex: 4.51). If the preferred units is Imperial, a view will appear which allows you to type in the mark in feet and inches. Once a value exists in the mark text field, press "Mark" to continue recording the competitor\'s mark.',
              },
              {
                title: "Foul",
                body: "The Foul button should be presssed when a competior scratches or fouls.",
              },
              {
                title: "Pass",
                body: "The Pass button should be pressed when a competitor wants to forfeit their current attempt.",
              },
              {
                title: "Retire",
                body: "The Retire button should be pressed when a competitor would like to retire from the competition. This will remove them from the competition.",
              },
              {
                title: "Order",
                body: "The Order list will show the order of the next five competitors in the flight as well as their number of attempts remaining.",
              },
              {
                title: "Undo",
                body: "The Undo button will undo the previous action made to the competition.",
              },
              {
                title: "Scoreboard",
                body: "The Scoreboard button will take you to the scoreboard view. This will show an overview of the competition as well as the place of competitors.",
              },
            ];
            break;
          case "scoreboard":
            items = [
              {
                title: "Scoreboard",
                body: "This view shows the overview of the competition including the number of competitors remaining in the height/flight as well as in the competition.",
              },
              {
                title: "Export Results",
                body: "Results for a competition can be exported once a competition has ended. This feature can be found in the competition menu.",
              },
            ];
            break;
          default:
            items = [];
        }
      } else {
        items = [];
      }

      return items;
    },
  },

  methods: {},
};
</script>

<style>
.container-app {
  max-width: 40rem;
  margin: auto;
  padding-top: 10px;
}
.info-page .gray-card {
  color: #515457;
  min-height: 100px !important;
}
.info-page .info-title {
  font-size: 26px;
}

.info-page-title {
  margin-top: -60px;
  margin-bottom: 16px;
}
.info-page-subheader {
  font-size: 24px;
  font-weight: 500;
}
.title-td {
  font-size: 16px;
  font-weight: 500;
}
.row-tb {
  padding-bottom: 16px !important;
}
</style>
