<template>
  <div>
    <div class="container-app">
      <h1>FieldEvents App</h1>
      <p  style="text-align: left" class="text-muted pa-4">Improve your track and field events this season with FieldEvents, 
        an intuitive software solution that enhances the experience for officials, 
        athletes, and spectators. FieldEvents provides live results and real-time standings  
       to help athletes and spectators track competition progress. FieldEvents simplifies the job of the official by keeping track of the 
       competitor order and providing alerts to key events like the height changing, new competitors in the rotation, and more. <a
          class="color-orange"
          href="https://apps.apple.com/us/app/fieldevents/id1495383079"
          >Download FieldEvents</a
        > from the App Store for all 
        Apple devices running the latest version of iOS.</p>

      <!-- <p style="text-align: left" class="text-muted pa-4">
        Transform your track meets this season by using a super intuitive tool,
        FieldEvents. By utilizing the FieldEvents app, you will improve the
        experience for the official, athlete, and spectator. Take advantage of
        some of the key features such as live results, alive groups, and real
        time standings. Athletes and spectators can follow along and see their
        progress and order within the competition. FieldEvents is
        <a
          class="color-orange"
          href="https://apps.apple.com/us/app/fieldevents/id1495383079"
          >available on the App Store</a
        >
        for all Apple devices running iOS 14 or newer.
      </p> -->

      <p v-if="$vssWidth <= 600">
        <a href="https://apps.apple.com/us/app/fieldevents/id1495383079"  target="_blank"
          ><img
            class="app-icon"
            src="../assets/FieldEvents-App-Cropped.png"
            alt="FieldEvents App"
        /></a>
      </p>
      <v-btn
        v-else
        class="get-fieldevents-btn mb-9"
        href="https://apps.apple.com/us/app/fieldevents/id1495383079"
        target="_blank"
        color="secondary"
        elevation="14"
        rounded
        x-large>
        Get FieldEvents
      </v-btn>
    </div>
    <iframe class="mt-8 mb-8"  :width="videoWidth" :height="videoWidth/1.777" src="https://www.youtube.com/embed/OZsgUjchsUo" title="FieldEvents App Overview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    <v-container v-if="$vssWidth > 600" class="feature-cards-container">
      <v-row no-gutters class="feature-row">
        <v-col cols="4">
          <div class="feature-description-col-v">
            <h2 class="feature-title">Setup the Competition</h2>
            <span class="feature-description"
              ><p>
                Easily configure the competition within the app or from the
                FieldEvents.net Account page. Add competitors, heights/flights,
                and check them in all in one spot. For vertical competitions
                like high jump and pole vault, enable alive groups to minimize
                competitor wait times. All competition data is stored in your
                FieldEvents account if you purchase the FieldEvents Pro
                subscription ($15 a year).
              </p></span
            >
          </div>
        </v-col>
        <v-col cols="8">
          <a href="https://apps.apple.com/us/app/fieldevents/id1495383079"  target="_blank">
            <img
              class="vertical-device"
              src="../assets/competitor-list-vertical.png"
              alt="Setup the competition"
            />
          </a>
        </v-col>
      </v-row>

      <v-row no-gutters class="feature-row">
        <v-col cols="8">
          <a href="https://apps.apple.com/us/app/fieldevents/id1495383079"  target="_blank">
            <img
              class="horizontal-device"
              src="../assets/competition-horizontal.png"
              alt="Run a competition"
            />
          </a>
        </v-col>
        <v-col cols="4">
          <div class="feature-description-col">
            <h2 class="feature-title" v-html="infoCards[1].title"></h2>
            <span class="feature-description" v-html="infoCards[1].body"></span>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters class="feature-row">
        <v-col cols="4">
          <div class="feature-description-col-v">
            <h2 class="feature-title">New Height Summary</h2>
            <span class="feature-description"
              ><p>
                Never miss any important information like new competitors coming
                in at the height. Each new height will show a summary of any new
                competitors as well as the remaining at the height and still in
                the competition.
              </p></span
            >
          </div>
        </v-col>
        <v-col cols="8">
          <a href="https://apps.apple.com/us/app/fieldevents/id1495383079" target="_blank">
            <img
              class="vertical-device"
              src="../assets/new-height-vertical.png"
              alt="New Height Summary"
            />
          </a>
        </v-col>
      </v-row>

      <v-row no-gutters class="feature-row">
        <v-col cols="8">
          <a href="https://apps.apple.com/us/app/fieldevents/id1495383079" target="_blank">
            <img
              class="horizontal-device"
              src="../assets/suspend-horizontal.png"
              alt="Suspend Competitors"
            />
          </a>
        </v-col>
        <v-col cols="4">
          <div class="feature-description-col">
            <h2 class="feature-title">Suspend Competitors</h2>
            <span class="feature-description"
              ><p>
                There's always one competitor that needs to go run a race in the
                middle of a competition. Suspending competitors will keep them
                in the competition but will remove them from the order until
                they are checked back in. At the end of a height, you will get a
                notice that there is a suspended competitor.
              </p></span
            >
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters class="feature-row">
        <v-col cols="4">
          <div class="feature-description-col-v">
            <h2 class="feature-title" v-html="infoCards[2].title"></h2>
            <span class="feature-description" v-html="infoCards[2].body"></span>
          </div>
        </v-col>
        <v-col cols="8">
          <a href="https://apps.apple.com/us/app/fieldevents/id1495383079" target="_blank">
            <img
              class="vertical-device"
              src="../assets/follow-competitions-vertical.png"
              alt="FieldEvents Live"
            />
          </a>
        </v-col>
      </v-row>

      <v-row no-gutters class="feature-row">
        <v-col cols="8">
          <a href="https://apps.apple.com/us/app/fieldevents/id1495383079" target="_blank">
            <img
              class="horizontal-device"
              src="../assets/laser-integration.png"
              alt="Laser Integration"
            />
          </a>
        </v-col>
        <v-col cols="4">
          <div class="feature-description-col">
            <h2 class="feature-title">Leica Laser Integration</h2>
            <span class="feature-description"
              ><p>
                The app supports connecting a Leica Disto via Bluetooth. 
                Take advantage of this feature for easy and accurate 
                measurements for all horizontal events
              </p></span
            >
          </div>
        </v-col>
      </v-row>
    </v-container>

    
    <!-- mobile cards -->
    <v-container v-if="$vssWidth <= 600">
      <v-expansion-panels multiple>
        <v-expansion-panel v-for="item in infoCards" :key="item.title">
          <v-expansion-panel-header>
            <h3 class="feature-title-mobile" v-html="item.title"></h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="feature-description-col">
            
              <span class="feature-description" v-html="item.body"></span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "FieldEventsApp",
  props: {},
  data() {
    return {
      infoCards: [
        {
          title: "Create Events",
          body: "Every field event in track and field is supported. Including pole vault, long jump, triple jump, high jump, shot put, discuss, weight throw, and javelin.",
        },
        {
          title: "Run the Event",
          body: "<p>The app does all the work- It keeps track of the order of competitors and shows who is up, second, third, etc. It also stores all marks and knows the place each competitor is in.</p>",
        },
        {
          title: "FieldEvents Live",
          body: '<p>If "FieldEvents Live" is enabled in the app, spectators and athletes can view real time the status of who\'s up, how many attempts are remaining, as well as see the competition scoreboard from the <a class="color-orange" style=" text-decoration: none!important;" href="https://fieldevents.net/">Events</a> page or through the app.</p>',
        },
        {
          title: "Import Meets",
          body: '<p>FieldEvents currently supports importing either a <a class="color-orange" style="text-decoration: underline!important;" href="https://fieldevents.net/Entries.csv">CSV like this</a>, or a Lynx.evt file, which can be created by track & field software such as Hy-Tek, MeetPro, and RaceTab)</p>',
        },
        {
          title: "Alive Groups",
          body: "<p>Alive groups can be very beneficial to competitions where several athletes are in at the same time. This can provide the competitor with the best chance at staying warmed up in a competition.</p>",
        },
        {
          title: "Leica Laser Integration",
          body: "<p>The app supports connecting a Leica Disto via Bluetooth. Take advantage of this feature for easy and accurate measurements for all horizontal events</p>",
        },
        {
          title: "Export Results",
          body: "<p>After a competition has been completed, results can be exported via the app to CSV or PDF, which then can be shared from your device.</p>",
        },
      ],
    };
  },
  computed: {
    videoWidth() {
      if (this.$vssWidth < 900) {
        return this.$vssWidth -80
      } else {
        return 900
      }
    }
  },

  methods: {},
};
</script>

<style>
.app-icon {
  width: 370px !important;
  height: auto;
  padding: 60px;  /* Adjust as needed */
  background-color: #fff;  /* Match with your design */
  border-radius: 25%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
              0px 1px 3px rgba(0, 0, 0, 0.1);
}



.get-fieldevents-btn {
  text-transform: none;
}
.horizontal-device {
  width: 90% !important;
  height: auto;
}
.vertical-device {
  width: 70% !important;
  height: auto;
}

.feature-cards-container {
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.feature-row {
  margin-bottom: 30px;
}

.feature-description-col {
  position: relative;
  top: 30%;
}

.feature-description-col-v {
  position: relative;
  top: 30%;
}

@media screen and (max-width: 800px) {
  .feature-description-col {
    top: 15%;
  }

  .feature-description-col-v {
    top: 15%;
  }
}

@media screen and (max-width: 1000px) {
  .feature-title {
    font-size: 18px;
  }
  .feature-description {
    font-size: 14px;
  }
}

.feature-description {
  text-align: left;
}

.feature-title {
  text-align: left;
}

.feature-title-mobile {
  font-weight: 420;
}

@media only screen and (max-width: 501px) {
  .app-icon {
    width: 80% !important;
  }
}
.container-app {
  max-width: 70rem !important;
  margin: auto;
  padding-top: 100px;
}
</style>
