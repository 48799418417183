<template>
  <div class="event-table">
    <competitor-details
      ref="competitorDetailsModal"
      :tableData="tableData"
      :selectedCompetitor="selectedCompetitor"
      :type="type"
    />
    <v-row>
      <v-col v-if="$vssWidth > 900" cols="4">
       <div :class="['competitor-up-container', {'mb-8': isPrinting}]">
          <div class="small-body">Event details:</div>
          <div
            class="competitor-team"
            v-bind:style="[
              { 'background-color': '#343a40' },
              { color: 'white' },
            ]"
          >
            <span class="competitor-name">{{ competitionName }}</span>
            <span>{{ meetName }}</span>
          </div>
        </div>
      </v-col>
      <v-col>
      <v-row
          v-if="(attemptsRemaining > 0 && competitorUp != 'Competition Ended') && !isPrinting"
          class="mb-1"
        >
          <v-col>
            <div class="competitor-up-container">
              <div class="small-body">Competitor up:</div>
              <div
                class="competitor-team"
                v-bind:style="[
                  { 'background-color': teamColor },
                  { color: foregroundColor },
                ]"
              >
                <span class="competitor-name">{{ competitorUp }}</span>
                <span>{{ competitorTeam }}</span>
              </div>
              <v-alert
                dense
                text
                class="attempts-remaining-alert"
                :color="attemptsRemainingColor"
              >
                <strong>{{ attemptsRemaining }}</strong>
                {{ attemptsRemaining > 1 ? "attempts" : "attempt" }} remaining
              </v-alert>
              <div class="small-body"></div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="!isPrinting" class="mb-1">
          <v-col>
            <div class="competition-ended-container">
              <span class="competitor-name">{{
                msg == "not_started"
                  ? "Waiting for competition to start"
                  : "Competition Ended"
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

   
    <v-layout column style="width: 98vw">
      <v-flex>
        <v-data-table
          id="event-table"
          :headers="headers"
          :items="tableData"
          fixed-header
          :class="[
            'elevation-1',
            {'event-table-hide-place': hidePlace},
            { verticalTable: type == 0 },
            { horizontalTable: type == 1 },
          ]"
          mobile-breakpoint="1"
          disable-pagination
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hide-default-footer
          hide-default-header
          must-sort
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.value"
                  scope="col"
                  :class="['nowrap', header.class]"
                > 
                  <template v-if="header.isPrelim || header.isFinal">
                    <div class="align-center" style="font-weight: 500;">{{header.isPrelim ? 'Prelims' : 'Finals'}}</div>
                    <div class="align-center">{{header.text}}</div>
                  </template>
                  <template v-else-if="header.isHeight">
                    <div class="align-center">{{header.text}}</div>
                    <div class="align-center" style="font-weight: 500;">{{header.secondaryText}}</div>
                  </template>
                  <template v-else>
                    {{ header.text }}
                  </template>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="props">
            <tr :class="{ 'current-competitor': isCompUp(props.item) }"  @click="clickPerson(props.item)">
              <td v-for="header in headers" :key="header.value" :class="[{'start-of-finals-header': header.class == 'start-of-finals-header'}]" >
                <span
                  v-if="header.value == 'place'"
                  class="color-orange cursor-important"
                >
                  {{ props.item.place }}
                </span>
                <div
                  v-else-if="header.value == 'name'"
                  class="cursor-important pt-2 pb-2"
                >
                  <div class="competitor-name-text">
                    {{ props.item.name }}
                    <v-icon v-if="!isPrinting" x-small color="accent"
                      >mdi-information-outline</v-icon
                    >
                  </div>
                  <div
                    v-if="props.item.team != ''"
                    class="team-text"
                    v-bind:style="[
                      {
                        'background-color': getCompetitorColor(props.item.name),
                      },
                      { color: getCompetitorForegroundColor(props.item.name) },
                    ]"
                  >
                    {{ props.item.team }}
                  </div>
                </div>
                <div
                  v-else-if="
                    header.value.includes('_') && props.item[header.value]
                  "
                  class="pt-2 pb-2"
                >
                  <div
                    class="mark-primary-unit align-center"
                    v-bind:class="horizontalMarkClass(props.item[header.value])"
                  >
                    {{ props.item[header.value].split("--")[0] }}
                  </div>
                  <div class="mark-secondary-unit align-center">
                    {{ props.item[header.value].split("--")[1] }}
                  </div>
                </div>
                <span v-else-if="header.value == 'flight'">
                  {{ props.item[header.value] }}
                </span>
                <div v-else style="text-align: center;">

                <span
                  v-bind:class="verticalMarkClass(props.item[header.value])"
                >
                  {{ props.item[header.value] }}
                </span>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:no-data> No results found </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import _ from "lodash";
import CompetitorDetails from "./CompetitorDetails.vue";
export default {
  components: { CompetitorDetails },
  name: "EventTable",
  props: {
    type: Number,
    competitionName: String,
    competitionDate: String,
    meetName: String,
    headers: Array,
    msg: String,
    tableData: Array,
    competitorTeams: Object,
    userTeams: Array,
    attemptsRemaining: String,
    competitorUp: String,
    competitorTeam: String,
    teamColor: String,
    foregroundColor: String,
  },
  data() {
    return {
      selectedCompetitor: "",
      sortBy: 'place',
      sortDesc: false,
    };
  },
  computed: {
    attemptsRemainingColor() {
      if (this.attemptsRemaining >= 3) {
        return "green";
      } else if (this.attemptsRemaining == 2) {
        return "orange";
      } else {
        return "red";
      }
    },
    hideToolbar() {
      return window.location.href.includes("hideToolbar=1");
    },
    tableHeightDiff() {
      if (this.hideToolbar) {
        return 170
      } else {
        return 260
      }
    },
    isPrinting() {
      return this.$store.state.isPrinting;
    },
    hidePlace() {
      return this.isPrinting && this.msg == 'not_started'
    }
  },
  methods: {
    clickPerson(item) {
      this.selectedCompetitor = item.name;
      this.$refs.competitorDetailsModal.open();
    },
    isCompUp(item) {
      return !_.isNil(item.status) && item.status == "compUp";
    },
    isMarkRow(item) {
      return item.includes("--");
    },
    splitMark(mark, index) {
      return mark.split("--")[index];
    },
    getCompetitorColor(name) {
      let color = "#FFFFFF";

      if (!_.isNil(this.competitorTeams) && !_.isNil(this.userTeams)) {
        let teamId = this.competitorTeams[name];
        if (!_.isNil(teamId)) {
          let teamObj = this.userTeams.find((team) => team.id == teamId);

          if (!_.isNil(teamObj) && !_.isNil(teamObj.color)) {
            color = teamObj.color;
          }
        }
      }

      return color;
    },
    getCompetitorForegroundColor(name) {
      let hexcolor = this.getCompetitorColor(name);

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }

      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor
          .split("")
          .map(function (hex) {
            return hex + hex;
          })
          .join("");
      }

      // Convert to RGB value
      let r = parseInt(hexcolor.substr(0, 2), 16);
      let g = parseInt(hexcolor.substr(2, 2), 16);
      let b = parseInt(hexcolor.substr(4, 2), 16);

      // Get YIQ ratio
      let yiq = (r * 299 + g * 587 + b * 114) / 1000;

      // Check contrast
      return yiq >= 150 ? "#000000" : "#FFFFFF";
    },
    horizontalMarkClass(mark) {
      if (!_.isNil(mark) && ["f", "r"].includes(mark.toLowerCase())) {
        return "mark-miss";
      } else if (!_.isNil(mark) && mark.toLowerCase().includes("p")) {
        return "mark-pass";
      } else {
        return "mark-make";
      }
    },
    verticalMarkClass(mark) {
      if (!_.isNil(mark) && mark.toLowerCase().includes("o")) {
        return "mark-make";
      } else if (!_.isNil(mark) && mark.toLowerCase().includes("p")) {
        return "mark-pass";
      } else {
        return "mark-miss";
      }
    },
  },
};
</script>

<style>
.active-height {
  background-color: rgba(81, 84, 87) !important;
  color: white !important;
}
.height-th {
  text-align: center !important;
}
.nowrap {
  white-space: nowrap;
}

.align-center {
  text-align: center;
}

.start-of-finals-header {
  border-left: 1px solid;
  border-color: rgba(0,0,0,0.27);
  text-align: center;
}

.competitor-up-container {
  width: auto;
  max-width: 70%;
  margin-top: 4px !important;
  margin: 0 auto;
}
.competition-ended-container {
  width: fit-content;
  max-width: 70%;
  margin-top: 4px !important;
  margin: 0 auto;
  height: 120px;
}
.competitor-order-container {
  width: 220px;
}
.attempts-remaining-alert {
  margin: 0 auto;
}
.competitor-name {
  display: block;
  font-size: 1.5em;
  font-weight: 400;
  white-space: nowrap !important;
}
.competitor-team {
  border-radius: 4px;
  padding: 1px 10px 1px 10px;
  margin-bottom: 2px;
}
.competitor-name-text {
  font-weight: 500;
  white-space: nowrap !important;
}

.team-text {
  display: inline-block;
  margin-top: 3px;
  padding: 3px 12px 3px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 8px;
}

.mark-primary-unit {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mark-secondary-unit {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#event-table > div > table > tbody > tr > td:nth-child(1),
#event-table > div > table > thead > tr > th:nth-child(1) {
  background: white;
  /* z-index: 2;
  box-shadow: 3px 0px 30px 1px rgba(0,0,0,0.07) !important; */
}

#event-table > div > table > tbody > tr > td:not(:nth-child(-1n+2)),
#event-table > div > table > thead > tr > th:not(:nth-child(-1n+2)) {
 background: rgb(250, 250, 250)
}

#event-table > div > table > tbody > tr > td:nth-child(2),
#event-table > div > table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 2;
  background: white;
  /* box-shadow: 3px 0px 30px 1px rgba(0,0,0,0.07) !important;  */
}

#event-table > div > table > thead > tr > th:nth-child(2) {
  z-index: 3;
  position: sticky !important;
}

#event-table > div > table > tbody > tr:hover td {
   background: #eeeeee !important;
}


.event-table-hide-place > div > table > tbody > tr > td:nth-child(1),
.event-table-hide-place > div > table > thead > tr > th:nth-child(1) { 
  width: 0px !important;
  opacity: 0 !important;
}

.freezeheader {
  z-index: 3 !important;
  position: sticky !important;
}

.align-center {
  text-align: center !important;
}

.current-competitor {
  background-color: rgba(81, 84, 87) !important;
  color: white;
}

.current-competitor > td {
  background-color: rgba(81, 84, 87) !important;
  color: white;
}

.header-text {
  text-align: left;
  padding: 0 16px 0 16px;
  font-size: 14px;
}

.header-text-primary {
  font-size: 14px;
}

.header-text-secondary {
  font-size: 12px;
}

.mark-make {
  font-size: 18px;
  font-weight: 450;
  color: #4caf50 !important;
}

.mark-pass {
  font-size: 18px;
  font-weight: 450;
  color: #0285f0 !important;
}

.mark-miss {
  font-size: 18px;
  font-weight: 450;
  color: #f44336 !important;
}

@media only screen and (max-width: 1100px) {
  .header-text {
  }
  .header-text-secondary {
    font-size: 10px;
  }
}
</style>
