<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" persistent width="400px">
      <v-card height="550px">
        <v-card-title>
          <v-spacer />
          <span class="headline">
            {{ updating ? "Edit Team" : "Create Team" }}</span
          >
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="name"
                  :rules="[rules.required, rules.counter30]"
                  filled
                  counter
                  maxlength="30"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <chrome-picker class="color-picker" v-model="color" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="action-buttons">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="reset"> Cancel </v-btn>
          <v-btn :disabled="!canAddTeam" color="primary" @click="createTeam">
            {{ updating ? "Update" : "Create" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Chrome } from "vue-color";
import { buildRequestHeaders } from "@/utils";

export default {
  name: "CreateEditTeam",
  components: {
    "chrome-picker": Chrome,
  },
  props: {
    userObject: Object,
    team: Object,
  },
  data() {
    return {
      isOpen: false,
      updating: false,
      teamId: uuidv4().toUpperCase(),
      name: "",
      color: "#000000",
      rules: {
        required: (value) => !!value || "Required.",
        counter30: (value) => value.length <= 30 || "Max 30 characters",
        counter20: (value) => value.length <= 30 || "Max 20 characters",
      },
    };
  },
  computed: {
    canAddTeam() {
      return this.name != "" && this.color != "";
    },
  },
  updated() {},
  methods: {
    async open() {
      if (typeof this.team.id !== "undefined") {
        let teamTemp = _.cloneDeep(this.team);
        this.teamId = teamTemp.id;
        this.name = teamTemp.name;
        this.color = teamTemp.color;
        this.updating = true;
      } else {
        this.updating = false;
      }

      this.isOpen = true;
    },
    reset() {
      this.name = "";
      this.color = "";
      this.isOpen = false;
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    createTeam() {
      if (this.canAddTeam && this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let tempColor = "";
        this.name = this.name.replace(/'/g, "’");

        if (typeof this.color === "object") {
          tempColor = this.color.hex;
        } else {
          tempColor = this.color;
        }

        let team = {
          id: this.teamId,
          name: this.name,
          color: tempColor,
        };

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/teams/" +
          this.userObject.username;
        this.axios.post(apiUrl, team, config).then((response) => {
          this.isOpen = false;
          this.$emit("updateTeams");
        });
      }
    },
  },
};
</script>

<style>
.create-card {
  position: absolute;
}
.scroll {
  overflow-y: scroll;
}
.action-buttons {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.color-picker {
  display: inline;
}

#app
  > div.v-dialog__content.v-dialog__content--active
  > div
  > div
  > div.v-card__text
  > div
  > div:nth-child(2)
  > div
  > div
  > div.vc-chrome-body
  > div.vc-chrome-controls
  > div.vc-chrome-sliders
  > div.vc-chrome-alpha-wrap {
  display: none;
}

#app
  > div.v-dialog__content.v-dialog__content--active
  > div
  > div
  > div.v-card__text
  > div
  > div:nth-child(2)
  > div
  > div
  > div.vc-chrome-body
  > div.vc-chrome-fields-wrap
  > div.vc-chrome-toggle-btn {
  display: none;
}
</style>
