<template>
  <div class="main-content">
    <div class="create">
      <v-btn small color="primary" dark @click="createMeet"> Create </v-btn>
    </div>

    <v-layout column fill-height style="width: 100%">
      <v-flex style="overflow: auto">
        <v-card>
          <v-data-table
            :headers="tableHeaders"
            :items="meets"
            :loading="!hasFetched"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :hide-default-footer="true"
            disable-pagination
            must-sort
            item-key="id"
            no-data-text="No competitions added"
          >
            <template v-slot:[`item.created`]="{ item }">
              <div>
                {{ item.created | formatDate }}
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <!-- three dot menu overflow button -->
              <div style="text-align: right;">
                <v-menu offset-y right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="primary">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="getPublicQrCode(item)">
                      <v-list-item-title>Get Public QR Code</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickLink(item)">
                      <v-list-item-title>Get Public Link</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showMeetInviteModal(item)">
                      <v-list-item-title>Invite Officials</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateMeet(item)">
                      <v-list-item-title>Edit Meet</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="exportMeet(item)">
                      <v-list-item-title>Download Results</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <CreateEditMeet
      @updateMeets="fetchData"
      :meet="selectedMeet"
      ref="createMeet"
      :userObject="userObject"
    />
    <MeetInvite
      ref="meetInvite"
      :userObject="userObject"
    />
  </div>
</template>

<script>
import CreateEditMeet from "./CreateEditMeet.vue";
import MeetInvite from './MeetInvite.vue';
import _ from "lodash";
import { buildRequestHeaders } from "@/utils";


export default {
  name: "Meets",
  components: {
    CreateEditMeet,
    MeetInvite,
  },
  props: {
    userObject: Object,
  },
  data() {
    return {
      meets: [],
      hasFetched: false,
      intervalId: "",
      selectedMeet: {},
      refreshCount: 0,
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Division", value: "division" },
        { text: "Date", value: "created" },
        { text: "", value: "actions"},
      ],
      sortBy: 'created',
      sortDesc: true,
    };
  },
  mounted() {
    this.startFetchTimer();
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  watch: {
    userObject: function (value) {
      if (value.username != undefined && this.refreshCount == 0) {
        this.refreshCount = this.refreshCount + 1;
        this.fetchData();
      }
    },
  },
  methods: {
     startFetchTimer() {
      if (this.userObject.username != undefined) {
        this.fetchData();
      } else {
          setTimeout(() => {
            this.fetchData();
          }, 500);
      }

      if (!_.isNil(this.intervalId)) {
        window.clearInterval(this.intervalId);
      }
      // check for updates every 30 seconds
      this.intervalId = setInterval(this.fetchData, 30000);
    },
    fetchData() {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/meets/" +
          this.userObject.username;
        this.axios.get(apiUrl, config).then((response) => {
          this.meets = response.data;
          this.hasFetched = true;
        });
      }
    },
    clickLink(item) {
      let url = "https://fieldevents.net/?meetId=" + item.id;
      window.open(url, "_blank");
    },
    showMeetInviteModal(item) {
      this.$refs.meetInvite.open(item.id);
    },
    async getPublicQrCode(item) {
      let apiUrl = `${process.env.VUE_APP_BACKEND_URL}/utility/generateQRCode`
      let data = {
        id: item.id,
        type: "meet"
      }

      this.axios.post(apiUrl, data).then((response) => {
        let qrCodeUrl = response.data.url;
        setTimeout(() => {
           window.open(qrCodeUrl, '_blank');
        }, 1000);
      });
    },
    openMeetModal() {
      this.$refs.createMeet.open();
    },
    async createMeet() {
      let item = {};
      await this.setSelectedItem(item);
      this.openMeetModal();
    },
    async updateMeet(item) {
      await this.setSelectedItem(item);
      this.openMeetModal();
    },
    async exportMeet(item) {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/results/meet/" + item.id
        
        this.axios.get(apiUrl, config).then((response) => {
         if (response.data.url) {
           window.open(response.data.url);
         }
        });
      }
    },
    async setSelectedItem(item) {
      this.selectedMeet = item;
    },
  },
};
</script>
