<template>
  <div>
    <div class="container-app pb-4">
      <h1>Page Not Found</h1>
      <div>It looks like the page you were looking for does not exist!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Not Found",
};
</script>

<style>
.container-app {
  max-width: 40rem;
  margin: auto;
  padding-top: 10px;
}
.footer {
  width: 100%;
  text-align: center;
  background-color: white;
}
</style>
