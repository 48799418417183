<template>
  <div class="text-center" v-if="!dontShowSettings">
    <v-menu :close-on-content-click="false" :nudge-left="200" attach>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="[
            'competitor-order-btn',
            'fade-transition', { 'hidden' : !isUserActive },
            { 'competitor-order-btn-fullscreen': fullscreen || hideToolbar },
          ]"
          color="primary"
          small
          fab
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-tune-variant</v-icon>
        </v-btn>
      </template>

      <v-card class="score-board-settings-menu">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Scoreboard Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="showOrderModel"
                @change="toggleShowOrder"
                color="primary"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Show Order</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="fullscreen">
            <v-list-item-action>
              <v-switch v-model="darkMode" color="primary"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Dark Mode</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "ScoreboardSettings",
  props: {
    showOrder: Boolean,
    prevTimeout: Number,
    fullscreen: Boolean,
    currentView: String,
    isUserActive: Boolean,
  },
  data() {
    return {
      showOrderModel: true,
    };
  },
  mounted() {
    this.showOrderModel = this.showOrder;
  },
  computed: {
    dontShowSettings() {
      let dontShow = false;
      if (this.$vssWidth < 768 && this.currentView == 'scoreboard') {
        dontShow = true;
      }

      return dontShow
    },
    hideToolbar() {
      return window.location.href.includes("hideToolbar=1");
    },
    darkMode: {
      get() {
        return this.$store.state.darkMode;
      },
      set(value) {
        this.$store.commit("setDarkMode", value);
      },
    },
  },
  methods: {
    toggleShowOrder(val) {
      if (val == true) {
        this.$emit("toggleShowOrder", true);
      } else {
        this.$emit("toggleShowOrder", false);
      }
    },
  },
};
</script>

<style>
.timeout-slider {
  display: block;
}
.scoreboard-settings-menu {
  z-index: 999999;
}
</style>
