var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fullscreen',{attrs:{"fullscreen":_vm.fullscreen,"fullscreen-class":(_vm.isDarkMode && _vm.view == 'scoreboard') ? '' : 'fullscreen'},on:{"update:fullscreen":function($event){_vm.fullscreen=$event}}},[_c('v-container',{class:['event-container', {'mobile-container' : _vm.hideToolbar}],attrs:{"fluid":""}},[(!_vm.isPrinting)?[_c('v-btn',{class:['table-btn', 'fade-transition', { 'hidden' : !_vm.isUserActive }, { 'table-btn-fullscreen': (_vm.fullscreen || _vm.hideToolbar) }],attrs:{"color":_vm.view == 'table' ? '#eb740e' : '#9e9e9e',"small":"","fab":"","dark":""},on:{"click":function($event){_vm.view = 'table'}}},[_c('v-icon',[_vm._v("mdi-table-large")])],1),_c('v-btn',{class:['scoreboard-btn', 'fade-transition', { 'hidden' : !_vm.isUserActive }, { 'scoreboard-btn-fullscreen': (_vm.fullscreen || _vm.hideToolbar)}],attrs:{"color":_vm.view == 'scoreboard' ? '#eb740e' : '#9e9e9e',"small":"","fab":"","dark":""},on:{"click":function($event){_vm.view = 'scoreboard'}}},[_c('v-icon',[_vm._v("mdi-card-account-details")])],1),(_vm.$vssWidth >= 1024)?_c('v-btn',{class:['fullscreen-btn', 'fade-transition', { 'hidden' : !_vm.isUserActive }, { 'fullscreen-btn-fullscreen': (_vm.fullscreen || _vm.hideToolbar) }],attrs:{"color":"primary","small":"","fab":"","dark":""},on:{"click":_vm.toggleFullscreen}},[(!_vm.fullscreen)?_c('v-icon',[_vm._v("mdi-fullscreen")]):_c('v-icon',[_vm._v("mdi-fullscreen-exit")])],1):_vm._e(),(_vm.$vssWidth >= 1024 && (_vm.view == 'table') && !(_vm.fullscreen || _vm.hideToolbar))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:['share-competition-btn', 'fade-transition', { 'hidden' : !_vm.isUserActive } ],attrs:{"color":"primary","small":"","fab":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-export-variant")])],1)]}}],null,false,1792397175)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.getQR}},[_c('v-list-item-title',[_vm._v("Get QR Code")])],1),_c('v-list-item',{on:{"click":_vm.copyLink}},[_c('v-list-item-title',[_vm._v("Copy Link")])],1),_c('v-list-item',{on:{"click":_vm.printResults}},[_c('v-list-item-title',[_vm._v("Print "+_vm._s(_vm.msg =='not_started' ? 'Starting List' : 'Results'))])],1)],1)],1):_vm._e(),(_vm.view == 'table' && _vm.orderOfComps.length)?_c('competitor-order',{attrs:{"isUserActive":_vm.isUserActive,"orderOfComps":_vm.orderOfComps,"fullscreen":_vm.fullscreen}}):_vm._e(),(
          _vm.msg != '' &&
          _vm.msg != 'not_started' &&
          _vm.competitorUp != 'Competition Ended' &&
          _vm.view == 'table'
        )?_c('v-alert',{staticClass:"msg-alert",attrs:{"dense":"","color":"#84bbf5","dismissible":""}},[_vm._v(" "+_vm._s(_vm.msg)+": "+_vm._s(_vm.currentHeight)+" ")]):_vm._e()]:_vm._e(),_c('v-img',{class:'printing-logo' + (_vm.isPrinting ? ' printing-logo-printing' : ''),attrs:{"alt":"FieldEvents Logo","contain":"","src":require("@/assets/logo384.png"),"transition":"scale-transition","width":"300"}}),(_vm.view == 'table')?_c('event-table',{attrs:{"type":_vm.competitionType,"competitionName":_vm.competitionName,"competitionDate":_vm.competitionDate,"meetName":_vm.meetName,"headers":_vm.headers,"tableData":_vm.tableData,"userTeams":_vm.userTeams,"competitorTeams":_vm.competitorTeams,"msg":_vm.msg,"attemptsRemaining":_vm.attemptsRemaining,"competitorUp":_vm.competitorUp,"competitorTeam":_vm.competitorTeam,"teamColor":_vm.teamColor,"foregroundColor":_vm.foregroundColor}}):_vm._e(),(_vm.view == 'scoreboard')?_c('event-scoreboard',{attrs:{"msg":_vm.msg,"eventId":_vm.eventId,"eventType":_vm.eventType,"currentHeight":_vm.currentHeight,"preferredUnits":_vm.preferredUnits,"previousCompetitorUp":_vm.previousCompetitorUp,"previousCompetitorTeam":_vm.previousCompetitorTeam,"previousCompetitorScore":_vm.previousCompetitorScore,"previousCompetitorTeamColor":_vm.previousCompetitorTeamColor,"previousCompetitorforegroundColor":_vm.previousCompetitorforegroundColor,"previousCompetitorHeight":_vm.previousCompetitorHeight,"attemptsRemaining":_vm.attemptsRemaining,"competitorUp":_vm.competitorUp,"competitorScore":_vm.competitorScore,"competitorTeam":_vm.competitorTeam,"teamColor":_vm.teamColor,"foregroundColor":_vm.foregroundColor,"orderOfComps":_vm.orderOfComps,"fullscreen":_vm.fullscreen,"isUserActive":_vm.isUserActive}}):_vm._e(),(_vm.view == 'table' && !_vm.isPrinting)?_c('span',{staticClass:"unofficial"},[_vm._v(_vm._s(_vm.fullscreen ? "Powered by FieldEvents.net - " : "")+"All results unofficial")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }