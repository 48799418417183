var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[
        'competitor-order-btn',
        'fade-transition', { 'hidden' : !_vm.isUserActive },
        { 'competitor-order-btn-fullscreen': _vm.fullscreen },
        { 'competitor-order-btn-mobile' : _vm.hideToolbar }
      ],attrs:{"color":"primary","small":"","fab":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-list")])],1)]}}]),model:{value:(_vm.competitorOrderDialog),callback:function ($$v) {_vm.competitorOrderDialog=$$v},expression:"competitorOrderDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Competitor Order")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('div',_vm._l((_vm.orderOfComps),function(item,index){return _c('v-list-item',{key:item.id,attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name)+" is "+_vm._s(index == 0 ? "up" : _vm.ordinalSuffix(index + 1)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.attempts)+" "+_vm._s(item.attempts > 1 ? "attempts" : "attempt")+" remaining")])],1)],1)}),1)]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.competitorOrderDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }