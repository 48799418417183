<template>
  <div>
    <div class="create">
      <v-btn small color="primary" dark @click="createTeam"> Create </v-btn>
    </div>

    <v-layout column fill-height style="width: 100%">
      <v-flex style="overflow: auto">
        <v-card>
          <v-data-table
            :headers="tableHeaders"
            :items="teams"
            :loading="!hasFetched"
            :hide-default-footer="true"
            disable-pagination
            must-sort
            item-key="id"
            no-data-text="No competitions added"
          >
            <template v-slot:[`item.color`]="{ item }">
                <div
                  class="color-div"
                  :style="'background-color: ' + colorFromHex(item) +'; color: ' + colorFromHex(item) + ';'"
                >
                  {{ colorFromHex(item) }}
                </div>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
               <v-btn
                  @click="updateTeam(item)"
                  outlined
                  x-small
                  fab
                  color="secondary"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <CreateEditTeam
      @updateTeams="fetchData"
      :team="selectedTeam"
      ref="createTeam"
      :userObject="userObject"
    />
  </div>
</template>

<script>
import CreateEditTeam from "./CreateEditTeam.vue";
import _ from "lodash";
import { buildRequestHeaders } from "@/utils";

export default {
  name: "Teams",
  components: {
    CreateEditTeam,
  },
  props: {
    userObject: Object,
  },
  data() {
    return {
      teams: [],
      hasFetched: false,
      intervalId: "",
      selectedTeam: {},
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Color", value: "color" },
        { text: "Edit", value: "edit" }
      ],
    };
  },
  mounted() {
    this.startFetchTimer();
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  methods: {
     startFetchTimer() {
      if (this.userObject.username != undefined) {
        this.fetchData();
      } else {
          setTimeout(() => {
            this.fetchData();
          }, 500);
      }

      if (!_.isNil(this.intervalId)) {
        window.clearInterval(this.intervalId);
      }
      // check for updates every 30 seconds
      this.intervalId = setInterval(this.fetchData, 30000);
    },
    fetchData() {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/teams/" +
          this.userObject.username;
        this.axios.get(apiUrl, config).then((response) => {
          this.teams = response.data;
          this.hasFetched = true;
        });
      }
    },
    colorFromHex(item) {
      return item.color.substring(0, 7);
    },
    clickLink(item) {
      let url = "https://fieldevents.net/Events/" + item.id + ".html";
      window.open(url, "_blank");
    },

    openTeamModal() {
      this.$refs.createTeam.open();
    },
    async createTeam() {
      let item = {};
      await this.setSelectedItem(item);
      this.openTeamModal();
    },
    async updateTeam(item) {
      await this.setSelectedItem(item);
      this.openTeamModal();
    },
    async setSelectedItem(item) {
      this.selectedTeam = item;
    },
  },
};
</script>
