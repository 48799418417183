<template>
  <div>
    <v-dialog v-model="isOpen" persistent :fullscreen="$vssWidth <= 500" max-width="1024">

      <v-stepper
        v-if="!isImporting"
        v-model="page"
        elevation="1"
        height="100%"
      >
        <v-form ref="competitionForm">
          <v-stepper-header>
            <v-spacer />
            <v-stepper-step :complete="page > 1" step="1">
              Competition Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="page > 2" step="2">
              Competitors
            </v-stepper-step>
            <v-spacer />
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <p class="stepper-detail-text">
                Specify competition name, meet, type, and date
              </p>
              <v-card elevation="0" class="mb-12">
                <v-container>
                  <v-row>
                    <v-col :cols="$vssWidth <= 400 ? 12 : 6">
                      <v-text-field
                        label="Competition Name"
                        v-model="name"
                        :rules="[rules.required, rules.counter40]"
                        filled
                        counter
                        maxlength="40"
                        hint="This field uses maxlength attribute"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="$vssWidth <= 400 ? 12 : 6" v-if="!creatingMeetNow">
                      <v-autocomplete
                        v-model="meet"
                        ref="meetDropdown"
                        :items="meets"
                        :rules="[rules.required]"
                        filled
                        dense
                        label="Meet"
                        item-text="name"
                        item-value="id"
                        no-data-text="No meets found, you must create a meet first"
                        class="form-dropdown"
                      >
                      <template v-slot:no-data>
                        <v-list-item @click="showCreatingMeetNow">
                          <v-list-item-content>
                            <v-list-item-title>
                              No meets found, click here to create a new one
                              <v-icon class="mt-n1" color="secondary">mdi-plus</v-icon>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    </v-col>
                    <template v-if="creatingMeetNow">
                      <v-col >
                        <v-text-field
                          label="Meet Name"
                          v-model="meetName"
                          :rules="[rules.required, rules.counter30]"
                          filled
                          counter
                          maxlength="30"
                        ></v-text-field>
                      </v-col>
      
                      <v-col>
                        <v-text-field
                          label="Meet Division"
                          v-model="division"
                          :rules="[rules.required, rules.counter30]"
                          filled
                          counter
                          maxlength="30"
                        ></v-text-field>
                      </v-col>
                    </template>
    
                  </v-row>
                  <v-row>
                    <v-col :cols="$vssWidth <= 400 ? 12 : 6">
                      <v-select
                        v-model="type"
                        :items="types"
                        :disabled="competitors.length > 0"
                        :rules="[rules.required]"
                        filled
                        dense
                        label="Type"
                      ></v-select>
                    </v-col>

                    <v-col :cols="$vssWidth <= 400 ? 12 : 6">
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Competition Date"
                            append-icon="mdi-calendar"
                            readonly
                            :rules="[rules.required]"
                            filled
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          color="secondary"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="dateMenu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dateMenu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-spacer />

              <div class="stepper-buttons">
                <v-btn text color="red" @click="reset"> Cancel </v-btn>

                <v-spacer />

                <v-btn
                  color="primary"
                  :disabled="!isPageOneValid"
                  @click="page = 2"
                >
                  Next
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" elevation="0">
              <p class="stepper-detail-text">
                Add competitors to the competition
              </p>
              <v-card class="mb-12" elevation="0" height="100%">
                <v-container>
                  <v-row>
                    <v-col :cols="$vssWidth <= 400 ? 12 : 5">
                      <v-combobox
                        v-model="newTempComp"
                        :items="existingCompetitors"
                        label="Name"
                        @change="competitorSelected"
                        filled
                        item-text="name"
                        item-value="id"
                      >
                        <template v-slot:item="data">
                        <div class="ac-competitor">
                          <span class="mr-1">{{data.item.name}}</span>
                          <span class="ac-competitor-team">({{getTeamName(data.item.teamId)}})</span>
                        </div>

                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col :cols="$vssWidth <= 400 ? 12 : 5">
                      <v-autocomplete
                        v-model="tempTeam"
                        :items="teams"
                        required
                        filled
                        dense
                        label="Team"
                        item-text="name"
                        no-data-text="No teams found"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      :cols="$vssWidth <= 400 ? 12 : 1"
                      :class="[{ 'mt-3': $vssWidth > 400 }]"
                    >
                      <v-btn
                        v-if="$vssWidth >= 400"
                        @click="addCompetitor()"
                        :disabled="!isTempCompValid"
                        outlined
                        x-small
                        fab
                        color="secondary"
                      >
                        <v-icon>mdi-account-plus-outline</v-icon>
                      </v-btn>
                      <div v-else class="flex-buttons">
                        <v-spacer />

                        <v-btn
                          text
                          color="secondary"
                          @click="addCompetitor()"
                          :disabled="!isTempCompValid"
                          >Add</v-btn
                        >

                        <v-spacer />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-card elevation="1" class="scroll">
                        <v-simple-table fixed-header height="320px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Name</th>
                                <th class="text-left">Team</th>
                                <th class="text-left small-header"></th>
                              </tr>
                            </thead>
                            <tbody v-if="competitors.length">
                              <tr v-for="item in competitors" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>{{ getTeamName(item.teamId) }}</td>
                                <td>
                                  <v-btn
                                    outlined
                                    x-small
                                    fab
                                    color="red"
                                    @click="removeCompetitor(item)"
                                  >
                                    <v-icon>mdi-account-minus-outline</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td>No Competitors Added</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <div class="stepper-buttons">
                <v-btn text color="red" @click="reset"> Cancel </v-btn>

                <v-spacer />

                <v-btn text @click="page = 1"> Back </v-btn>

                <v-btn
                  class="ml-3"
                  color="primary"
                  :disabled="!canCreateCompetition"
                  @click="createCompetition"
                >
                  {{isUpdating ? 'Update' : 'Create'}}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-form>
      </v-stepper>

      <v-card height="640px" elevation="1" v-if="isImporting">
        <v-form ref="importingForm">
          <v-card-title>
            <v-spacer />
            <span class="headline">Import Competitions</span>
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col v-if="!creatingMeetNow">
                  <v-autocomplete
                    v-model="meet"
                    ref="meetDropdown"
                    :items="meets"
                    :rules="[rules.required]"
                    filled
                    dense
                    label="Meet"
                    item-text="name"
                    item-value="id"
                    no-data-text="No meets found, you must create a meet first"
                    class="form-dropdown"
                  >
                  <!-- slot for no-data-text -->
                  <template v-slot:no-data>
                    <v-list-item @click="showCreatingMeetNow">
                      <v-list-item-content>
                        <v-list-item-title>
                          No meets found, click here to create a new one
                          <v-icon class="mt-n1" color="secondary">mdi-plus</v-icon>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <template v-if="creatingMeetNow">
                <v-col >
                  <v-text-field
                    label="Meet Name"
                    v-model="meetName"
                    :rules="[rules.required, rules.counter30]"
                    filled
                    counter
                    maxlength="30"
                  ></v-text-field>
                </v-col>
 
                <v-col>
                  <v-text-field
                    label="Division"
                    v-model="division"
                    :rules="[rules.required, rules.counter30]"
                    filled
                    counter
                    maxlength="30"
                  ></v-text-field>
                </v-col>
              </template>
    
         
                <v-col>
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Competition Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        required
                        filled
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      color="secondary"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dateMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-card height="385px" width="100%" class="scroll ml-4 mr-4">
                  <v-simple-table fixed-header height="385px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            <v-checkbox
                              color="primary"
                              v-model="importAll"
                              @change="toggleImportAll"
                            ></v-checkbox>
                          </th>
                          <th class="text-left">Event</th>
                          <th class="text-left">Type</th>
                          <th class="text-left"># Athletes</th>
                          <th class="text-left" v-if="someHorizontalEvents">
                            Create Flights
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="importedData.length">
                        <tr v-for="item in importedData" :key="item.id">
                          <td>
                            <v-checkbox
                              color="primary"
                              v-model="item.isImporting"
                            ></v-checkbox>
                          </td>

                          <td>
                            <v-text-field
                              class="mt-2 mb-2 no-details width-150"
                              filled
                              :rules="[rules.required, rules.counter40]"
                              dense
                              v-model="item.name"
                            ></v-text-field>
                          </td>

                          <td>
                            <v-select
                              class="mt-2 mb-2 no-details width-135"
                              v-model="item.type"
                              :items="types"
                              required
                              filled
                              dense
                            ></v-select>
                          </td>
                          <td>
                            <span
                              class="td-link"
                              @click="
                                showImportEventCompetitors(item.competitors)
                              "
                            >
                              {{ item.competitors.length }}
                            </span>
                          </td>
                          <td>
                            <span style="display: flex">
                              <v-checkbox
                                v-if="item.type !== 'Vertical'"
                                color="primary"
                                v-model="item.createFlights"
                              ></v-checkbox>
                              <v-autocomplete
                                class="width-160 mt-3"
                                v-if="item.createFlights == true"
                                v-model="item.numCompsInFlight"
                                :items="numCompetitorsInFlightArr"
                                :rules="[rules.required]"
                                dense
                                outlined
                                no-data-text="No items found"
                                label="# Athletes per flight"
                              ></v-autocomplete>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td>No events found in file</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-row>
            </v-container>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="action-buttons">
            <div class="stepper-buttons">
              <v-btn text color="red" @click="reset"> Cancel </v-btn>
              <v-spacer />
              <v-btn
                class="ml-3"
                v-if="isImporting"
                color="primary"
                :disabled="!canImportCompetitions"
                @click="importCompetitions"
              >
                Import
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <simple-table
      ref="CompetitorsTable"
      :headers="[{ label: 'Name' }, { label: 'Team' }]"
      :items="importEventCompetitors"
    />
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import SimpleTable from "../partials/SimpleTable.vue";
import { buildRequestHeaders } from "@/utils";

export default {
  name: "CreateCompetition",
  components: {
    SimpleTable,
  },
  props: {
    userObject: Object,
    isImporting: Boolean,
    importedData: Array,
  },
  data() {
    return {
      isOpen: false,
      isUpdating: false,
      creatingMeetNow: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter40: (value) => value.length <= 40 || "Max 40 characters",
        counter30: (value) => value.length <= 30 || "Max 30 characters",
        counter20: (value) => value.length <= 30 || "Max 20 characters",
      },
      importEventCompetitors: [],
      importAll: true,
      compId: uuidv4().toUpperCase(),
      deviceId: "site",
      fieldsJSON: {},
      itemsJSON: {},
      scoreboardJSON: {},
      eventInfo: {},
      meets: [],
      teams: [],
      existingCompetitors: [],
      intervalId: "",
      types: ["Vertical", "Horizontal"],
      dateMenu: false,
      page: 1,
      meetName: "",
      division: "",
      name: "",
      meet: "",
      type: "",
      date: new Date().toISOString().substr(0, 10),
      competitors: [],
      newTempComp: null,
      tempName: "",
      tempTeam: "",
      numFlightsToCreate: 0,
      numCompetitorsInFlightArr: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      vertCompetitorTemplate: {
        id: "",
        bio: "",
        name: "",
        score: [""],
        misses: 0,
        teamId: "",
        attempts: 3,
        realScore: 0,
        suspended: false,
        checkInStatus: 0,
        startingHeight: {
          id: "1",
          Feet: "",
          Meters: "Not Set",
        },
      },
      tradCompetitorTemplate: {
        id: "",
        bio: "",
        name: "",
        score: [],
        flight: 0,
        misses: 0,
        teamId: "",
        attempts: 3,
        realScore: 0,
        suspended: false,
        checkInStatus: 0,
      },
      snapshotTemplate: {
        competitionStarted: false,
        initialCompetition: {
          id: "",
          type: 0,
          meetId: "",
          showLoad: false,
          endedComp: false,
          loadedComp: 1,
          selectUnit: 0,
          createFinal: false,
          shouldClear: false,
          createdFinal: false,
          previousComp: "",
          selectThresh: 3,
          useFiveAlive: false,
          compsAtHeight: [],
          currentFlight: 0,
          currentHeight: 0,
          shouldRefresh: true,
          compsAtAttempt: [],
          jumpOffStarted: false,
          previousResult: "",
          unitsPreferred: "",
          competitionDate: "",
          competitionName: "",
          previousHeightF: "",
          previousHeightM: "",
          selectedHeights: [
            {
              id: "1",
              Feet: "",
              Meters: "Not Set",
            },
          ],
          selectNumFlights: 0,
          currentCompetitor: 0,
          heightRequirement: "",
          backFromScoreboard: false,
          competitionStarted: false,
          fiveAliveThreshold: 8,
          numCompsInFinalArr: ["8", "9", "10", "11", "12", "13", "14", "15"],
          selectNumAttemptsF: 2,
          selectNumAttemptsP: 2,
          shouldUseFiveAlive: false,
          selectNumCompsInFinal: 1,
          sortedTradCompetitors: [],
          sortedVertCompetitors: [],
          selectedTradCompetitors: [],
          selectedVertCompetitors: [],
        },
        ongoingCompetition: {
          id: "",
          type: 0,
          meetId: "",
          showLoad: false,
          endedComp: false,
          loadedComp: 1,
          selectUnit: 0,
          createFinal: false,
          shouldClear: false,
          createdFinal: false,
          previousComp: "",
          selectThresh: 3,
          useFiveAlive: false,
          compsAtHeight: [],
          currentFlight: 0,
          currentHeight: 0,
          shouldRefresh: true,
          compsAtAttempt: [],
          jumpOffStarted: false,
          previousResult: "",
          unitsPreferred: "",
          competitionDate: "",
          competitionName: "",
          previousHeightF: "",
          previousHeightM: "",
          selectedHeights: [
            {
              id: "1",
              Feet: "",
              Meters: "Not Set",
            },
          ],
          selectNumFlights: 0,
          currentCompetitor: 0,
          heightRequirement: "",
          backFromScoreboard: false,
          competitionStarted: false,
          fiveAliveThreshold: 8,
          numCompsInFinalArr: ["8", "9", "10", "11", "12", "13", "14", "15"],
          selectNumAttemptsF: 2,
          selectNumAttemptsP: 2,
          shouldUseFiveAlive: false,
          selectNumCompsInFinal: 1,
          sortedTradCompetitors: [],
          sortedVertCompetitors: [],
          selectedTradCompetitors: [],
          selectedVertCompetitors: [],
        },
      },
    };
  },
  mounted() {
    this.startFetchTimer();
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  computed: {
    isPageOneValid() {
      return this.name != "" && this.type != "" && (this.meet != "" || this.creatingMeetNow && (this.meetName != "" && this.division != ""))
    },
    isTempCompValid() {
      let fieldsValid = this.tempName != "" && this.tempTeam != "";
      let competitorInList = this.competitors.find(el => el.name == this.tempName && el.teamId == this.tempTeam)

      return fieldsValid && !competitorInList
    },
    canCreateCompetition() {
      return this.isPageOneValid;
    },
    canImportCompetitions() {
      let somethingSelected = false;

      for (let i = 0; i < this.importedData.length; i++) {
        if (this.importedData[i].isImporting == true) {
          somethingSelected = true;

          if (this.importedData[i].name == "" || this.importedData[i].type == "" || this.importedData[i].competitors.length == 0) {
            return false;
          }

          if (this.importedData[i].type == "Horizontal") {
            if (!_.isNil(this.importedData[i].createFlights)) {
              if (this.importedData[i].createFlights && (_.isNil(this.importedData[i].numCompsInFlight) || this.importedData[i].numCompsInFlight <= 0)) {
                return false;
              }
            }
          }
        }
      }

      return (this.meet != "" || this.creatingMeetNow && (this.meetName != "" && this.division != "")) && somethingSelected; // todo add check for selected competition
    },
    someHorizontalEvents() {
      let someExist = false;
      if (this.importedData.length) {
        let horizontalComps = this.importedData.filter(
          (el) => el.type == "Horizontal"
        );
        if (horizontalComps.length) {
          someExist = true;
        }
      }

      return someExist;
    },
  },
  methods: {
    open(competition) {
      if (competition) {
        this.isUpdating = true;
        this.name = competition.competitionName;
        this.page = 1;
        this.meet = competition.meetId;
        this.compId = competition.id
        this.type = this.types[competition.snapshot.initialCompetition.type];
        let dateExtracted = competition.competitionDate.split(" ")[0];
        this.date = new Date(dateExtracted).toISOString().substr(0, 10);

        if (competition.snapshot.initialCompetition.type == 0) {
          this.competitors = competition.snapshot.initialCompetition.selectedVertCompetitors;
        } else {
          this.competitors = competition.snapshot.initialCompetition.selectedTradCompetitors;
        }
      }

      this.startFetchTimer();
      this.isOpen = true;
    },
    reset() {
      this.isUpdating = false;
      this.creatingMeetNow = false;
      this.compId = uuidv4().toUpperCase()
      this.page = 1;
      this.meet = "";
      this.type = "";
      this.meetName = "";
      this.division = "";
      this.name = "";
      this.competitors = [];
      this.date = new Date().toISOString().substr(0, 10);
      this.$emit("resetCreate");
      if (!this.importing) {
        this.$refs.competitionForm.resetValidation()
      } else {
        this.$refs.importingForm.resetValidation()
      }
      this.isOpen = false;
    },
    showCreatingMeetNow() {
      // set meetName to the current text in the v-autocomplete with ref meetDropdown
      this.$nextTick(() => {
        const inputElement = this.$refs.meetDropdown.$refs.input;
          if (inputElement) {
              this.meetName = _.clone(inputElement.value);
          } else {
              console.error("Input element not found");
          }

        this.division = "";
        this.creatingMeetNow = true;
    });

    },
    startFetchTimer() {
      if (this.userObject.username != undefined) {
        this.fetchData();
      } else {
          setTimeout(() => {
            this.fetchData();
          }, 500);
      }

      if (!_.isNil(this.intervalId)) {
        window.clearInterval(this.intervalId);
      }
      // check for updates every 30 seconds
      this.intervalId = setInterval(this.fetchData, 30000);
    },
    async fetchData() {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/meets/" +
          this.userObject.username;
        this.axios.get(apiUrl, config).then((response) => {
          this.meets = response.data;
        });

        apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/teams/" +
          this.userObject.username;
        this.axios.get(apiUrl, config).then((response) => {
          this.teams = response.data;
        });

        apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/competitors/" +
          this.userObject.username;
        this.axios.get(apiUrl, config).then((response) => {
          this.existingCompetitors = response.data;
        });
      }
    },
    competitorSelected() {
      if (this.newTempComp) {
        this.tempName = this.newTempComp.name //this.existingCompetitors.find(el => el.id == this.newTempComp.id).name
        this.tempTeam = this.newTempComp.teamId //this.existingCompetitors.find(el => el.id == this.newTempComp).teamId
      } else {
        this.tempName = ""
        this.tempTeam = ""
      }

    },
    showImportEventCompetitors(items) {
      let newItems = [];

      for (let i = 0; i < items.length; i++) {
        let simpleComp = {
          name: items[i].name,
          team: items[i].team,
        };
        newItems.push(simpleComp);
      }
      this.importEventCompetitors = newItems;

      this.$refs.CompetitorsTable.open();
    },
    async importCompetitions() {
      // will only create a meet if the user is creating a meet now
      await this.createMeet()

      for (let i = 0; i < this.importedData.length; i++) {
        let event = this.importedData[i];

        if (event.isImporting) {
          // reset vars
          this.compId = uuidv4().toUpperCase();
          this.name = event.name;
          this.type = event.type;
          this.competitors = [];
          this.numFlightsToCreate = 0;

          for (let j = 0; j < event.competitors.length; j++) {
            let comp = event.competitors[j];
            this.tempName = comp.name;
            this.tempTeam = await this.getTeamId(comp.team);
            this.addCompetitor(parseInt(comp.flight));
          }

          // If we are creating flights, we need to divide the comps into chunks, then assign the flight, and then join the array
          if (event.createFlights) {
            let tempCompetitors = [];
            let perChunk = event.numCompsInFlight;
            var chunks = this.competitors.reduce((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / perChunk);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(item);

              return resultArray;
            }, []);

            // Save the total number of flights for the event for later
            this.numFlightsToCreate = chunks.length;

            for (let i = 0; i < chunks.length; i++) {
              for (let j = 0; j < chunks[i].length; j++) {
                let tempComp = chunks[i][j];
                tempComp.flight = i;
                tempCompetitors.push(tempComp);
              }
            }
            this.competitors = tempCompetitors;
          }

          await this.createCompetition();
        }
      }
      this.isOpen = false;
    },
    addCompetitor(flight = 0) {
      let competitor = {};

      if (this.type == "Vertical") {
        competitor = _.cloneDeep(this.vertCompetitorTemplate);
      } else {
        competitor = _.cloneDeep(this.tradCompetitorTemplate);
        competitor.flight = flight;
      }
      if (!this.tempName) {
        this.tempName = this.newTempComp
      }
      this.tempName = this.tempName.replace(/'/g, "’");
      competitor.id = uuidv4().toUpperCase();
      competitor.name = this.toTitleCase(this.tempName);
      competitor.teamId = this.tempTeam;

      this.competitors.push(competitor);
      this.newTempComp = null;
      this.tempTeam = "";
      this.tempName = "";

      return competitor;
    },
    removeCompetitor(item) {
      this.competitors = this.competitors.filter(
        (element) => !(element.id == item.id)
      );
    },
    toggleImportAll(value) {
      for (let j = 0; j < this.importedData.length; j++) {
        this.importedData[j].isImporting = value;
      }
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    getTeamName(id) {
      let team = this.teams.find((element) => element.id == id);
      return team ? team.name : ""
    },
    async getTeamId(name) {
      let teamId = "NA";
      let temp2 = name.toLowerCase();
      temp2 = temp2.trim();

      for (let i = 0; i < this.teams.length; i++) {
        let temp = this.teams[i].name.toLowerCase();
        temp = temp.trim();

        if (temp == temp2) {
          teamId = this.teams[i].id;
          break;
        }
      }

      if (teamId == "NA") {
        console.log("cant find ", name, " (adding to DB)");
        let id = uuidv4().toUpperCase();
        teamId = id;
        let tempTeamObj = {
          color: "#FFFFFF",
          name: name,
          id: id,
        };
        // temporarily push this to the teams array so other requests don't choke on it
        this.teams.push(tempTeamObj);
        await this.createTeam(tempTeamObj);
      }

      return teamId;
    },
    async createMeet() {
      if (this.creatingMeetNow && this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);
        this.meet = uuidv4().toUpperCase()
        this.meetName = this.meetName.replace(/'/g, "’");

        let meet = {
          id: this.meet,
          name: this.meetName,
          division: this.division,
        };

        let apiUrl = process.env.VUE_APP_BACKEND_URL + "/meets/" + this.userObject.username;
        this.axios.post(apiUrl, meet, config).then((response) => {
        });
      }
    },
    async createCompetition() {
      if (
        (this.canCreateCompetition || this.canImportCompetitions) &&
        this.userObject.username != undefined
      ) {
        if (!this.isImporting) {
          // try to create a meet if we need to now
          // we dont do this for import here because importing does a loop for multiple events and we only need to create the meet once
          await this.createMeet();
        }

        this.name = this.name.replace(/'/g, "’");
        let compDate = this.$options.filters.formatDateTime(this.date);

        // Building dumb NSDate which is the time in seconds between 2001-01-01 and competitionDate
        let newDate = new Date().toISOString();
        let time = this.$options.filters.formatDateTimeOnly(newDate);
        var now = moment(String(this.date + " " + time)); //todays date
        var end = moment("2001-01-01"); // another date
        var duration = moment.duration(now.diff(end));
        var nsDate = duration.asSeconds();

        // Update the snapshot template
        let snapshot = _.cloneDeep(this.snapshotTemplate);
        snapshot.initialCompetition.id = this.compId;
        snapshot.ongoingCompetition.id = this.compId;
        snapshot.initialCompetition.meetId = this.meet;
        snapshot.ongoingCompetition.meetId = this.meet;
        snapshot.initialCompetition.competitionName = this.name;
        snapshot.ongoingCompetition.competitionName = this.name;
        snapshot.initialCompetition.competitionDate = nsDate;
        snapshot.ongoingCompetition.competitionDate = nsDate;

        if (this.type == "Vertical") {
          snapshot.initialCompetition.type = 0;
          snapshot.ongoingCompetition.type = 0;
          snapshot.initialCompetition.sortedVertCompetitors = this.competitors;
          snapshot.initialCompetition.selectedVertCompetitors = this.competitors;
          snapshot.ongoingCompetition.sortedVertCompetitors = this.competitors;
          snapshot.ongoingCompetition.selectedVertCompetitors = this.competitors;
        } else {
          snapshot.initialCompetition.type = 1;
          snapshot.ongoingCompetition.type = 1;
          snapshot.initialCompetition.sortedTradCompetitors = this.competitors;
          snapshot.initialCompetition.selectedTradCompetitors = this.competitors;
          snapshot.ongoingCompetition.sortedTradCompetitors = this.competitors;
          snapshot.ongoingCompetition.selectedTradCompetitors = this.competitors;

          if (this.numFlightsToCreate == 0) {
            snapshot.initialCompetition.selectNumFlights = 0;
            snapshot.ongoingCompetition.selectNumFlights = 0;
          } else {
            snapshot.initialCompetition.selectNumFlights = this.numFlightsToCreate - 1;
            snapshot.ongoingCompetition.selectNumFlights = this.numFlightsToCreate - 1;
          }
        }

        let snapshotJSON = JSON.stringify(snapshot);
        let eventType = this.type == "Vertical" ? "0" : "1";
        let emptyObj = {};

        let competition = {
          id: this.compId,
          meetId: this.meet,
          competitionName: this.name,
          deviceId: "site",
          competitionDate: compDate,
          fields: emptyObj,
          eventInfo: emptyObj,
          items: emptyObj,
          scoreboard: emptyObj,
          snapshot: snapshot,
          isActive: 0,
          type: eventType,
        };

        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl = process.env.VUE_APP_BACKEND_URL + "/competitions/" + this.userObject.username;

        this.axios.post(apiUrl, competition, config).then((response) => {
          this.reset();
          this.$emit("updateCompetitions");
        });
      }
    },
    async createTeam(team) {
      let config = buildRequestHeaders(this.userObject.username);
      let apiUrl =
        process.env.VUE_APP_BACKEND_URL + "/teams/" + this.userObject.username;
      this.axios.post(apiUrl, team, config).then((response) => {
        this.fetchData();
        return;
      });
    },
  },
};
</script>

<style>
.create-card {
  position: absolute;
}
.scroll {
  overflow-y: scroll;
}

.ac-competitor {
  width: -webkit-fill-available;
}
.ac-competitor-team {
  color: rgba(0,0,0,0.5);
  font-size: 13px;
  float: right;
}

no-details .v-text-field__details {
  display: none;
}
.v-text-field__details {
  display: none;
}

#app
  > div.v-dialog__content.v-dialog__content--active
  > div
  > div
  > div.v-card__text
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-readonly.theme--light.v-text-field.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed
  > div.v-input__prepend-outer {
  display: none;
}

#app
  > div.v-dialog__content.v-dialog__content--active
  > div
  > div
  > div.v-card__text
  > div
  > div:nth-child(1)
  > div:nth-child(2)
  > div.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-readonly.v-input--dense.theme--light.v-text-field.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed
  > div.v-input__prepend-outer {
  display: none;
}
#v-list {
  text-align: left !important;
}
#list {
  text-align: left !important;
}

.v-list-item {
  text-align: left !important;
}

.td-link {
  color: #eb740e !important;
  font-weight: 500;
  cursor: pointer;
}
.stepper-detail-text {
  margin-left: 10px;
  margin-bottom: 6px !important;
  color: #666464;
}
.flex-buttons {
  display: flex;
}
.stepper-buttons {
  display: flex;
}
@media only screen and (max-width: 650px) {
  .stepper-buttons {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
.width-160 {
  width: 170px;
}
.width-160.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select.v-autocomplete
  > div
  > div.v-input__slot
  > div.v-select__slot
  > label {
  font-size: 13px;
}
.width-150 {
  min-width: 150px;
}
.width-135 {
  width: 135px;
}
.small-header {
  width: 50px;
}
</style>
