<template>
  <div>
    <div class="container-app pb-4">
      <h1>About FieldEvents</h1>
      <p class="lead text-muted">
        <i>
          FieldEvents was created to revolutionalize the way track meets are
          run.
        </i>
      </p>
      <p style="text-align: left">
        The goal of FieldEvents is to vastly improve the experience for the
        athlete and spectator of a track and field event while simplifying the
        job of the official.
      </p>

      <p class="lead text-muted main-color" style="text-align: left">
        FieldEvents supports officiating every field event in track and field
        through the
        <a
          class="color-orange"
          href="https://apps.apple.com/us/app/fieldevents/id1495383079"
          >iOS App</a
        >. Officials can choose to post results to the web through the app,
        which are instantly accessible for spectators to follow along on the
        <a class="color-orange" href="https://fieldevents.net/">Events</a>
        page. For more information about the FieldEvents app, visit the
        <a class="color-orange" href="https://fieldevents.net/app">App</a>
        page.
      </p>
      <v-btn
        class="get-fieldevents-btn mb-8 mt-8"
        href="https://apps.apple.com/us/app/fieldevents/id1495383079"
        target="_blank"
        color="secondary"
        elevation="14"
        rounded
        x-large>
        Get FieldEvents
      </v-btn>
    </div>
    <video v-if="$vssWidth > 600" :width="videoWidth"  ref="videoRef" src="" poster="https://fieldevents.net/live-results-demo-poster.png" loop id="video-container" />
    <div class="datastudio-report mt-10">
      <iframe
        v-if="$vssWidth >= 610"
        width="603"
        height="440"
        src="https://datastudio.google.com/embed/reporting/5e6bb7bb-212e-4b3c-8599-35745f8d149d/page/aJUiC"
        frameborder="0"
        style="border: 0"
        allowfullscreen
      ></iframe>
      <iframe
        v-else
        width="300"
        height="840"
        src="https://datastudio.google.com/embed/reporting/ed3b9a93-b1ba-45c0-8d8a-dab63d4d32b3/page/aJUiC"
        frameborder="0"
        style="border: 0"
        allowfullscreen
      ></iframe>
    </div>
    <v-divider />
    <div class="bio-container">
      <h2 class="bio-title">Behind the Development</h2>
      <div style="width:100%" class="mt-7">
        <div class="bio-img-container">
           <a  href="https://www.linkedin.com/in/drew-klein-b6431712b/" target="_blank">
          <img
            class="creator-bio"
            src="../assets/creator-fieldevents.jpg"
            alt="FieldEvents Creator"/>
          </a>
          <div class="creator-name"><a class="creator-name" href="https://www.linkedin.com/in/drew-klein-b6431712b/" target="_blank">Drew Klein, (creator)</a></div>
        </div>
        <div style="float:none;">
           <p class="lead text-muted main-color creator-text">
              I competed as a track and field athlete at both the high school and collegiate level. 
              During this time I developed a strong passion for the sport, and I experienced first-hand very successful track meets, as well as challenging ones. 
              My main event in track and field was the pole vault, so I became very familiar with the complexity of the event.
            </p>
            <p class="lead text-muted main-color creator-text">
              I created FieldEvents because I want to improve the experience for everyone involved in the sport. Most importantly the athlete, but also the officials, coaches, and spectators. 
              FieldEvents will do just that by streamlining the creation, running, and viewing of the event. As an athlete or coach, it can be crucial to know where you sit in the order and competition. FieldEvents provides real time scores/marks, and highlights where each competitor is in the rotation.
            </p>
        </div>
      </div>
    </div>

    <v-divider />
    <div class="container-app mt-4 mb-6">
      <h2>Contact</h2>
      <p class="lead text-muted main-color">
        Please send us a message below and we'll will get back to you as soon as
        possible!
      </p>
      <br />
      <v-alert v-if="messageSent" color="green" type="success"
        >Your message has been sent!</v-alert
      >
      <template v-if="!messageSent">
        <v-form v-model="valid">
          <v-row>
            <v-col>
              <v-text-field
                label="Your Name"
                filled
                required
                :rules="nameRules"
                counter="30"
                v-model="contactMessageName"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Your Email"
                email
                required
                :rules="emailRules"
                filled
                v-model="contactMessageEmail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            filled
            name="input-7-4"
            required
            :rules="messageRules"
            counter="600"
            count
            label="Message"
            v-model="contactMessageBody"
          ></v-textarea>
          <v-btn
            class="mt-4 get-fieldevents-btn"
            rounded
            large
            color="primary"
            :disabled="!valid"
            @click="sendMessage"
          >
            Send Message
          </v-btn>
        </v-form>
      </template>
    </div>
    <v-divider />
    <div class="footer lead text-muted pt-2">
      <a
        class="color-orange"
        style="padding-right: 16px"
        href="https://fieldevents.net/terms"
        >Terms & Conditions</a
      >
      <a
        class="color-orange"
        style="padding-left: 16px"
        href="https://fieldevents.net/privacy"
        >Privacy Policy</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {},
  props: {},
  data() {
    return {
      contactMessageBody: "",
      contactMessageName: "",
      contactMessageEmail: "",
      messageSent: false,
      valid: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      messageRules: [
        (v) => !!v || "Message is required",
        (v) => v.length <= 600 || "Message must be less than 600 characters",
      ],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    videoWidth() {
      if (this.$vssWidth < 900) {
        return this.$vssWidth -80
      } else {
        return 900
      }
    }

  },
  mounted () {
    if (this.$vssWidth > 600) {
      this.$refs.videoRef.src = "https://fieldevents.net/live-results-demo.mp4";
      this.$refs.videoRef.play();
    }
  },

  methods: {
    async sendMessage() {
      this.messageSent = true;

      let body = {
        email: this.contactMessageEmail,
        name: this.contactMessageName,
        message: this.contactMessageBody,
      };

      let apiUrl = process.env.VUE_APP_BACKEND_URL + "/utility/sendMessage/";
      this.axios.post(apiUrl, body).then((response) => {
        this.contactMessageBody = "";
        this.contactMessageName = "";
        this.contactMessageEmail = "";
        return;
      });

      setTimeout(() => (this.messageSent = false), 10000);
    },
  },
};
</script>

<style>
.datastudio-report {
  opacity: 1;
	animation-name: reportFadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2.5s;
}

@keyframes reportFadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.bio-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgb(52, 58, 64);
  color: #c9cacc;
}
.bio-text {
  display: block;
}
.bio-title {
  color: white;
}
.bio-img-container {
  display: block;
  float:right; 
  width:30%;
  top: -30px;
  position: relative;
}

@media screen and (max-width: 720px) { 
  .bio-img-container {
    float: unset;
    width: unset;
    margin: 0 auto;
    padding-bottom: 18px;
    top: 0px;  
  }
}

@media screen and (min-width: 1600px) { 
  .bio-img-container {
    top: -60px;  
  }
}
.creator-bio {
  height:200px;
  object-fit:cover;
  border-radius:50%;
  border: 5px solid white
}
.creator-text {
  text-align: justify;
  margin: auto;
  padding: 0 40px 0 40px;
}
.creator-name {
  color: white !important;
  font-style: italic;
  text-decoration: unset;
}
.container-app {
  max-width: 40rem;
  margin: auto;
  padding: 20px 8px 0 20px;
}
.footer {
  width: 100%;
  text-align: center;
  background-color: white;
}
</style>
