<template>
  <div>
    <v-switch
      v-if="canSeeAllCompetitions"
      class="show-all"
      v-model="showAll"
      @change="showAllToggled"
      label="Show All"
    ></v-switch>
    <div class="import">
      <v-btn small color="primary" dark 
      @click="$refs.uploadDialog.open()"
      > Import
      </v-btn>
    </div>
    <div class="create">
      <v-btn small color="primary" dark @click="createCompetition">
        Create
      </v-btn>
    </div>
    <v-layout column fill-height style="width: 100%">
      <v-flex style="overflow: auto">
        <v-card>

          <v-data-table
            :headers="tableHeaders"
            :items="competitions"
            :loading="!hasFetched"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :hide-default-footer="true"
            disable-pagination
            must-sort
            item-key="id"
            @click:row="(item) => clickLink(item)"
            no-data-text="No competitions added"
          >
            <template v-slot:[`item.competitionDate`]="{ item }">
              <div>
                {{ item.competitionDate | formatDateNew }}
              </div>
            </template>
            <template v-slot:[`item.meetName`]="{ item }">
              <div v-if="item.meetName">
                {{ item.meetName }}
              </div>
              <div v-else>
                <v-btn
                  @click.stop="associateMeet(item)"
                  outlined
                  x-small
                  fab
                  color="secondary"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.compCount`]="{ item }">
              <div style="width: 100px" @click.stop>
                {{ competitorCount(item) }}  
                <v-icon v-if="status(item) == 'Not started'" color="secondary" class="ml-4 mb-1" size="24"  @click.stop="editCompetitors(item)">mdi-pencil</v-icon>
              </div>
            </template>
             <template v-slot:[`item.status`]="{ item }">
               <div>
                 {{ status(item) }}
               </div>
            </template>
            <template v-slot:[`item.results`]="{ item }">
               <v-btn
                  v-if="status(item) == 'Finished' && hasResults(item)"
                  @click.stop="downloadResults(item, 'pdf')"
                  icon
                  
      
                  color="secondary"
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>

                <v-btn
                  v-if="status(item) == 'Finished' && hasResults(item)"
                  @click.stop="downloadResults(item, 'csv')"
                  icon
              
      
                  color="secondary"
                >
                 <csv-icon />
                </v-btn>
            </template>
            <template v-slot:[`item.isActive`]="{ item }">
               <v-btn
                  @click.stop="toggleVisibility(item)"
                  outlined
                  x-small
                  fab
                  :color="item.isActive == 1 ? 'secondary' : 'primary'"
                >
                  <v-icon v-if="item.isActive == 1">mdi-eye</v-icon>
                  <v-icon v-else color="primary">mdi-eye-off</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.link`]="{ item }">
               <v-btn
                  :disabled="item.isActive == 0"
                  @click.stop="clickLink(item)"
                  outlined
                  x-small
                  fab
                  color="secondary"
                >
                  <v-icon>mdi-link</v-icon>
                </v-btn>
            </template>

            <template v-slot:[`item.qr`]="{ item }">
            <v-btn
                  :disabled="item.isActive == 0"
                  @click.stop="getQrCode(item)"
                  outlined
                  x-small
                  fab
                  color="secondary"
                >
                  <v-icon>mdi-qrcode</v-icon>
                </v-btn>
              </template> 
          </v-data-table>
          <v-snackbar v-model="snackbar" :multi-line="true">
            <div v-html="snackbarText"></div>
            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-flex>
    </v-layout>
    <CreateCompetition
      @updateCompetitions="fetchData"
      @resetCreate="resetCreate"
      ref="createCompetition"
      :userObject="userObject"
      :isImporting="isImporting"
      :importedData="importedData"
    />
    <upload-dialog ref="uploadDialog" 
        @load="importedText = $event"
        @fileName="fileName"/>
     
    <associate-meet
      :userObject="userObject"
      ref="associateMeet"
      :competitionId="selectedCompetitionId"
      @associatedMeet="fetchData"
    />
  </div>
</template>

<script>
import CreateCompetition from "./CreateCompetition.vue";
import _ from "lodash";
import { buildRequestHeaders } from "@/utils";
import AssociateMeet from './AssociateMeet.vue';
import UploadDialog from '../partials/UploadDialog.vue';
import CsvIcon from '@/assets/csv.svg';
import html2pdf from 'html2pdf.js';


export default {
  name: "Competitions",
  components: {
    CreateCompetition,
    AssociateMeet,
    UploadDialog,
    CsvIcon
  },
  props: {
    userObject: Object,
  },
  data() {
    return {
      showAll: false,
      snackbar: false,
      snackbarText: "",
      competitions: [],
      hasFetched: false,
      results: [],
      intervalId: "",
      importedFileName: "",
      importedText: "",
      isImporting: false,
      importedData: [],
      sortBy: 'competitionDate',
      sortDesc: true,
      selectedCompetitionId: "",
    };
  },
  watch: {
    importedText: function (value) {
      if (value != "") {
        this.startImport();
      }
    },
  },
  computed: {
    canSeeAllCompetitions() {
      return this.userObject.username == 'andrew.klein21@gmail.com';
    },
    tableHeaders() {
      let headers = [
        { text: "User", value: "username", visible: this.showAll },
        { text: "Name", value: "competitionName", visible: true },
        { text: "Date", value: "competitionDate", visible: true },
        { text: "Meet", value: "meetName", visible: true },
        { text: "# of Competitors", value: "compCount", visible: true },
        { text: "Status", value: "status", visible: true },
        { text: "Results", value: "results", visible: true },
        { text: "Visibility", value: "isActive", visible: true },
        { text: "Link", value: "link", visible: true },
        { text: "QR Code", value: "qr", visible: true}
      ]

      return headers.filter(el => el.visible);
    }
  },
  mounted() {
    this.startFetchTimer();
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  methods: {
    startFetchTimer() {
      if (this.userObject.username != undefined) {
        this.fetchData();
      } else {
          setTimeout(() => {
            this.fetchData();
          }, 500);
      }

      if (!_.isNil(this.intervalId)) {
        window.clearInterval(this.intervalId);
      }
      // check for updates every 30 seconds
      this.intervalId = setInterval(this.fetchData, 30000);
    },
    fetchData() {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl = process.env.VUE_APP_BACKEND_URL + "/competitions/user/" +  this.userObject.username;

        if (this.showAll) {
          apiUrl = process.env.VUE_APP_BACKEND_URL + "/competitions/admin"
        }

        this.axios.get(apiUrl, config).then((response) => {
          this.competitions = response.data;
          this.hasFetched = true;
        });

        // Now fetch all results for this user!
        apiUrl = process.env.VUE_APP_BACKEND_URL + "/results/user/" + this.userObject.username;

        if (this.showAll) {
          apiUrl = process.env.VUE_APP_BACKEND_URL + "/results/all/admin"
        }

        this.axios.get(apiUrl, config).then((response) => {
          this.results = response.data;
        });
      }
    },
    showAllToggled() {
      this.competitions = [];
      this.hasFetched = false;
      this.fetchData();
    },
    resetCreate() {
      this.importedFileName = "";
      this.importedText = "";
      this.isImporting = false;
      this.importedData = [];
    },
    associateMeet(item) {
      this.selectedCompetitionId = item.id
      this.$refs.associateMeet.open();
    },
    toggleVisibility(item) {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);
        let action = item.isActive == 0 ? "enable" : "disable";
        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/competitions/" +
          action +
          "/" +
          item.id;

        this.axios.post(apiUrl, {}, config).then((response) => {
          this.fetchData();
        });
      }
    },
    competitorCount(item) {
      if (item.snapshot.initialCompetition.type == 0) {
        return item.snapshot.initialCompetition.selectedVertCompetitors.length;
      } else {
        return item.snapshot.initialCompetition.selectedTradCompetitors.length;
      }
    },
    status(item) {
      if (item.snapshot.competitionStarted == false) {
        return "Not started";
      } else {
        if (item.snapshot.ongoingCompetition.endedComp == false) {
          return "Ongoing";
        } else {
          return "Finished";
        }
      }
    },
    hasResults(item) {
      if (this.results.length) {
        return this.results.filter((e) => e.id === item.id).length > 0;
      } else {
        return false;
      }
    },
    getResults(item, type) {
      let itemResults = this.results.filter((e) => e.id === item.id)[0];
      return type == 'pdf' ? itemResults.html : itemResults.csv;
    },
    clickLink(item) {
      let url = "https://fieldevents.net/event?eventId=" + item.id;
      window.open(url, "_blank");
    },
    async getQrCode(item) {
      let apiUrl = `${process.env.VUE_APP_BACKEND_URL}/utility/generateQRCode`
      let data = {
        id: item.id,
        type: "event"
      }

      this.axios.post(apiUrl, data).then((response) => {
        let qrCodeUrl = response.data.url;
        // wait 1 second
        setTimeout(() => {
           window.open(qrCodeUrl, '_blank');
        }, 1000);
      });
    },
    async downloadResults(item, type = 'pdf') {
      if (this.userObject.username != undefined) {
        let filenamePrefix = (item.meetName + "-" + item.competitionName + " Results").replace(/'|’/g, "");
        let safeFilenamePrefix = filenamePrefix.replace(/[^a-z0-9 _-]/gi, '_').toLowerCase();
        let body = await this.getResults(item, type);

        // if pdf, create the content and show in print window
        if (type == 'pdf') {
          html2pdf(body, {
            margin: 10,
            filename: safeFilenamePrefix + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
          }).save();
        } else {
          // if csv, download the file
          const blob = new Blob([body], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = safeFilenamePrefix + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
        }

      }
    },
    openUploadDialog() {
      this.resetCreate();
      this.$refs.uploadDialog.open()
    },
    createCompetition(item) {
      this.$refs.createCompetition.open();
    },
    fileName(item) {
      this.importedFileName = item;
    },
    editCompetitors(item) {
      this.$refs.createCompetition.open(item);
    },
    unsupportedImport() {
      this.snackbarText =
        '<span>This looks like an unsupported file, FieldEvents currently only supports importing a lynx.evt file or a <a class="snackbar-link" href="https://fieldevents.net/Entries.csv">CSV like this</a>.</span>';
      this.snackbar = true;
    },
    startImport() {
      let fileExt = this.importedFileName.split(".").pop();
      fileExt = fileExt.toLowerCase();

      switch (fileExt) {
        case "evt":
          this.convertEVT();
          break;
        case "csv":
          this.convertCSV();
          break;
        case "html":
        case "htm":
          this.convertHtml();
          break;
        default:
          this.unsupportedImport();
          break;
      }
    },
    convertEVT() {
      // Gather the list of events from the file
      var events = [];
      let evtArr = this.importedText.split("\n");

      for (let i = 0; i < evtArr.length; i++) {
        if (evtArr[i].charAt(0) != ";" && evtArr[i].charAt(0) != ",") {
          let lineArr = evtArr[i].split(",");

          for (let j = 0; j < lineArr.length; j++) {
            if (lineArr[j].charAt(0) != "") {
              let eventsTwo = lineArr[j].split("\n");

              for (let k = 0; k < eventsTwo.length; k++) {
                let eventsThree = eventsTwo[k];

                if (
                  eventsThree.length > 5 &&
                  !(
                    eventsThree.includes("Meter") ||
                    eventsThree.includes("Mile")
                  )
                ) {
                  events.push(eventsThree);
                }
              }
            }
          }
        }
      }
      events = _.uniq(events);

      // Build the list of competitors in each event
      let eventsWithCompetitors = [];

      events.forEach(function (event) {
        let type =
          event.toLowerCase().includes("pole") ||
          event.toLowerCase().includes("high")
            ? "Vertical"
            : "Horizontal";

        let eventObj = {
          isImporting: true,
          name: event,
          type: type,
          competitors: [],
        };

        // Loop through each line
        for (let i = 0; i < evtArr.length; i++) {
          if (evtArr[i].includes(event)) {
            let flight = evtArr[i].split(",")[2];
            let z = i + 1;

            for (let t = i + 1; t < evtArr.length; t++) {
              let f = evtArr[t].charAt(0);

              // competitors start with comma
              if (f == ",") {
                let lineArr = evtArr[t].split(",");

                let comp = {
                  name: lineArr[4] + " " + lineArr[3],
                  team: lineArr[5],
                  flight: flight,
                };
                eventObj.competitors.push(comp);
              } else {
                break;
              }
            }
          }
        }
        // flight = flight + 1
        eventsWithCompetitors.push(eventObj);
      });

      if (eventsWithCompetitors.length) {
        this.importedData = eventsWithCompetitors;
        this.isImporting = true;
        this.createCompetition(null);
      }
    },
    convertCSV() {
      const athleticNetHeaders = [
        "type",
        "teamcode",
        "teamname",
        "firstname",
        "lastname",
        "gender",
        "grade",
        "eventcode",
      ];
      const mileSplitHeaders = [
        "event",
        "distance",
        "division",
        "lname",
        "fname",
        "gender",
        "team",
        "teamid",
        "seed",
        "grade",
        "city",
        "state",
        "country",
        "birthdate",
        "seedmeet",
        "seeddate",
        "note",
        "contactname",
        "contactemail",
        "datecreated",
        "dateupdated",
      ];
      const eventCodeDict = {
        PV: "Pole Vault",
        HJ: "High Jump",
        LJ: "Long Jump",
        TJ: "Triple Jump",
        SP: "Shot Put",
        WT: "Weight Throw",
        DT: "Discus Throw",
        JT: "Javlin Throw",
      };
      const genderDict = {
        M: "Men's",
        F: "Women's",
      };
      let csvRows = this.importedText.split("\n");
      let csvHeaders = csvRows[0].split(",");
      var events = [];

      // Lowercase and trim line breaks
      csvHeaders = csvHeaders.map((name) => name.toLowerCase());
      csvHeaders = csvHeaders.map((name) => name.trim());

      // remove empty headers
      csvHeaders = csvHeaders.filter((name) => name != "");

      if (_.isEqual(csvHeaders, athleticNetHeaders)) {
        // -----------------------------------------------
        // Convert Athletic.net CSV
        // -----------------------------------------------
        let rows = this.importedText.split("\n");

        // Build the list of events
        for (let i = 1; i < rows.length; i++) {
          let cells = rows[i].split(",");
          events.push(
            genderDict[cells[5].trim()] + " " + eventCodeDict[cells[7].trim()]
          );
        }

        events = _.uniq(events);

        // Build the list of competitors in each event
        let eventsWithCompetitors = [];

        events.forEach(function (event) {
          let type =
            event.toLowerCase().includes("pole vault") ||
            event.toLowerCase().includes("high jump")
              ? "Vertical"
              : "Horizontal";

          let eventObj = {
            isImporting: true,
            name: event,
            type: type,
            competitors: [],
          };

          // Loop through each row and add the competitor if the event matches
          for (let i = 1; i < rows.length; i++) {
            let cells = rows[i].split(",");

            // event code and gender match
            if (
              event ==
              genderDict[cells[5].trim()] + " " + eventCodeDict[cells[7].trim()]
            ) {
              let comp = {
                name: cells[3].trim() + " " + cells[4].trim(),
                team: cells[2].trim(),
                flight: 0,
              };
              eventObj.competitors.push(comp);
            }
          }
          // flight = flight + 1
          eventsWithCompetitors.push(eventObj);
        });

        if (eventsWithCompetitors.length) {
          this.importedData = eventsWithCompetitors;
          this.isImporting = true;
          this.createCompetition(null);
        }
      } else if (_.isEqual(csvHeaders, mileSplitHeaders)) {
        // -----------------------------------------------
        // Convert MileSplit CSV
        // -----------------------------------------------
        let cleanImportedText = this.importedText.replace(/['"]+/g, "");

        let rows = cleanImportedText.split("\n");

        // Build the list of events
        for (let i = 1; i < rows.length; i++) {
          let cells = rows[i].split(",");

          if (cells[1] == "0") {
            //let eventSplit = cells[0].split(" ");
            // events.push(eventSplit.slice(2, eventSplit.length).join(' '));
            events.push(cells[0].trim());
          }
        }

        events = _.uniq(events);

        // Build the list of competitors in each event
        let eventsWithCompetitors = [];

        events.forEach(function (event) {
          let type =
            event.toLowerCase().includes("pole vault") ||
            event.toLowerCase().includes("high jump")
              ? "Vertical"
              : "Horizontal";

          let eventObj = {
            isImporting: true,
            name: event,
            type: type,
            competitors: [],
            createFlights: false,
            numCompsInFlight: 10,
          };

          // Loop through each row and add the competitor if the event matches
          for (let i = 1; i < rows.length; i++) {
            let cells = rows[i].split(",");

            // event matches
            if (cells[0].trim().includes(event)) {
              let comp = {
                name: cells[4].trim() + " " + cells[3].trim(),
                team: cells[6].trim(),
                flight: 0,
              };
              // Build the competitor's seed if it contains -
              if (cells[8].trim().includes("-")) {
                let seedObj = cells[8].trim().split("-");
                let ft = parseInt(seedObj[0]);
                let inches = parseInt(seedObj[1]);
                let totalIn = ft * 12 + inches;

                comp.seed = totalIn;
              } else {
                comp.seed = 0;
              }

              eventObj.competitors.push(comp);
            }
          }
          eventObj.competitors = _.sortBy(eventObj.competitors, (e) => {
            return e.seed;
          });
          // flight = flight + 1
          eventsWithCompetitors.push(eventObj);
        });

        if (eventsWithCompetitors.length) {
          this.importedData = eventsWithCompetitors;
          this.isImporting = true;
          this.createCompetition(null);
        }
      } else {
        this.unsupportedImport();
      }
    },
    convertHtml() {
      // This converter will except an html created from Hy-Tek by going to Reports->Entry Lists-> then toggling the following settings only
      //   Under the first tab (Report Type/Options/Format) 
      //     Report type: set to "Entry List" and "flat HTML" checked
      //     Primary sort: set to "Team"
      //     Options: set to "Athletes Only"
      //   Under the second tab ("Include in Report")
      //     Uncheck everything

      const events = [
        "Pole Vault",
        "High Jump",
        "Long Jump",
        "Triple Jump",
        "Shot Put",
        "Weight Throw",
        "Discus Throw",
        "Javlin Throw",
      ]
      const genders = [
        "Men's",
        "Men",
        "Women's",
        "Women",
        "Boy's",
        "Boy",
        "Girl's",
        "Girl",
        "Mixed"
      ];

      let foundEvents = [];
      let athletes = [];

      let uncleanedRows = this.importedText.split("\n");
      let rows = [];
      for (let row of uncleanedRows) {
        rows.push(row.trim());
      }

      rows = rows.filter(el => !el.startsWith("<"))
      rows = rows.filter(el => el != "");
      rows = rows.filter(el => !el.startsWith("---"))

      let startIndex = 0
      const startOfTeams = (element) => element.includes('School Entries');
      startIndex = rows.findIndex(startOfTeams);
      let team = ""

      for (let i = startIndex + 1; i < rows.length; i++) {
        let isAthleteRow = !isNaN(rows[i].charAt(0)) && rows[i].substring(0, 3).includes(".");
        let isTeamRow = !isAthleteRow && !rows[i].startsWith("#");

        if (isTeamRow) {
          team = rows[i].split(" - ")[1]
        }

        if (isAthleteRow && !isTeamRow) {
          let nameStartIndex = rows[i].indexOf(". ")
          let nameEndIndex = rows[i].lastIndexOf(" - ")
          let nameComponents = rows[i].substring(nameStartIndex + 2, nameEndIndex).split(",")

          let athlete = {
            name: nameComponents[1].trim() + " " + nameComponents[0].trim(),
            team: team,
            flight: 0
          }

          for (let j = i + 1; j < rows.length; j++) {
            if (!rows[j].startsWith("#")) {
              // new athlete or team found
              athletes.push(athlete)
              break;
            } else {
              let tempGender = "Men's"
              for (let gender of genders) {
                let cleanRowGender = _.clone(rows[j]).replace("'", "").toLowerCase()
                let cleanGender = _.clone(gender).replace("'", "").toLowerCase()

                if (cleanRowGender.includes(cleanGender)) {
                  tempGender = _.clone(gender)
                }
              }

              for (let event of events) {
                if (rows[j].toLowerCase().includes(event.toLowerCase())) {
                  let eventName = tempGender +  " " + event
                  foundEvents.push(eventName);
                  athlete[eventName] = true
                }
              }
            }
          }
        }
      }

      foundEvents = _.uniq(foundEvents);

      // Build the list of competitors in each event
      let eventsWithCompetitors = [];

      foundEvents.forEach(function (event) {
        let type =
          event.toLowerCase().includes("pole vault") ||
          event.toLowerCase().includes("high jump")
            ? "Vertical"
            : "Horizontal";

        let eventObj = {
          isImporting: true,
          name: event,
          type: type,
          competitors: [],
        };

        for (let athlete of athletes) {
          if (!_.isNil(athlete[event] && athlete[event] == true)) {
            let comp = {
              name: athlete.name,
              team: athlete.team,
              flight: athlete.flight,
            };
            eventObj.competitors.push(comp);
          }
        }

        eventsWithCompetitors.push(eventObj);
      });

      if (eventsWithCompetitors.length) {
        this.importedData = eventsWithCompetitors;
        this.isImporting = true;
        this.createCompetition(null);
      }
    },
    convertAthleticNet() {},
  },

};
</script>

<style>
.color-div {
  width: 80px;
  border-radius: 6px;
  box-shadow: 2px 2px 20px rgba(0,0,0,0.15);
}
.snackbar-link {
  color: #eb740e !important;
}
</style>
