import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

function initialState() {
  return {
    isSignedIn: false,
    selectedDrawerItem: "Meets",
    darkMode: false,
    isPrinting: false,
  };
}

export default new Vuex.Store({
  state: initialState,
  mutations: {
    setSelectedDrawerItem(state, selectedDrawerItem) {
      state.selectedDrawerItem = selectedDrawerItem;
    },
    setIsSignedIn(state, isSignedIn) {
      state.isSignedIn = isSignedIn;
    },
    setDarkMode(state, darkMode) {
      state.darkMode = darkMode;
    },
    setIsPrinting(state, isPrinting) {
      state.isPrinting = isPrinting;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      paths: ["isSignedIn", "selectedDrawerItem", "darkMode"],
      storage: window.sessionStorage,
    }),
  ],
});
