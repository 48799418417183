<template>
  <div class="full-w">
    <template v-if="isStarted" class="full-w">
      <!-- PREVIOUS MARK -->
      <displayed-competitor
        v-if="displayPrevious"
        :eventType="eventType"
        :height="previousCompetitorHeight"
        :preferredUnits="preferredUnits"
        :competitorName="previousCompetitorUp"
        :competitorTeam="previousCompetitorTeam"
        :competitorScore="previousCompetitorScore"
        :fullscreen="fullscreen"
        :competitorBackgroundColor="previousCompetitorTeamColor"
        :competitorForegroundColor="previousCompetitorforegroundColor"
        :timerObject="timerObject"
      />

      <!-- MOST RECENT MARK -->
      <displayed-competitor
        v-else
        :eventType="eventType"
        :height="currentHeight"
        :preferredUnits="preferredUnits"
        :competitorName="competitorUp"
        :competitorTeam="competitorTeam"
        :competitorScore="competitorScore"
        :fullscreen="fullscreen"
        :competitorBackgroundColor="teamColor"
        :competitorForegroundColor="foregroundColor"
        :timerObject="timerObject"
      />

      <!-- ORDER OF COMPETITORS -->
      <displayed-order
        v-if="showOrder"
        :orderOfComps="orderOfComps"
        :fullscreen="fullscreen"
      />
    </template>
    <template v-else>
      <h1
        v-bind:style="headerText"
        style="text-align: center; margin-top: 200px"
      >
        Waiting for competition to start...
      </h1>
    </template>
    <scoreboard-settings
      :showOrder="showOrder"
      currentView="scoreboard"
      @toggleShowOrder="toggleShowOrder"
      :fullscreen="fullscreen"
      :isUserActive="isUserActive"
    />
  </div>
</template>
<script>
import DisplayedCompetitor from "./ScoreboardComponents/DisplayedCompetitor.vue";
import DisplayedOrder from "./ScoreboardComponents/DisplayedOrder.vue";
import ScoreboardSettings from "./ScoreboardSettings.vue";
export default {
  components: { ScoreboardSettings, DisplayedCompetitor, DisplayedOrder },
  name: "EventScoreboard",
  props: {
    msg: String,
    eventId: String,
    eventType: String,
    currentHeight: String,
    preferredUnits: String,
    previousCompetitorUp: String,
    previousCompetitorTeam: String,
    previousCompetitorTeamColor: String,
    previousCompetitorforegroundColor: String,
    previousCompetitorScore: String,
    previousCompetitorHeight: String,
    attemptsRemaining: String,
    competitorUp: String,
    competitorTeam: String,
    competitorScore: String,
    teamColor: String,
    foregroundColor: String,
    orderOfComps: Array,
    fullscreen: Boolean,
    isUserActive: Boolean,
  },
  data() {
    return {
      showOrder: true,
      displayPrevious: false,
      intervalId: null,
      timerObject: null
    };
  },
  async mounted() {
    await this.fetchTimerData();
    this.intervalId = setInterval(this.fetchTimerData, 3000);
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  computed: {
    isStarted: function () {
      return this.msg != "not_started";
    },
    attemptsRemainingColor() {
      if (this.attemptsRemaining >= 3) {
        return "green";
      } else if (this.attemptsRemaining == 2) {
        return "orange";
      } else {
        return "red";
      }
    },
    headerText: function () {
      let size = this.$vssWidth / 16 > 70 ? 70 : this.$vssWidth / 16;

      return {
        "font-size": size + "px",
      };
    },
  },
  watch: {
    competitorUp: function (val) {
      this.displayPrevious = true;
    },
    competitorTeam: function (val) {
      this.displayPrevious = true;
    },
    competitorScore: function (val) {
      this.displayPrevious = true;
    },
    previousCompetitorUp: function (val) {
      this.displayPrevious = true;
    },
    previousCompetitorTeam: function (val) {
      this.displayPrevious = true;
    },
    previousCompetitorScore: function (val) {
      this.displayPrevious = true;
    },
    currentHeight: function (val) {
      this.displayPrevious = true;
    },
    previousCompetitorHeight: function (val) {
      this.displayPrevious = true;
    },
    displayPrevious: function (val) {
      if (val === true) {
        this.updateScoreboard();
      }
    },
  },
  methods: {
    toggleShowOrder(val) {
      this.showOrder = val;
    },
    updateScoreboard() {
      setTimeout(() => {
        this.displayPrevious = false;
      }, 8000);
    },
    async fetchTimerData() {
      let apiUrl = process.env.VUE_APP_BACKEND_URL + "/timer/" + this.eventId;

      this.axios.get(apiUrl, {}).then((response) => {
        if (response.data && response.data.length > 0 ) {
          this.timerObject = response.data[0]
        }
      });
    }
  },
};
</script>

<style>
.full-w {
  width: 100%;
}
</style>
