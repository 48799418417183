<template>
  <fullscreen :fullscreen.sync="fullscreen" :fullscreen-class="(isDarkMode && view == 'scoreboard') ? '' : 'fullscreen'">
    <v-container fluid :class="['event-container', {'mobile-container' : hideToolbar}]">
      <template v-if="!isPrinting">
          <v-btn
          :class="['table-btn', 'fade-transition', { 'hidden' : !isUserActive }, { 'table-btn-fullscreen': (fullscreen || hideToolbar) }]"
          :color="view == 'table' ? '#eb740e' : '#9e9e9e'"
          small
          fab
          dark
          @click="view = 'table'"
        >
          <v-icon>mdi-table-large</v-icon>
        </v-btn>
        <v-btn
          :class="['scoreboard-btn', 'fade-transition', { 'hidden' : !isUserActive }, { 'scoreboard-btn-fullscreen': (fullscreen || hideToolbar)}]"
          :color="view == 'scoreboard' ? '#eb740e' : '#9e9e9e'"
          small
          fab
          dark
          @click="view = 'scoreboard'"
        >
          <v-icon>mdi-card-account-details</v-icon>
        </v-btn>

        <v-btn
          v-if="$vssWidth >= 1024"
          :class="['fullscreen-btn', 'fade-transition', { 'hidden' : !isUserActive }, { 'fullscreen-btn-fullscreen': (fullscreen || hideToolbar) }]"
          color="primary"
          small
          fab
          dark
          @click="toggleFullscreen"
        >
          <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
          <v-icon v-else>mdi-fullscreen-exit</v-icon>
        </v-btn>

        <v-menu
          v-if="$vssWidth >= 1024 && (view == 'table') && !(fullscreen || hideToolbar)"
        
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            :class="['share-competition-btn', 'fade-transition', { 'hidden' : !isUserActive },]"
              color="primary"
              small
              fab
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-export-variant</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="getQR">
              <v-list-item-title>Get QR Code</v-list-item-title>
            </v-list-item>  
            <v-list-item @click="copyLink">
              <v-list-item-title>Copy Link</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printResults">
              <v-list-item-title>Print {{msg =='not_started' ? 'Starting List' : 'Results' }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <competitor-order
          v-if="view == 'table' && orderOfComps.length"
          :isUserActive="isUserActive"
          :orderOfComps="orderOfComps"
          :fullscreen="fullscreen"
        />
        <v-alert
          v-if="
            msg != '' &&
            msg != 'not_started' &&
            competitorUp != 'Competition Ended' &&
            view == 'table'
          "
          dense
          color="#84bbf5"
          dismissible
          class="msg-alert"
        >
          {{ msg }}: {{ currentHeight }}
        </v-alert>
      </template>
     
      <v-img 
        alt="FieldEvents Logo"
        :class="'printing-logo' + (isPrinting ? ' printing-logo-printing' : '')"
        contain
        src="@/assets/logo384.png"
        transition="scale-transition"
        width="300"
      />

      <event-table
        v-if="view == 'table'"
        :type="competitionType"
        :competitionName="competitionName"
        :competitionDate="competitionDate"
        :meetName="meetName"
        :headers="headers"
        :tableData="tableData"
        :userTeams="userTeams"
        :competitorTeams="competitorTeams"
        :msg="msg"
        :attemptsRemaining="attemptsRemaining"
        :competitorUp="competitorUp"
        :competitorTeam="competitorTeam"
        :teamColor="teamColor"
        :foregroundColor="foregroundColor"
      />
      <event-scoreboard
        v-if="view == 'scoreboard'"
        :msg="msg"
        :eventId="eventId"
        :eventType="eventType"
        :currentHeight="currentHeight"
        :preferredUnits="preferredUnits"
        :previousCompetitorUp="previousCompetitorUp"
        :previousCompetitorTeam="previousCompetitorTeam"
        :previousCompetitorScore="previousCompetitorScore"
        :previousCompetitorTeamColor="previousCompetitorTeamColor"
        :previousCompetitorforegroundColor="previousCompetitorforegroundColor"
        :previousCompetitorHeight="previousCompetitorHeight"
        :attemptsRemaining="attemptsRemaining"
        :competitorUp="competitorUp"
        :competitorScore="competitorScore"
        :competitorTeam="competitorTeam"
        :teamColor="teamColor"
        :foregroundColor="foregroundColor"
        :orderOfComps="orderOfComps"
        :fullscreen="fullscreen"
        :isUserActive="isUserActive"
      />
      <span v-if="view == 'table' && !isPrinting" class="unofficial"
        >{{ fullscreen ? "Powered by FieldEvents.net - " : "" }}All results
        unofficial</span
      >
    </v-container>
  </fullscreen>
</template>
<script>
import CompetitorOrder from "./partials/CompetitorOrder.vue";
import EventScoreboard from "./partials/EventScoreboard.vue";
import EventTable from "./partials/EventTable.vue";
import _ from "lodash";
import html2pdf from "html2pdf.js";

export default {
  name: "Event",
  components: { CompetitorOrder, EventTable, EventScoreboard },
  data() {
    return {
      fullscreen: false,
      view: "table",
      competitionType: 0,
      competitionDate: "",
      snackbar: false,
      snackbarText: "",
      competitionData: "",
      parsedSnapshot: "",
      competitorTeams: {},
      teamsLoaded: false,
      userTeams: [],
      results: [],
      intervalId: "",
      importedFileName: "",
      importedText: "",
      isImporting: false,
      importedData: [],
      competitorOrderDialog: false,
      isUserActive: false,
      isScoreboard: false,
      eventType: "vertical",
      preferredUnits: 0,
      competitionName: "",
      meetName: "",
      competitorUp: "",
      competitorTeam: "",
      teamColor: "",
      foregroundColor: "",
      attemptsRemaining: "",
      msg: "",
      currentHeight: "",
      stickyHeader: String(window.innerHeight - 150) + "px",
      noCollapse: false,
      fields: "",
      items: "",
      timer: "",
      displayPrevious: false,
      competitorScore: "",
      competitorMark: "",
      previousCompetitorUp: "",
      previousCompetitorTeam: "",
      previousCompetitorTeamColor: "",
      previousCompetitorforegroundColor: "",
      previousCompetitorHeight: "",
      previousCompetitorMark: "",
      previousCompetitorScore: "",
      orderOfComps: [],
      isOrderShown: false,
    };
  },
  computed: {
    hideToolbar() {
      return window.location.href.includes("hideToolbar=1");
    },
    eventId() {
      if (
        !_.isNil(this.$route.params.eventId) &&
        this.$route.params.eventId != ""
      ) {
        return this.$route.params.eventId;
      } else {
        return this.$route.query.eventId;
      }
    },
    isDarkMode() {
      return this.$store.state.darkMode
    },
    isPrinting() {
      return this.$store.state.isPrinting
    },
    headers() {
      let headers = [];
      let foundFlight = false;
      if (this.competitionData != "") {
        let tempHeaders = this.competitionData.fields;
        for (let item of tempHeaders) {

          let headerItem = {
            text: "",
            secondaryText: "",
            value: item.key,
            isPrelim: false,
            isFinal: false,
            isHeight: false,
            width: 60,
            class: ""
          };


          if (item.key == "name" || item.key == "team" || item.key == "flight") {
            headerItem.width = 155;
            headerItem.fixed = true;
            headerItem.text = this.$options.filters.titleCase(item.key);

            if (item.key == "name") {
              headerItem.class = "freezeheader";
              headerItem.text = "Competitor";
            } else if (item.key == "flight") {
              foundFlight = true;
            }
          } else if (item.key.includes("--")) {
            // VERTICAL HEIGHT COLUMN
            headerItem.text = item.key.split("--")[0]
            headerItem.secondaryText = item.key.split("--")[1]
            headerItem.width = 100;
            headerItem.align = 'center'
            headerItem.isHeight = true
          } else if (item.key.includes("_")) {
             // HORIZONTAL ATTEMPT COLUMN
            headerItem.text = "Attempt" + "  " + item.key.split("_")[1];
            headerItem.width = 110;
            headerItem.class = 'align-center';
            
            if (this.competitionData.eventInfo.createFinal == 'true' && (item.key.split("_")[1] <= parseInt(this.competitionData.eventInfo.attemptsPrelim))){
              headerItem.isPrelim = true
            } else if (this.competitionData.eventInfo.createFinal == 'true' && (item.key.split("_")[1] > parseInt(this.competitionData.eventInfo.attemptsPrelim))){
              headerItem.isFinal = true
              let finalAttemptNumber =  parseInt(item.key.split("_")[1]) - parseInt(this.competitionData.eventInfo.attemptsPrelim)
              headerItem.text = "Attempt " + finalAttemptNumber

              if (finalAttemptNumber == 1) {
                headerItem.class = "start-of-finals-header";
              }
            } 
          } else {
            headerItem.text = this.$options.filters.titleCase(item.key);
          }

          if (item.variant == "primary") {
            headerItem.class = "active-height";
          }

          if (item.key != "team") {
            headers.push(headerItem);
          }
        }
      }

      if (foundFlight) {
        this.swapArrayLocs(headers, 1, 2);
      }

      return headers;
    },
    tableData() {
      let tableData = [];
      if (this.competitionData != "") {
        tableData = this.competitionData.items;
      }

      return tableData;
    },
  },
  async mounted() {
    await this.fetchCompetitionData();
    this.setupActivityListeners();
    this.intervalId = setInterval(this.fetchCompetitionData, 5000);
    setTimeout(() => {
      this.attemptScrollToActiveHeight();
    }, 100);
  },
  destroyed() {
    window.clearInterval(this.intervalId);
    this.removeActivityListeners();
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    resetActivityTimer() {
      clearTimeout(this.activityTimer);
      this.isUserActive = true;
      this.activityTimer = setTimeout(() => {
        this.isUserActive = false;
      }, 3000); // 3 seconds
    },
    setupActivityListeners() {
      document.addEventListener('mousemove', this.resetActivityTimer);
      document.addEventListener('touchstart', this.resetActivityTimer);
    },
    removeActivityListeners() {
      document.removeEventListener('mousemove', this.resetActivityTimer);
      document.removeEventListener('touchstart', this.resetActivityTimer);
      clearTimeout(this.activityTimer);
    },
    attemptScrollToActiveHeight() {
      try {
        let activeHeight = document.getElementsByClassName("active-height");

        if (activeHeight.length > 0) {
          let element = activeHeight[0];
          let wrapper = document.getElementsByClassName("v-data-table__wrapper")[0];

          if (wrapper) {
            let topPosition = element.offsetTop - wrapper.offsetTop;
            let leftPosition = element.offsetLeft - wrapper.offsetLeft;

            wrapper.scrollTo({
              top: topPosition,
              left: leftPosition,
              behavior: 'smooth'
            });
          }
        }
      } catch (err) {
      // ignore error
      console.log(err)
      }
    },
    async fetchCompetitionData() {
      let apiUrl =
        process.env.VUE_APP_BACKEND_URL +
        "/competitions/public-competition/" +
        this.eventId;

      this.axios.get(apiUrl, {}).then((response) => {
        this.competitionData = response.data[0];
        this.competitionType = parseInt(this.competitionData.type);
        let scoreJSON = response.data[0].scoreboard;
        this.competitionName = response.data[0].competitionName;
        this.competitionDate = response.data[0].competitionDate;
        this.eventType = scoreJSON.eventType;
        this.preferredUnits = scoreJSON.preferredUnits;
        this.meetName = response.data[0].meetName;
        this.competitorUp = scoreJSON.compUp;
        this.competitorTeam = scoreJSON.compTeam;
        this.competitorScore = scoreJSON.currentScore;
        this.competitorMark = scoreJSON.compMark;
        this.attemptsRemaining = scoreJSON.attemptsRemaining;
        this.teamColor = scoreJSON.teamColor;
        this.foregroundColor = scoreJSON.foregroundColor;
        this.msg = scoreJSON.msg;
        this.currentHeight = scoreJSON.height;
        this.previousCompetitorUp = scoreJSON.prevCompUp;
        this.previousCompetitorTeam = scoreJSON.prevCompTeam;
        this.previousCompetitorTeamColor = scoreJSON.prevTeamColor;
        this.previousCompetitorforegroundColor = scoreJSON.prevForegroundColor;
        this.previousCompetitorHeight = scoreJSON.prevHeight;
        this.previousCompetitorMark = scoreJSON.prevMark;
        this.previousCompetitorScore = scoreJSON.prevScore;
        this.orderOfComps = scoreJSON.orderOfComps;

        // Fix incomplete colors...
        if (this.previousCompetitorforegroundColor == "#0000") {
          this.previousCompetitorforegroundColor == "#00000";
        }

        if (this.foregroundColor == "#0000") {
          this.foregroundColor == "#00000";
        }

        // Further process competition data to create dict of competitors and their teamID
        this.parsedSnapshot = this.competitionData.snapshot;
        let compArray = [];

        // Get the competition competitors so we can extract the teamId
        if (this.competitionType == 0) {
          compArray =
            this.parsedSnapshot.ongoingCompetition.selectedVertCompetitors;
        } else {
          compArray =
            this.parsedSnapshot.ongoingCompetition.selectedTradCompetitors;
        }

        // Create a dict containing the competitor name and teamId as key value pair
        this.competitorTeams = new Object();

        for (let i = 0; i < compArray.length; i++) {
          let name = compArray[i].name;
          let teamId = compArray[i].teamId;
          this.competitorTeams[name] = teamId;
        }
        this.fetchTeams();
      });
    },
    fetchTeams() {
      if (this.teamsLoaded == false) {
        // Now load all of the teams for this competiton creator so we can get the team colors for displaying
        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/teams/" +
          this.competitionData.username;
        this.axios.get(apiUrl, {}).then((response) => {
          this.userTeams = response.data;
          this.teamsLoaded = true;
        });
      }
    },
    swapArrayLocs(arr, index1, index2) {
      var temp = arr[index1];

      arr[index1] = arr[index2];
      arr[index2] = temp;
    },
    async getQR() {
      let apiUrl = `${process.env.VUE_APP_BACKEND_URL}/utility/generateQRCode`
      let data = {
        id: this.eventId,
        type: "event"
      }

      this.axios.post(apiUrl, data).then((response) => {
        let qrCodeUrl = response.data.url;
        setTimeout(() => {
           window.open(qrCodeUrl, '_blank');
        }, 1000);
      });
    },
    async copyLink() {
      try {
        let url = window.location.href;
        await navigator.clipboard.writeText(url);
        console.log('Link copied to clipboard');
      } catch (err) {
        console.error('Failed to copy link: ', err);
      }
    },
    async printResults() {
      await this.$store.commit("setIsPrinting", true);

      let element = document.getElementById('componentId'); // replace 'componentId' with the id of your component's root element
      console.log(this.competitionData)
      // calculate width based on header width
      let width = 0;
      for (let item of this.headers) {
        if (item.width) {
          width += item.width;
        }
      }

      // add about 10% to width to account for padding
      width = width * 1.1;

      // calculate height based on number of rows
      let height = 300;
      for (let item of this.tableData) {
        height += 50;
      }

      let scaleFactor = Math.min(window.innerWidth / width, window.innerHeight / height);
      document.body.style.transform = `scale(${scaleFactor})`;
      document.body.style.transformOrigin = 'top left';

      // convert px to inches for height and width
      let customPageHeight = height / 96;
      let customPageWidth = width / 96;

      let opt = {
        margin: 0.25,
        filename: this.competitionName + ' (' + this.meetName + ').pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'in', format: [customPageWidth, customPageHeight], orientation: 'landscape' }
      };

      html2pdf().set(opt).from(element).save()

        // Reset the viewport
      document.body.style.transform = '';

      this.$store.commit("setIsPrinting", false);
    }
  },
};
</script>

<style>
.fullscreen {
  background: white !important;
}
.event-container {
  padding-bottom: 0 !important;
}
.mobile-container {
  padding-top: 0 !important;
}
.table-btn {
  z-index: 1;
  position: absolute;
  top: 90px;
  left: 20px;
}
.table-btn-fullscreen {
  top: 10px !important;
}
.scoreboard-btn {
  z-index: 1;
  position: absolute;
  top: 150px;
  left: 20px;
}
.fade-transition {
  transition: opacity 0.5s ease-in-out; /* Smooth transition for the opacity */
  opacity: 1; /* Fully visible initially */
}

.fade-transition.hidden {
  opacity: 0; /* Fully transparent when hidden */
  pointer-events: none; /* Prevents interaction when invisible */
}
.scoreboard-btn-fullscreen {
  top: 60px !important;
}
.fullscreen-btn {
  z-index: 1;
  position: absolute;
  top: 90px;
  right: 20px;
}
.fullscreen-btn-fullscreen {
  top: 10px !important;
}
.share-competition-btn {
  z-index: 1;
  position: absolute;
  top: 150px;
  right: 20px;
}
.printing-logo {
  position: absolute;
  top: 30px;
  right: 20px;
  opacity: 0;
}
.printing-logo-printing {
  opacity: 1;
}
.competitor-order-btn {
  z-index: 1;
  position: absolute;
  top: 150px;
  right: 20px;
}
.competitor-order-btn-fullscreen {
  top: 60px !important;
}
.competitor-order-btn-mobile {
  top: 10px;
}

.msg-alert {
  margin-right: 80px;
  margin-left: 80px;
}

@media only screen and (max-width: 768px) {
  .msg-alert {
    margin-right: 50px;
    margin-left: 50px;
  }
  .table-btn {
    left: 10px;
  }
  .scoreboard-btn {
    left: 10px;
  }
  .competitor-order-btn {
    right: 10px;
  }
  .fullscreen-btn {
    right: 10px;
  }
}
.unofficial {
  position: relative;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 14px;

}
</style>
