<template>
  <div :class="['bottom-div', {'hide-border': $vssWidth <= 800}]" v-if="orderOfComps.length > 1">
    <div :class="['label-top', {'label-top-dark' : (fullscreen && isDarkMode)}]" v-if="$vssWidth > 768">Order of Competitors</div>
    <div class="scoreboard-order-div">
      <div
        v-for="(item, index) in orderOfComps.slice(1, 5)"
        :key="item.id"
        :class="[
          'scoreboard-order-item',
          { 'scoreboard-order-item-white': fullscreen && isDarkMode },
        ]"
      >
        <div
          class="competitor-team"
          v-bind:style="[
            { 'background-color': item.teamColor },
            { color: item.foregroundColor },
          ]"
        >
          <span class="competitor-name"
            >{{ item.name }} is
            {{ index + 2 == 0 ? "up" : ordinalSuffix(index + 2) }}</span
          >
          <span>{{ item.teamName }}</span>
        </div>
        <v-alert
          dense
          text
          class="attempts-remaining-alert"
          :color="attemptsRemainingColor(item.attempts)"
        >
          <strong>
            {{ item.attempts }}
            {{ item.attempts > 1 ? "attempts" : "attempt" }}
            remaining</strong
          >
        </v-alert>
        <div class="small-body"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayedOrder",
  props: {
    orderOfComps: Array,
    fullscreen: Boolean,
  },
  computed: {
    isDarkMode() {
      return this.$store.state.darkMode;
    },
  },
  methods: {
    ordinalSuffix(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },
    attemptsRemainingColor(attemptsRemaining) {
      if (attemptsRemaining >= 3) {
        return "green";
      } else if (attemptsRemaining == 2) {
        return "orange";
      } else {
        return "red";
      }
    },
  },
};
</script>

<style scoped>


.bottom-div {

  position: absolute;
  bottom: 8px;
  width: -webkit-fill-available;
  margin-left: 8px;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  padding-top: 30px; /* Adjust as needed */
}
.hide-border {
  border: none;
}

.label-top {
  position: absolute;
  top: -15px; /* Half of the height of the label */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: rgba(0, 0, 0, 0.35);
  padding: 5px;
}
.label-top-dark {
    background-color: black;
    color: white;
}
.scoreboard-order-div {
  width: 100%;
}
.scoreboard-order-item {
  max-width: 70%;
  margin-top: 4px !important;
  margin: 0 auto;
  padding-bottom: 6px;
  padding-top: 6px;
}

.scoreboard-order-item-white {
  background: #ffffff;
  border-radius: 4px;
}
.scoreboard-order-item-white .competitor-team {
  margin-top: 8px;
}
.scoreboard-order-item-white .attempts-remaining-alert {
  margin-bottom: 8px;
}

.competitor-team {
  border-radius: 4px;
  padding: 1px 10px 1px 10px;
  margin-bottom: 2px;
}

.competitor-name {
  display: block;
  font-size: 1.5em;
  font-weight: 400;
}

.attempts-remaining-alert {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .scoreboard-order-div {
    height: 60%;
    overflow-y: scroll;
    margin-top: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .scoreboard-order-div {
    overflow-y: scroll;
    margin-top: 80%;
  }
}

@media only screen and (min-width: 768px) {
  .bottom-div {
    width: -webkit-fill-available;
    margin-left: 0px;
    margin-right: 12px;
  }
  .scoreboard-order-div {
    display: inline-flex;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .scoreboard-order-item {
    padding: 0px 10px 0px 10px;
  }
  .scoreboard-order-item-white {
    padding: 0px 10px 0px 10px;
  }
}
</style>
