<template>
  <div style="text-align: left" class="pa-6">
    <h2 class="fixed-header color-gray" style="text-align: center">
      Privacy Policy
    </h2>
    <br />
    <p>
      FieldEvents, ("<strong>Drew Klein</strong>," "<strong>us</strong>",
      "<strong>we</strong>" or "<strong>our</strong>") values your privacy, and
      we are committed to protecting your personal information. FieldEvents was
      built by Drew Klein (creator) as a commercial app. The app and it's
      services provided by us is intended for use as is. This page is used to
      inform visitors regarding policies with the collection, use, and
      disclosure of Personal Information by those who choose to use FieldEvents.
    </p>
    <p>
      If you choose to use the FieldEvents app, then you agree to the collection
      and use of information in relation to this policy. The Personal
      Information collected is used for providing a meaningful experience for
      the app users spectators of FieldEvents. We will not use or share your
      information with anyone except as described in this Privacy Policy.
    </p>
    <p>
      Any terms of use referenced in this Privacy Policy are defined in the
      <a class="link-orange" href="/terms">Terms and Conditions</a>.
    </p>
    <p style="text-align: center" class="pt-4">
      <strong>Information Collection and Use</strong>
    </p>
    <p>
      For a better experience, while using FieldEvents, the app requires it's
      users to provide certain personal identifiable information, including but
      not limited to a competitor's Name, Team, School/Organization, and the
      competitors field event marks. The competitor information that you submit
      will be retained on your device until the app is uninstalled. If you
      choose to post competition's to the web from the app (FieldEvents Live:
      Requires subscription), the competitor's data will be publicly accessible
      on the web. If you decide to enable FieldEvents Live, you must disclose
      this to all competitors. All competition results will be removed from
      FieldEvents once they are 1 year old. However, competition results can be
      removed instantly if requested.
    </p>
    <!---->
    <p style="text-align: center" class="pt-4"><strong>Cookies</strong></p>
    <p>
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the
      websites that you visit and are stored on your device's internal memory.
    </p>
    <p>
      FieldEvents.net does not use these "cookies" explicitly. However, the site
      may use third party code and libraries that use "cookies" to collect
      information and improve their services. You have the option to either
      accept or refuse these cookies and know when a cookie is being sent to
      your device. If you choose to refuse our cookies, you may not be able to
      use some portions of FieldEvents.
    </p>
    <p>
      We value your trust in providing us your personal information, thus we are
      striving to use commercially acceptable means of protecting it. You
      acknowledge that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we cannot guarantee
      its absolute security.
    </p>
    <p style="text-align: center" class="pt-4"><strong>Links to Other Sites</strong></p>
    <p>
      FieldEvents may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by FieldEvents. Therefore, we strongly
      advise you to review the Privacy Policy of these websites. We have no
      control over and assume no responsibility for the content, privacy
      policies, or practices of any third-party sites or services.
    </p>
    <p style="text-align: center" class="pt-4">
      <strong>Changes to This Privacy Policy</strong>
    </p>
    <p>
      The Privacy Policy for FieldEvents may be updated from time to time.
      Therfore, you are advised to review this page periodically for any
      changes. We will notify you of any changes by posting the new Privacy
      Policy on this page.
    </p>
    <p style="text-align: center">This policy is effective as of 2020-12-24</p>
    <p style="text-align: center"><strong>Contact Us</strong></p>
    <p style="text-align: center">
      If you have any questions or suggestions about this Privacy Policy, you
      can contact us by using this
      <a class="link-orange" href="/about">contact form</a>.
    </p>
    <br />
  </div>
</template>

<script>
export default {
  name: "Privacy",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},

  methods: {},
};
</script>

<style>
.container-app {
  max-width: 40rem;
  margin: auto;
  padding-top: 10px;
}
</style>
