<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" width="400px">
      <v-card height="285px" width="100%" class="scroll">
        <v-simple-table fixed-header height="285px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" v-for="item in headers" :key="item.label">
                  {{ item.label }}
                </th>
              </tr>
            </thead>
            <tbody v-if="items.length">
              <tr v-for="item in items" :key="item.id">
                <td v-for="value in item" :key="value">{{ value }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>No events found in file</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import _ from "lodash";

export default {
  name: "SimpleTable",
  props: {
    headers: Array,
    items: Array,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    async open() {
      this.isOpen = true;
    },
    reset() {
      this.isOpen = false;
    },
  },
};
</script>

<style></style>
