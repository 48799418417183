var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"componentId"}},[(!_vm.onExcludedPage && !_vm.hideToolbar)?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center nav-bar-top"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":require("@/assets/logo384.png"),"transition":"scale-transition","width":"300"}}),(_vm.$vssWidth >= 820)?[_c('router-link',{staticClass:"nav-link",class:{ 'color-white-bold': _vm.page == '/' },attrs:{"to":"/"}},[_vm._v("Events")]),_c('router-link',{staticClass:"nav-link",class:{ 'color-white-bold': _vm.page == '/app' },attrs:{"to":"/app"}},[_vm._v("App")]),_c('router-link',{staticClass:"nav-link",class:{ 'color-white-bold': _vm.page == '/about' },attrs:{"to":"/about"}},[_vm._v("About")]),_c('router-link',{staticClass:"nav-link",class:{ 'color-white-bold': _vm.page == '/faq' },attrs:{"to":"/faq"}},[_vm._v("FAQ")]),_c('router-link',{staticClass:"nav-link",class:{ 'color-white-bold': _vm.page == '/account' },attrs:{"to":"/account"}},[_vm._v("Account")]),_c('v-spacer'),(_vm.isSignedIn)?_c('span',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"},attrs:{"to":"/#"},on:{"click":_vm.signOut}},[_vm._v("Sign out")]):_vm._e()]:[_c('v-spacer'),(!_vm.hideToolbar)?_c('v-menu',{staticClass:"menu-nav",attrs:{"bottom":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,false,1209790460)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/"}},[_c('router-link',{class:[{ 'color-gray-bold': _vm.page == '/' }, 'color-gray'],attrs:{"to":"/"}},[_vm._v("Events")])],1),_c('v-list-item',{attrs:{"to":"/app"}},[_c('router-link',{class:[
                    { 'color-gray-bold': _vm.page == '/app' },
                    'color-gray' ],attrs:{"to":"/app"}},[_vm._v("App")])],1),_c('v-list-item',{attrs:{"to":"/about"}},[_c('router-link',{class:[
                    { 'color-gray-bold': _vm.page == '/about' },
                    'color-gray' ],attrs:{"to":"/about"}},[_vm._v("About")])],1),_c('v-list-item',{attrs:{"to":"/faq"}},[_c('router-link',{class:[
                    { 'color-gray-bold': _vm.page == '/faq' },
                    'color-gray' ],attrs:{"to":"/faq"}},[_vm._v("FAQ")])],1),_c('v-list-item',{attrs:{"to":"/account"}},[_c('router-link',{class:[
                    { 'color-gray-bold': _vm.page == '/account' },
                    'color-gray' ],attrs:{"to":"/account"}},[_vm._v("Account")])],1),(_vm.isSignedIn)?_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.signOut}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(" Sign Out ")])],1):_vm._e()],1)],1):_vm._e()]],2),_c('v-spacer')],1):_vm._e(),_c('router-view',{class:['view', {'no-toolbar': _vm.hideToolbar}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }