<template>
  <v-dialog v-model="competitorOrderDialog" scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="[
          'competitor-order-btn',
          'fade-transition', { 'hidden' : !isUserActive },
          { 'competitor-order-btn-fullscreen': fullscreen },
          { 'competitor-order-btn-mobile' : hideToolbar }
        ]"
        color="primary"
        small
        fab
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-clipboard-list</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Competitor Order</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px">
        <div>
          <v-list-item
            v-for="(item, index) in orderOfComps"
            :key="item.id"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title
                >{{ item.name }} is
                {{
                  index == 0 ? "up" : ordinalSuffix(index + 1)
                }}</v-list-item-title
              >
              <v-list-item-subtitle
                >{{ item.attempts }}
                {{ item.attempts > 1 ? "attempts" : "attempt" }}
                remaining</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="competitorOrderDialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CompetitorOrder",
  props: {
    orderOfComps: Array,
    fullscreen: Boolean,
    isUserActive: Boolean,
  },
  data() {
    return {
      competitorOrderDialog: false,
    };
  },
  computed: {
    hideToolbar() {
      return window.location.href.includes("hideToolbar=1");
    },
  },
  updated() {},
  methods: {
    ordinalSuffix(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },
  },
};
</script>

<style></style>
