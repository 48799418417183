<template>
  <v-card color="#515457" class="mx-auto gray-card" max-width="344">
    <v-card-text>
      <p class="info-title color-white">
        {{ item.title }}
      </p>
      <div class="color-white" v-html="item.body"></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InfoCard",
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.gray-card {
  color: #515457;
  min-height: 210px !important;
}
.info-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 34px !important;
}

.gray-card.v-card.v-sheet.theme--light {
  border-radius: 26px;
}
.color-white {
  color: white;
}
</style>
