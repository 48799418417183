<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" persistent width="400px">
     <v-form ref="meetForm">
        <v-card height="350px">
          <v-card-title>
            <v-spacer />
            <span class="headline">
              {{ updating ? "Edit Meet" : "Create Meet" }}</span
            >
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Meet Name"
                    v-model="name"
                    :rules="[rules.required, rules.counter30]"
                    filled
                    counter
                    maxlength="30"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Meet Division"
                    v-model="division"
                    :rules="[rules.required, rules.counter30]"
                    filled
                    counter
                    maxlength="30"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="action-buttons">
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="reset"> Cancel </v-btn>
            <v-btn :disabled="!canAddMeet" color="primary" @click="createMeet">
              {{ updating ? "Update" : "Create" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { buildRequestHeaders } from "@/utils";

export default {
  name: "CreateEditMeet",
  props: {
    userObject: Object,
    meet: Object,
  },
  data() {
    return {
      isOpen: false,
      updating: false,
      meetId: uuidv4().toUpperCase(),
      name: "",
      division: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter30: (value) => value.length <= 30 || "Max 30 characters",
        counter20: (value) => value.length <= 30 || "Max 20 characters",
      },
    };
  },
  computed: {
    canAddMeet() {
      return this.name != "" && this.division != "";
    },
  },
  updated() {},
  methods: {
    async open() {
      if (typeof this.meet.id !== "undefined") {
        let meetTemp = _.cloneDeep(this.meet);
        this.meetId = meetTemp.id;
        this.name = meetTemp.name;
        this.division = meetTemp.division;
        this.updating = true;
      } else {
        this.updating = false;
      }

      this.isOpen = true;
    },
    reset() {
      this.name = "";
      this.division = "";
      this.$refs.meetForm.resetValidation()
      this.isOpen = false;
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    createMeet() {
      if (this.canAddMeet && this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        this.name = this.name.replace(/'/g, "’");

        let meet = {
          id: this.meetId,
          name: this.name,
          division: this.division,
        };

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/meets/" +
          this.userObject.username;
        this.axios.post(apiUrl, meet, config).then((response) => {
          this.isOpen = false;
          this.$emit("updateMeets");
        });
      }
    },
  },
};
</script>

<style>
.create-card {
  position: absolute;
}
.scroll {
  overflow-y: scroll;
}
.action-buttons {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
</style>
