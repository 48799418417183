<template>
  <div class="full-w height-and-timer">
    <v-row>
      <v-col :cols="showingTimer && timerInitialized ? 9 : 12">
          <custom-header
            v-if="eventType == 'vertical'"
            :title="height"
            label="Height"
            :bindedStyle="heightStyle"
            :fullscreen="fullscreen"
            :isDarkMode="isDarkMode">
          </custom-header>
      </v-col>
      <v-col cols="3" v-if="showingTimer && timerInitialized">

          <custom-header
            :title="secondsToMinutesAndSeconds(timerText)"
            label="Timer"
            :bindedStyle="timerStyle"
            :fullscreen="fullscreen"
            :isDarkMode="isDarkMode">
          </custom-header>
    
      </v-col>
    </v-row>
    

    <div :class="['main-competitor-container', {'mt-14': !showingTimer}]">
      <v-row justify="space-around" class="ml-2 mr-2">
        <v-col class="main-competitor-div" xl="8" lg="8" sm="8">
          <h1
            v-bind:style="headerText"
            :class="{ 'align-center': competitorName == 'Competition Ended' }"
          >
            {{ competitorName }}
          </h1>
          <h2
            v-if="competitorName != 'Competition Ended'"
            class="competitor-displayed-team"
            v-bind:style="subtextTeam"
          >
            {{ competitorTeam }}
          </h2>
        </v-col>

        <v-col
          v-if="competitorName != 'Competition Ended'"
          class="main-competitor-div"
          xl="4"
          lg="4"
          sm="4"
        >
          <h1
            v-if="isVerticalEvent"
            v-bind:style="markText(true)"
            class="mark-font"
          >
            {{ competitorScore }}
          </h1>
          <h1 v-if="!isVerticalEvent" v-bind:style="markText(true)">
            {{ primaryMark(competitorScore) }}
          </h1>
          <h2
            v-if="!isVerticalEvent && shouldSplit(competitorScore)"
            v-bind:style="subtext"
          >
            {{ secondaryMark(competitorScore) }}
          </h2>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CustomHeader from "./CustomHeader.vue";
export default {
  components: { CustomHeader },
  name: "DisplayedCompetitor",
  props: {
    eventType: String,
    height: String,
    preferredUnits: String,
    competitorName: String,
    competitorTeam: String,
    competitorScore: String,
    fullscreen: Boolean,
    competitorBackgroundColor: String,
    competitorForegroundColor: String,
    timerObject: Object,
  },
  data() {
    return {
      tick: 0,
      timer: null,
    }
  },
  watch: {
    timerObject: function (newVal, oldVal) {
      if (newVal != null) {
        clearInterval(this.timer);

        this.timer = setInterval(() => {
          this.tick = Date.now();
        }, 1000);
      }
    }
  },
  beforeDestroy() {
    // Clear the timer when the component is destroyed
    clearInterval(this.timer);
  },
  computed: {
    showingTimer() {
      // show for larger screensizes
      return this.$vssWidth > 800;
    },
    timerInitialized() {
      return this.timerObject != null;
    },
    heightStyle() {
      let size = this.$vssWidth / 24 > 44 ? 50 : this.$vssWidth / 24; // Font size increased to 60px max
      let color = this.isDarkMode && this.fullscreen ? "white" : "rgba(0, 0, 0, 0.38)";

      return {
        "font-size": size + "px",
        "line-height": "68px", // Lock line height to 68px
        "height": "68px", // Lock height to 68px
        "overflow": "hidden", // Hide overflow
        color: color
      };
    },
    isVerticalEvent() {
      return this.eventType == "vertical";
    },
    isDarkMode() {
      return this.$store.state.darkMode;
    },
    subtext() {
      let size = this.$vssWidth / 24 > 38 ? 38 : this.$vssWidth / 24;
      return {
        "font-size": size + "px",
      };
    },
    headerText() {
      let size = this.$vssWidth / 16 > 70 ? 70 : this.$vssWidth / 16;

      return {
        color: this.isDarkMode && this.fullscreen ? "white" : "black",
        "font-size": size + "px",
      };
    },
    subtextTeam() {
      let size = this.$vssWidth / 24 > 50 ? 50 : this.$vssWidth / 24;

      return [
        { "font-size": size + "px" },
        { background: this.competitorBackgroundColor },
        { color: this.competitorForegroundColor },
        { "border-radius": "8px" },
      ];
    },
    timerStyle() {
      let size = this.$vssWidth / 24 > 44 ? 60 : this.$vssWidth / 24; // Font size increased to 60px max
      let color = '#2c3e50';

      if (this.timerText <= 15) {
        color = '#e74c3c';
      }

      return {
        "font-size": size + "px",
        "line-height": "68px", // Lock line height to 68px
        "height": "68px", // Lock height to 68px
        "overflow": "hidden", // Hide overflow
        color: color
      };
    },
    timerText() {
      // timerObject.status (string) (started, stopped, reset)
      // timerObject.time (epoch time)
      // timerObject.timerDuration (integer)

      // intentional for vue rendering
      console.log(this.tick)
      let timeRemaining = 60;

      if (this.timerObject != null) {
        if (this.timerObject.status == "reset" || this.timerObject.status == "stopped") {
          return this.timerObject.timerDuration
        } else if (this.timerObject.status == "started") {
          // we cant rely on the timerDuration because it is not updated in real time so we need to calculate it based on the difference between the timerObject.time and the current time
          let currentTime = new Date().getTime()
          let timeElapsed = currentTime - this.timerObject.time

          // convert timeElapsed to seconds
          let timeElapsedInSeconds = Math.round(timeElapsed / 1000)

          timeRemaining = this.timerObject.timerDuration - timeElapsedInSeconds
          
          if (timeRemaining <= 0) {
            timeRemaining = 0
          }
        }
      }

      return timeRemaining
    }
  },
  methods: {
    secondsToMinutesAndSeconds(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;
      if (seconds <= 60) {
        return `${seconds}`;
      } else {
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      }
    },
    shouldSplit(result) {
      if (result == "P" || result == "F" || result == "R" || !result) {
        return false;
      } else {
        return true;
      }
    },
    primaryMark(mark) {
      if (this.shouldSplit(mark)) {
        if (this.preferredUnits == 0) {
          return this.splitMark(mark, 0);
        } else {
          return this.splitMark(mark, 1);
        }
      } else {
        return mark;
      }
    },
    secondaryMark(mark) {
      if (this.shouldSplit(mark)) {
        if (this.preferredUnits == 0) {
          return this.splitMark(mark, 1);
        } else {
          return this.splitMark(mark, 0);
        }
      } else {
        return mark;
      }
    },
    markText(isPrimary) {
      var width = this.$vssWidth;
      var scale = 8;

      if (this.eventType == "horizontal") {
        scale = 16;
      }

      if (!isPrimary) {
        scale + 3;
      }

      let markColor = "#00000";
      let value = this.competitorScore;

      switch (value) {
        case "O":
        case "PPO":
        case "XO":
        case "XXO":
        case "PXO":
        case "PO":
          markColor = "#1dad38";
          break;
        case "PPX":
        case "PX":
        case "XXX":
        case "XX":
        case "X":
        case "F":
          markColor = "#ad1d1d";
          break;
        case "XXP":
        case "XPP":
        case "PPP":
        case "P":
          markColor = "#1d63ad";
          break;
        default:
          markColor = "#1dad38";
          break;
      }

      return {
        color: markColor,
        "font-size": width / scale + "px",
      };
    },
  },
};
</script>

<style scoped>
.full-w {
  width: 100%;
}
.height-and-timer {
  display: flex
}
.main-competitor-container {
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.lighter-powered-by {
    color: #979797
}

.main-competitor-div {
  white-space: nowrap;
  padding: 60px 0;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  text-align: left;
}
.competitor-displayed-team {
  width: min-content;
  padding: 10px 20px 10px 20px;
}
.mark-font {
  font-family: Verdana, Geneva, sans-serif;
  letter-spacing: -0.2px;
  word-spacing: 1.2px;
  color: #000000;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .main-competitor-container {
    position: absolute;
    top: 35%;
    -ms-transform: translateY(-35%);
    transform: translateY(-35%);
  }
}

@media only screen and (max-width: 600px) {
  .main-competitor-container {
    position: absolute;
    top: 25%;
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
  }
}
</style>
