<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" persistent width="400px">
      <v-card height="350px">
        <v-card-title>
          <v-spacer />
          <span class="headline">
            Link Competition to Meet</span
          >
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
               <v-autocomplete
                      v-model="meet"
                      :items="meets"
                      :rules="[rules.required]"
                      filled
                      dense
                      label="Meet"
                      item-text="name"
                      item-value="id"
                      class="form-dropdown"
                    ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="action-buttons">

        <v-btn text color="error" @click="reset"> Cancel </v-btn>
 
          <v-btn :disabled="!meet" color="primary" @click="linkMeet">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { buildRequestHeaders } from "@/utils";

export default {
  name: "AssociateMeet",
  props: {
    userObject: Object,
    competitionId: String,
  },
  data() {
    return {
      isOpen: false,
      meet: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter30: (value) => value.length <= 30 || "Max 30 characters",
        counter20: (value) => value.length <= 30 || "Max 20 characters",
      },
      meets: [],
    };
  },
  updated() {},
  methods: {
    async open() {
      this.isOpen = true;
      await this.fetchData()
    },
    reset() {
      this.meet = null;
      this.isOpen = false;
    },
    async fetchData() {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/meets/" +
          this.userObject.username;
        this.axios.get(apiUrl, config).then((response) => {
          this.meets = response.data;
        });
      }
    },
    linkMeet() {
      if (this.userObject.username != undefined) {
        let config = buildRequestHeaders(this.userObject.username);

        let payload = {
          competitionId: this.competitionId,
          meetId: this.meet
        };
        console.log(payload)

        let apiUrl =
          process.env.VUE_APP_BACKEND_URL +
          "/utility/associateMeet"
        this.axios.post(apiUrl, payload, config).then((response) => {
          this.isOpen = false;
          this.$emit("associatedMeet");
        });
      }
    },
  },
};
</script>

<style>
.create-card {
  position: absolute;
}
.scroll {
  overflow-y: scroll;
}
.action-buttons {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
</style>
