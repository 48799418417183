import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});

Vue.filter("formatDateNew", function (value) {
  if (value) {
    let dateParts = String(value).split(" ");
    if (dateParts.length) {
      let parts = dateParts[0].split("-");
      if (parts.length >= 3) {
        return parts[1] + "/" + parts[2] + "/" + parts[0]
      }
    }

  }
});



Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
  }
});


Vue.filter("formatTimeLocal", function (date) {
  const dateMoment = moment.utc(date);
  if (!date || !dateMoment.isValid()) {
    return '-';
  }
  return dateMoment.local().format(`h:mm:ss A`);
});

Vue.filter("formatDateTimeOnly", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm:ss");
  }
});

Vue.filter("titleCase", function (value) {
  if (value) {
    return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
  }
});

Vue.filter("ordinalSuffix", function (i) {
  if (i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
});
