<template>
    <div class="header-container">
      <h1 v-bind:style="bindedStyle">{{ title }}</h1>
      <span :class="['label', {'label-dark': isFullscreen && isDarkMode}]">{{ label }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CustomHeader',
    props: {
      title: String,
      label: String,
      bindedStyle: Object,
      isFullscreen: Boolean,
      isDarkMode: Boolean,
    }
  }
  </script>
  
  <style scoped>
.header-container {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;
  padding: 20px 10px 30px; /* Adjusted bottom padding */
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  text-align: center;
}

.header-container h1 {
  margin: 0;
}

.label {
  position: absolute;
  bottom: -15px; /* Adjusted for larger label */
  left: 50%;
  color: rgba(0, 0, 0, 0.35);
  transform: translateX(-50%);
  background-color: white;
  padding: 5px;
}
.label-dark {
    background-color: black;
    color: white;
}
  </style>
  