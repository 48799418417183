import MD5 from "crypto-js/md5";

export function buildRequestHeaders(username) {
  let stringToEnc = username + "FiEldEvEnTs2020!"
  let encryptedString = MD5(stringToEnc).toString();

  let config = {
    headers: {
      authorization: "Bearer " + encryptedString,
      username: username
    }
  }

  return config
}
