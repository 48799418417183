/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:74147924-100e-4340-9edd-53df4bfca668",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_CXtGFpQlX",
  aws_user_pools_web_client_id: "7l9v9htrks7rddh8r7i4fb1dtp",
  oauth: {},
};

export default awsmobile;
