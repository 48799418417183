<template>
  <div class="main-content">
    <!-- <div v-if="isSignedIn" class="welcome-user text-center">
      <v-btn text color="secondary" dark @click="signOut">
        Sign out
      </v-btn>
    </div> -->

    <v-container v-if="!isSignedIn">
      <amplify-authenticator
      class="amplify-authenticator"
        :authConfig="{ signInConfig: { isSignUpDisplayed: false } }"
      >
      </amplify-authenticator>

      <span
        ><br /><b>Don't have an account?</b><br />You can create one from the
        FieldEvents App if you have an active subscription!</span
      >
    </v-container>

    <div class="mobile-message">
      <h2>This page is not supported on mobile devices</h2>
    </div>

    <v-tabs v-if="isSignedIn" v-model="selectedTab" class="account-tabs" active-class="active-account-tab">
      <v-tab key="Meets" @click="changeDrawerItem('Meets')">Meets</v-tab>
      <v-tab key="Competitions" @click="changeDrawerItem('Competitions')">Competitions</v-tab>
      <v-tab key="Teams" @click="changeDrawerItem('Teams')">Teams</v-tab>
    </v-tabs>

    <div v-if="isSignedIn" :class="['mt-6', { 'mt-16': $vssWidth <= 650 }]" @dragover="startDragAndDrop">
      <Meets :userObject="userObject" v-if="selectedDrawerItem == 'Meets'" />
      <Competitions
        :userObject="userObject"
        v-if="selectedDrawerItem == 'Competitions'"
        ref="competitionsTab"
      />
      <Teams :userObject="userObject" v-if="selectedDrawerItem == 'Teams'" />
    </div>
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";
import Meets from "./Account/Meets.vue";
import Competitions from "../components/Account/Competitions.vue";
import Teams from "./Account/Teams.vue";
import _ from "lodash";

export default {
  name: "Account",
  components: {
    Meets,
    Competitions,
    Teams,
  },
  props: {},
  async beforeCreate() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.userObject = user;
      this.$store.commit("setIsSignedIn", true);
    } catch (err) {
      this.$store.commit("setIsSignedIn", false);
    }
    AmplifyEventBus.$on("authState", (info) => {
      if (info === "signedIn") {
        this.$store.commit("setIsSignedIn", true);
      } else {
        this.$store.commit("setIsSignedIn", false);
        this.userObject = {};
      }
    });
    this.intervalId = setInterval(this.reloadUserData, 2000);
  },
  data() {
    return {
      userObject: {},
      intervalId: "",
      selectedTab: "",
    };
  },
  computed: {
    isSignedIn() {
      return this.$store.state.isSignedIn;
    },
    selectedDrawerItem() {
      return this.$store.state.selectedDrawerItem;
    },
    usersName() {
      if (
        !_.isNil(this.userObject) &&
        !_.isNil(this.userObject.attributes) &&
        !_.isNil(this.userObject.attributes.name)
      ) {
        return this.userObject.attributes.name;
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.$store.state.selectedDrawerItem == "Meets") {
      this.selectedTab = 0;
    } else if (this.$store.state.selectedDrawerItem == "Competitions") {
      this.selectedTab = 1;
    } else if (this.$store.state.selectedDrawerItem == "Teams") {
      this.selectedTab = 2;
    }
  },
  methods: {
    async reloadUserData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.userObject = user;
        this.$store.commit("setIsSignedIn", true);
      } catch (err) {
        this.$store.commit("setIsSignedIn", false);
      }
      AmplifyEventBus.$on("authState", (info) => {
        if (info === "signedIn") {
          this.$store.commit("setIsSignedIn", true);
        } else {
          this.$store.commit("setIsSignedIn", false);
          this.userObject = {};
        }
      });
    },
    changeDrawerItem(value) {
      this.$store.commit("setSelectedDrawerItem", value);
    },
    async signOut() {
      await Auth.signOut();
      this.$store.commit("setIsSignedIn", false);
      await this.reloadUserData();
    },
    startDragAndDrop() {
      this.changeDrawerItem("Competitions");
      this.selectedTab = 1;
      this.$nextTick(() => {
        this.$refs.competitionsTab.openUploadDialog();
      });
    }
  },
};
</script>

<style>
/* body {
  margin: 0;
} */
/* .nav-drawer-view {
  width: 15% !important;
} */
.account-tabs .v-tab {
  border-radius: 8px 8px 0 0;
}
.account-tabs .v-tab::before {
  border-radius: 8px 8px 0 0;
}
.active-account-tab {
  background-color: rgba(176, 175, 175, 0.4)
}
@media only screen and (max-width: 950px) {
  .nav-drawer-view {
    width: 60px !important;
  }
}
.page-title {
  align-content: flex-start;
  text-align: left;
  margin-left: 20px;
}

@media only screen and (max-width: 800px) {
  .page-title {
    font-size: 14px;
  }
}
.sign-in {
  margin-top: 68px;
}
.mobile-message {
  margin-top: 100px;
  display: none;
}
.full-content {
  height: 100%;
  margin-top: 8px;
  display: flex;
}
.main-content {
  width: 100%;
  padding: 8px 8px 8px 8px;
}
.welcome-user {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 100;
}
.show-all {
  position: absolute;
  width: 109px;
  right: 246px;
  top: 73px;
}
.import {
  position: absolute;
  width: min-content;
  right: 130px;
  top: 90px;
}
.create {
  position: absolute;
  width: min-content;
  right: 20px;
  top: 90px;
}
@media only screen and (max-width: 650px) {
  .create {
    top: 145px;
    left: 20px;
    text-align: left !important;
  }
  .import {
    top: 145px;
    left: 120px;
  }
  .show-all {
    position: absolute;
    width: 109px;
    right: 20px;
    top: 126px;
  }
}
.amplify-authenticator {
  padding-top: 60px;
}
.amplify-authenticator input:focus { 
    outline: red
}

.amplify-authenticator .Section__sectionFooter___1QlDn {
  display: contents !important;
}
 .amplify-authenticator .Section__sectionFooterPrimaryContent___36Gb1 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
}
.amplify-authenticator .Button__button___1FrBC {
  border-radius: 16px !important;
  font-weight: 800 !important;
  background-color: #eb740e !important;
}


/* 
@media only screen and (max-width: 600px) {
  .sign-in {
    display: none;
  }
  .mobile-message {
    display: block;
  }
}



tr {
  text-align: left;
}
.signout {
  background-color: #ededed;
  margin: 0;
  padding: 11px 0px 1px;
}

.nav-link {
  color: white !important;
  text-decoration: none;
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 8px;
}
.inActive {
  color: rgba(255, 255, 255, 0.5) !important;
}

#app > div > div.sign-in > div > div > div.error {
  background-color: white !important;
  color: red;
} */
</style>
