<template>
    <fullscreen :fullscreen.sync="fullscreen" background="#000" class="all-boards-container">
        <settings
            v-if="!fullscreen"
            :settings="scoreboardSettings"
            @updateScoreboards="updateScoreboards"
            @changeSmall="changeSmall"
            @changeMid="changeMid"
            @changeLarge="changeLarge"
            @changeShowLogos="changeShowLogos"
            @changeLogoSize="changeLogoSize"
        />
        <v-btn
            v-if="$vssWidth >= 720"
            class="doane-fullscreen-btn"
            outlined 
            fab 
            color="#fefefe" 
            small 
            @click="toggleFullscreen"
        >
            <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
            <v-icon v-else>mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-container fluid class="full-h" v-if="renderedBoard">
            <v-row :class="[{'full-h': secondTwo.length == 0}]" align="center"  justify="center">
            <v-col v-for="(item, index) in firstTwo" :key="item.port" :class="['col-padding', 'full-h', {'border-r' : ((index % 2 ==0) && firstTwo.length > 1) }]" justify="space-between"> 
                <div :class="['placeAndEventContainer', {'no-margin': fullscreen}]" justify="space-between">
                <div class="placeContainer">
                    <div class="placeLabel" v-bind:style="smallFont"><b>Place:</b></div>
                    <div class="place" v-bind:style="smallFont" id="placeRight"><b>{{item.data.place}}</b></div>
                </div>
                <div v-bind:style="smallFont" :class="['eventName',  {'mr-6' : ((index % 2 ==0) && shownScoreboards.length > 1) }]" id="eventNameRight"><b>{{item.data.eventName}}</b></div>
                </div>
                <v-spacer/>
                <div class="athleteAndMarkContainer">
                <div class="athleteAndMarkInner">
                    <div :class="['nameAndSchool', {'nameAndSchoolFull' : !isVerticalBoard(item)}]">
                    <div class="athlete" v-bind:style="midFont" id="athleteRight"><b>{{item.data.athlete}}</b></div>
                    <div class="school" v-bind:style="smallFont" id="schoolRight"><b>{{item.data.school}}</b></div>
                    </div>
                    <div v-if="isVerticalBoard(item)" class="mark" v-bind:style="lgFont" id="markRight">{{item.data.mark}}</div>
                </div>
                </div>
                <div class="bottom-container" >
                  <div class="logo-container" v-bind:style="logoWidth">
                    <img
                      v-if="showLogos"
                      class="logo-img"
                      :src="getSchoolLogo(item.data.school.toLowerCase())"
                      alt="Competitor Logo"
                    />
                  </div>
                  <div class="heightContainer"  v-bind:style="heightPadding" justify="center">
                    <div class="heightTest">
                        <div class="imperial" v-bind:style="midFont" id="imperialRight"><b>{{item.data.imperial}}</b></div>
                        <div class="metric" v-bind:style="midFont" id="metricRight"><b>{{item.data.metric}} {{isNotEmpty(item.data.metric) ? ' M' : ''}}</b> </div>
                    </div>
                  </div>
                </div>
            </v-col>
            </v-row>
            <v-row v-if="secondTwo.length > 0" :class="[{'full-h': secondTwo.length == 0}, {'border-t': secondTwo.length > 0}]" align="center" justify="center" >
            <v-col v-for="(item, index) in secondTwo" :key="item.port" :class="['col-padding', 'full-h', {'border-r' : ((index % 2 ==0) && secondTwo.length > 1) }]" justify="space-between"> 
                <div class="placeAndEventContainer" justify="space-between">
                <div class="placeContainer">
                    <div class="placeLabel" v-bind:style="smallFont"><b>Place:</b></div>
                    <div class="place" v-bind:style="smallFont" id="placeRight">{{item.data.place}}</div>
                </div>
                <div v-bind:style="smallFont" :class="['eventName',  {'mr-6' : ((index % 2 ==0) && shownScoreboards.length > 1) }]" id="eventNameRight">{{item.data.eventName}}</div>
                </div>
                <v-spacer/>
                <div class="athleteAndMarkContainer">
                <div class="athleteAndMarkInner">
                    <div :class="['nameAndSchool', {'nameAndSchoolFull' : !isVerticalBoard(item)}]" >
                    <div class="athlete" v-bind:style="midFont" id="athleteRight"><b>{{item.data.athlete}}</b></div>
                    <div v-bind:style="smallFont" class="school" id="schoolRight"><b>{{item.data.school}}</b></div>
                    </div>
                    <div v-if="isVerticalBoard(item)" class="mark" v-bind:style="lgFont" id="markRight">{{item.data.mark}}</div>
                </div>
                </div>
                <div class="heightContainer">
                  <div class="heightTest">
                      <div class="imperial" v-bind:style="midFont" id="imperialRight">{{item.data.imperial}}</div>
                      <div class="metric" v-bind:style="midFont" id="metricRight">{{item.data.metric}} {{isNotEmpty(item.data.metric) ? ' M' : ''}} </div>
                  </div>
                </div>
            </v-col>
            </v-row>
        </v-container>
    </fullscreen>
</template>

<script>
import Settings from "./partials/ScoreboardSettings/Settings.vue";
import _ from "lodash";
export default {
  name: "Doane",
  components: { Settings },
  props: {
      port: {
        required: false,
        type: String
     }
  },
  data() {
    return {
      scoreboards: [],
      intervalId: null,
      scoreboardSettings: [],
      fullscreen: false,
      renderedBoard: true,
      smallF: 80,
      midF: 105,
      largeF: 120,
      showLogos: false,
      logoSize: 70
    };
  },
  computed: {
    shownScoreboards() {
      let shownBoards = [];
      let shown = _.orderBy(
        this.scoreboardSettings.filter((sc) => sc.shown == true),
        ["order"],
        ["asc"]
      );
      let shownPorts = shown.map((s) => s.port);

      for (let port of shownPorts) {
        for (let board of this.scoreboards) {
          if (port == board.port) {
            shownBoards.push(board)
          }
        }

      }
      return shownBoards;
    },
    firstTwo() {
      if (this.shownScoreboards.length <= 2) {
        return this.shownScoreboards
      } else {
        return this.shownScoreboards.slice(0, 2)
      }
    },
    secondTwo() {
      if (this.shownScoreboards.length <= 2) {
        return []
      } else {
        if (this.shownScoreboards.length <=3) {
          return this.shownScoreboards.slice(2)
        } else {
          return this.shownScoreboards.slice(2, 4)
        }
      }
    },
    smallFont: function () {

      let size = this.$vssWidth / 18 > 50 ? 50 : this.$vssWidth / 18;

      if (this.shownScoreboards.length > 1) {
        size = size / 1.33
      }

      return {
        "font-size": this.smallF + "px",
      };
    },
    midFont: function () {

      let size = this.$vssWidth / 16 > 70 ? 70 : this.$vssWidth / 16;

      if (this.shownScoreboards.length > 1) {
        size = size / 1.33
      }

      return {
        "font-size": this.midF + "px",
      };
    },
    lgFont: function () {
      // let size = this.$vssWidth / 15 > 100 ? 100 : this.$vssWidth / 15;

      // if (this.shownScoreboards.length == 1 && this.$vssWidth > 1024) {
      //     size = 130;
      // }

      // if (this.shownScoreboards.length > 1) {
      //   size = size / 1.33
      // }

      return {
        "font-size": this.largeF + "px",
      };
    },
    logoWidth() {
      return {
        "height": this.logoSize + "%"
      }
    },
    heightPadding() {
      return {
        "padding-right": "0px"
      }
    }
  },
  mounted() {
    this.fetchScoreboardData();
    this.intervalId = setInterval(this.fetchScoreboardData, 3500);
  },
  methods: {
    rerenderBoard() {
      this.renderedBoard = false
      this.$nextTick(() => {
        this.renderedBoard = true
      });
    },
    changeSmall(val) {
      this.smallF = val
      this.rerenderBoard()
    },
    changeMid(val) {
      this.midF = val
      this.rerenderBoard()
    },
    changeLarge(val) {
      this.largeF = val
      this.rerenderBoard()
    },
    changeShowLogos(val) {
      this.showLogos = val
      this.rerenderBoard()
    },
    changeLogoSize(val) {
      this.logoSize = val
      this.rerenderBoard()
    },
    fetchScoreboardData() {
      let apiUrl = process.env.VUE_APP_BACKEND_URL + "/scoreboards";

      this.axios
        .get(apiUrl)
        .then((response) => {
          this.scoreboards = response.data;

          if (this.scoreboardSettings.length == 0) {
            let count = 1;
            for (let sc of this.scoreboards) {
              let scSetting = _.cloneDeep(sc);
              scSetting.shown = false;
              scSetting.type = "Vertical";
              scSetting.order = count;
              count += 1;
              this.scoreboardSettings.push(scSetting);
              if (!_.isNil(this.port)) {
                if (scSetting.port == this.port) {
                  scSetting.shown = true
                }
              }
  
            }
          }
        });
    },
    getSchoolLogo(school) {
      let path = '';

      if (school.includes('briar')) {
        path = require('../assets/doane-logos/briarcliff.png')
      } 
      
      else if (school.includes('james')) {
        path = require('../assets/doane-logos/jamestown.png')
      }

      else if (school.includes('concordia')) {
        path = require('../assets/doane-logos/concordia.png')
      }

      else if (school.includes('dakota wesleyan')) {
        path = require('../assets/doane-logos/dakota-wesleyan.png')
      }
      
      else if (school.includes('doane')) {
        path = require('../assets/doane-logos/doane.png')
      }

      else if (school.includes('dordt')) {
        path = require('../assets/doane-logos/dordt.png')
      }

      else if (school.includes('hastings')) {
        path = require('../assets/doane-logos/hastings.png')
      }

      else if (school.includes('midland')) {
        path = require('../assets/doane-logos/midland.png')
      }

      else if (school.includes('morningside')) {
        path = require('../assets/doane-logos/morningside.png')
      }

      else if (school.includes('marty')) {
        path = require('../assets/doane-logos/mountmarty.png')
      }

      else if (school.includes('northwestern')) {
        path = require('../assets/doane-logos/northwestern.png')
      }

      else if (school.includes('mary')) {
        path = require('../assets/doane-logos/saintmary.png')
      }

      return path
    },
    toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
    },
    updateScoreboards(settings) {
      console.log("settings updated");
      console.log(settings);
    },
    isVerticalBoard(board) {
      let thisBoard = this.scoreboardSettings.filter((sc) => sc.type == 'Vertical' && sc.port == board.port)

      return thisBoard.length > 0;
    },
    isNotEmpty(mark) {
        return mark.trim().length !== 0;
    }
  },
};
</script>

<style>

.all-boards-container {
  background-color: #000 !important;
  color: #000;
  margin-top: 0px;
  height: 100%;
  padding: 8px;
}

.board-container {
  width: 224px !important;
  height: 112px !important;
}
.full-h {
  height: 100%;
}
.settings-btn {
  float: right;
}
.col-padding {
  padding: 4px 4px 0 4px !important;
}
.no-margin {
  margin: 0 !important;
}
.placeAndEventContainer {
  height: 33%;
  margin-top: 30px;
  color: white;
}
.placeContainer {
  display: inline-flex;
  float: left;
}

.placeLabel {
  padding-right: 30px;
  color: white;
}
.place {
  color: #f27820;
}

.eventName {
  float: right;
  color: #f27820;
}

.athleteAndMarkContainer {
  width: 100%;
  height: 33%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.athleteAndMarkInner {
  display: inline-flex;
  justify-content: space-between;
  color: white;
}
.nameAndSchool {
  display: block;
  width: 55%;
  color: white;
  text-align: left;
}
.nameAndSchoolFull {
  width: 80% !important;
}
.athlete {
  color: white;
  white-space: nowrap;
}
.school {
  color: #adadad;
  white-space: nowrap;
}
.mark {
  width: 40%;
  margin-right: 10px;
  color: white;
  letter-spacing: 4px;
  font-weight: 600;
}
.heightLabel {
    display: none;
    padding-right: 30px;
    color: #adadad
}
.bottom-container {
  display: flex;
  height: 33%;
  align-items: flex-end;
}
.logo-container {
  display: inline-flex; 
  background: rgba(255,255,255,0.5);
  border-radius: 8px;
}
.logo-img {
  width: 100%;
}
.heightContainer {
  width: 100%;
  display: inline-flex; 
  flex-direction: column;
  justify-content: flex-end;
}
.heightTest {
    display: inline-flex;
    justify-content: flex-end;
}
.imperial {
  padding-right: 6%;
  color: #f27820;
}
.metric {
  color: #f27820;
  white-space: nowrap;
}
.border-r {
  border-right: 5px solid white !important;
  margin-right: 16px;
}

.border-t {
  border-top: 5px solid white !important;
}

.small-font {
  font-size: 36px;
}
.mid-font {
  font-size: 50px;
}
.lg-font {
  font-size: 80px;
  letter-spacing: 24px;
}

.doane-fullscreen-btn {
    position: absolute;
    bottom: 10px;
    left: 8px;
}


@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 768px) {
}
</style>
