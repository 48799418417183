<template>
    <v-row justify="center">
      <v-dialog v-model="isOpen" persistent width="400px">
          <v-card height="350px">
            <v-card-title>
              <v-spacer />
              <span class="headline">
                Invite Officials
                </span>
              <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-container>
                    <p>As a FieldEvents Pro subscriber, you can invite up to 8 people to help officiate your meet. To officiate an event, instruct users to visit the "Manage Competition" page and enter the code below in the section "Join a meet".</p>
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <v-text-field
                                class="invite-code"
                                label="Invite Code"
                                v-model="inviteCode"
                                filled
                                readonly
                                style="max-width: 150px; letter-spacing: 20px;"
                            >
                                <template v-slot:append>
                                    <v-icon color="primary" @click="copyToClipboard">mdi-content-copy</v-icon>
                                </template>
                            </v-text-field>
                            </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="action-buttons">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="reset"> Dismiss </v-btn>
            </v-card-actions>
          </v-card>

      </v-dialog>
      <v-snackbar v-model="snackbar" :multi-line="true">
        <div v-html="snackbarText"></div>
        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </v-row>
  </template>
  <script>

  import _ from "lodash";
  import { buildRequestHeaders } from "@/utils";
  
  export default {
    name: "CreateEditMeet",
    props: {
      userObject: Object,
    },
    data() {
      return {
        isOpen: false,
        inviteCode: "......",
        meetId: null,
        snackbar: false,
        snackbarText: "",
      };
    },

    updated() {},
    methods: {
      async open(meetId) {
        this.meetId = meetId;
        // todo fetch invite code
        await this.getInviteCode();
        this.isOpen = true;
      },
      reset() {
        this.inviteCode = "......";
        this.meetId = null;
        this.isOpen = false;
      },
      getInviteCode() {
        if (this.meetId && this.userObject.username != undefined) {
            let config = buildRequestHeaders(this.userObject.username);
            let apiUrl = process.env.VUE_APP_BACKEND_URL + "/meets/joinCode/" + this.meetId;
            this.axios.get(apiUrl, config).then((response) => {
                this.inviteCode = response.data.code
            });
         
        }
      },
      async copyToClipboard() {
            try {
            await navigator.clipboard.writeText(this.inviteCode);
            this.snackbar = true;
            this.snackbarText = "Invite code copied to clipboard";
            // Notify the user that the text has been copied
            } catch (err) {
            console.error('Failed to copy text: ', err);
            }
        },
    },
  };
  </script>
  
  <style>
  .create-card {
    position: absolute;
  }
  .scroll {
    overflow-y: scroll;
  }
  .action-buttons {
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
  .invite-code .v-input__control  .v-input__slot .v-text-field__slot input{
    letter-spacing: 4px;
  }
  </style>
  