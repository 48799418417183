<template>
  <v-dialog v-model="isOpen" scrollable max-width="700px"  max-height="840px">
    <v-card>
      <v-card-title>Import Competitions</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div
          :class="['upload-container', {'showing-drop': showDrop}]"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <v-icon class="drag-upload-icon">mdi-cloud-upload</v-icon>
          <div class="drag-and-drop">Drag and drop here</div>
          <div class="drag-and-drop-subtext">or</div>
          <div class="browse-files">
            <label class="text-reader">
                Browse files
                <input @click="clearValue" single type="file" @change="loadTextFromFile" ref="fileInput" />
            </label>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="isOpen = false"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: "UploadDialog",
  props: {},
  data() {
    return {
      isOpen: false,
      showDrop: false,
    };
  },
  computed: {},
  updated() {},
  methods: {
    open() {
      this.isOpen = true;
    },
    clearValue(item) {
      if (item.target.value) {
        item.target.value = null;
      }
    },
    dragover(event) {
      event.preventDefault();
      this.showDrop = true
    },
    dragleave(event) {
      this.showDrop = false
    },
    drop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.$emit("fileName", file.name);
      const reader = new FileReader();
      reader.onload = (e) => this.$emit("load", e.target.result);
      reader.readAsText(file);
      this.showDrop = false;
      this.isOpen = false;
    },
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      this.$emit("fileName", file.name);
      const reader = new FileReader();
      reader.onload = (e) => this.$emit("load", e.target.result);
      reader.readAsText(file);
      this.showDrop = false;
      this.isOpen = false;
    },
    reset() {
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
  },
};
</script>

<style>
.upload-container {
  text-align: center;
  /* margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  width: 100%;
  height: 284px;
  border: 2px dotted;
  padding: 60px;
  margin-top: 20px;
  border: 2px dashed grey;
  border-radius: 27px;
}
.showing-drop {
    /* background: #eb740e; */
    border: 4px dashed #eb740e;
    padding: 58px;
}
.drag-upload-icon {
    font-size: 70px !important;
    color: #bbbbbb !important;
}
.drag-and-drop {
  font-size: 26px;
}
.drag-and-drop-subtext {
  font-size: 18px;
  padding: 7px 0 7px;
}
.browse-files {
  font-weight: 600;
  font-size: 18px;
  color: #eb740e !important;
  cursor: pointer;
}
.text-reader {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}
.text-reader input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
</style>
