<template>
  <v-card elevation="2" class="">
    <v-row>
      <v-col class="search-bar-col">
        <v-text-field
          label="Search"
          placeholder="Search for a meet, event, team, or athlete.."
          filled
          rounded
          dense
          v-model="searchText"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="meetIsSelected" class="mt-0">
      <v-col class="d-flex">
        <span class="ml-4 mt-1"> Meet: </span>
        <v-chip
          class="ma-2 mt-0 mb-4"
          close
          color="accent"
          @click:close="clearFilter()"
        >
          {{ selectedMeetName }}
        </v-chip>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-data-table
      v-if="$vssWidth > 550"
      :headers="headers"
      :loading="isLoadingCompetitions"
      loading-text="Loading Events"
      :items="shownCompetitions"
      must-sort
      :options="options"
      class="mt-3"
      mobile-breakpoint="1"
    >
      <template v-slot:item="props">
        <tr @click="clickEvent(props.item)">
          <td v-for="header in headers" :key="header.value">
            <span
              v-if="header.value == 'meetName'"
              class="color-orange cursor-important"
              @click.stop="clickMeet(props.item)"
            >
              {{ props.item.meetName }}
            </span>
            <div
              v-if="header.value == 'competitionName'"
              class="cursor-important"
              
              :class="{ 'pt-3': isCompetitionLive(props.item) }"
            >
              <div class="event-b-link">{{ props.item.competitionName }}</div>
              <div v-if="isCompetitionLive(props.item)" class="pb-3">
                <span
                  class="color-orange"
                  style="font-size: 14px; cursor: pointer"
                  >Live Now </span
                >
                <v-icon
                  color="secondary"
                  small
                  >mdi-broadcast</v-icon
                >
              </div>
            </div>
            <span v-if="header.value == 'competitionDate'">
              {{ props.item.competitionDate | formatDate }}
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:no-data> Loading Events </template>
    </v-data-table>

    <v-list two-line v-if="$vssWidth <= 550 && !isLoadingCompetitions">
      <template v-for="(item, index) in shownCompetitions">
        <v-divider
          v-if="index != 0"
          :key="index"
          :inset="item.inset"
        ></v-divider>

        <v-list-item two-line :key="item.id" @click="clickEvent(item)">
          <v-list-item-content>
            <v-list-item-title class="competition-title">
              {{ item.competitionName }} <v-icon
                  v-if="isCompetitionLive(item)"
                  color="secondary"
                  small
                  >mdi-broadcast</v-icon
                >
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-layout row justify-space-between>
                <v-col>
                  <span
                    class="color-orange competition-subtitle"
                    @click.stop="clickMeet(item)"
                    >{{ item.meetName }}</span
                  >
                </v-col>
                <v-col class="date-left">
                  <span class="date-left">{{
                    item.competitionDate | formatDate
                  }}</span>
                </v-col>
              </v-layout>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-card
      :height="$vssHeight"
      v-if="$vssWidth <= 550 && isLoadingCompetitions"
    >
      <v-container fill-height fluid>
        <v-row align="center" class="loading-row" justify="center">
          <v-col>
            <div class="mb-3">Loading Events</div>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-snackbar v-model="snackbar" :multi-line="true">
      <div v-html="snackbarText"></div>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import _ from "lodash";
import moment from "moment";

export default {
  name: "Events",
  components: {},
  props: {},
  data() {
    return {
      isLoadingCompetitions: false,
      snackbar: false,
      snackbarText: "",
      competitions: [],
      results: [],
      intervalId: "",
      importedFileName: "",
      importedText: "",
      isImporting: false,
      importedData: [],
      searchText: "",
      meetIsSelected: false,
      selectedMeet: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Event",
          value: "competitionName",
        },
        {
          text: "Meet",
          value: "meetName",
        },
        {
          text: "Date",
          value: "competitionDate",
        },
      ];
    },
    options() {
      let options = {
        itemsPerPage: this.$vssHeight > 850 ? 15 : 10,
      };

      return options;
    },
    selectedMeetName() {
      let selectedMeetName = "";

      if (this.meetIsSelected && this.competitions.length) {
        let comp = this.competitions.find(
          (x) => x.meetId === this.selectedMeet
        );
        selectedMeetName = !_.isNil(comp) ? comp.meetName : "";
      }

      return selectedMeetName;
    },
    shownCompetitions() {
      let results = [];
      if (this.searchText != "") {
        for (var competition of this.competitions) {
          let addingComp = false;
          let phrase = this.searchText.toLowerCase();

          if (!_.isNil(competition)) {
            if (!_.isNil(competition.meetName) && competition.meetName.toLowerCase().includes(phrase)) {
              addingComp = true;
            }

            if (!_.isNil(competition.competitionName) && competition.competitionName.toLowerCase().includes(phrase)) {
              addingComp = true;
            }

            if (!_.isNil(competition.items) && JSON.stringify(competition.items).toLowerCase().includes(phrase)) {
              addingComp = true;
            }
          }

          if (addingComp) {
            results.push(competition);
          }
        }
      } else {
        results = this.competitions;
      }

      if (this.meetIsSelected && this.selectedMeet != "") {
        // only show comps where meetId is selectedMeet
        results = results.filter((comp) => comp.meetId == this.selectedMeet);
      }

      return results;
    },
  },
  watch: {
    importedText: function (value) {
      if (value != "") {
        this.startImport();
      }
    },
  },
  async created() {
    this.isLoadingCompetitions = true;
    await this.fetchData();
    this.intervalId = setInterval(this.fetchData, 6000);

    if (!_.isNil(this.$route.query.meetId) && this.$route.query.meetId != "") {
      this.selectedMeet = this.$route.query.meetId;
      this.meetIsSelected = true;
    }
  },
  destroyed() {
    window.clearInterval(this.intervalId);
  },
  methods: {
    clickMeet(item) {
      this.meetIsSelected = true;
      this.selectedMeet = item.meetId;
      this.scrollToTop();
    },
    clearFilter() {
      this.meetIsSelected = false;
      this.selectedMeet = "";
    },
    clickEvent(item) {
      this.$router.push({ name: "event", query: { eventId: item.id } });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    minutesSinceModified(comp) {
      let minutes = 99999;
      const now = new Date();
      const nowMoment =  moment.utc(now)
      const modifiedMoment = moment.utc(comp.modified);

      if (!comp.modified || !modifiedMoment.isValid()) {
        return 999999;
      }

      return nowMoment.diff(modifiedMoment, 'minutes') 
    },
    isCompetitionLive(comp) {
      // Will return if a competition has been updated in last 30 minutes
      let diff = this.minutesSinceModified(comp)

      return diff < 30;
    },
    async fetchData() {
      let apiUrl = process.env.VUE_APP_BACKEND_URL + "/competitions/live";
      this.axios.get(apiUrl, {}).then((response) => {
        this.competitions = response.data;
        setTimeout(() => (this.isLoadingCompetitions = false), 1000);
      });
    },
  },
};
</script>

<style>
.competition-title {
  font-size: 18px;
}
.search-bar-col {
  width: 100%;
  overflow-x: hidden;
  max-width: unset !important;
}
.competition-subtitle {
  font-size: 16px;
}
.event-b-link {
  font-weight: 500;
  height: 100%;
}
.loading-row {
  padding-bottom: 50%;
}
.date-left {
  flex-grow: unset !important;
}
</style>
