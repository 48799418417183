<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="dialog"
      scrollable
     
      max-width="700px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="settings-btn ma-1" outlined fab color="#fefefe" small  v-bind="attrs" v-on="on">
          <v-icon color="#fefefe">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card  height="600px"  style="opacity: 67%;">
        <v-card-title>Scoreboard Settings</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <h3 class="mt-2 mb-1 events-to-show">Font Settings:</h3>
          <v-row class="mt-2 mb-4 ml-2 mr-2">
            <v-col cols="3">
              <v-text-field
                label="Small font size"
                type="number"
                v-model="smallF"
                @change="$emit('changeSmall', smallF)"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Mid font size"
                type="number"
                v-model="midF"
                @change="$emit('changeMid', midF)"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Large font size"
                type="number"
                v-model="largeF"
                @change="$emit('changeLarge', largeF)"
              ></v-text-field>
            </v-col>
            <v-spacer/>
            <v-col cols="2">
               <v-btn
               class="mt-2"
               text>
                 Save
               </v-btn>
             </v-col>
          </v-row>
          <v-divider/>
          <h3 class="mt-2 mb-1 events-to-show">Logo Settings:</h3>
          <v-row class="mb-4 ml-2 mr-2">
            <v-col cols="5">
              <v-switch
                v-model="showLogos"
                label="Show Logos"
                @change="$emit('changeShowLogos', showLogos)"
              ></v-switch>
            </v-col>
            <v-col cols="5">
               <v-text-field
                label="Logo Size (%)"
                type="number"
                v-model="logoSize"
                @change="$emit('changeLogoSize', logoSize)"
              ></v-text-field>
            </v-col>
             <v-col cols="2">
               <v-btn
               class="mt-2"
               text>
                 Save
               </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
         <h3 class="mt-2 mb-4 events-to-show">Events to show:</h3>
         <v-list-item
            v-for="(item) in settings"
            :key="item.port"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title> 
                <div class="title-container">
                  <div class="title-left">
                 {{item.data.eventName}}
                  </div>
                  <div class="title-right">
                    {{item.data.ipAndPort}}
                  </div>
                </div>
                </v-list-item-title
              >

              <div class="inline-container"> 
                <v-row>
                  <v-col>
                    <v-switch
                      class="shown-switch"
                      v-model="item.shown"
                      :disabled="(!item.shown && maxShown)"
                      label="Shown"
                    ></v-switch>
                  </v-col>
                  <v-spacer/>
                  <v-col>
                    <v-select
                      :items="orders"
                      v-model="item.order"
                      label="Order"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="types"
                      v-model="item.type"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <v-divider/>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Settings",
  props: {
    settings: Array,
  },
  data() {
    return {
      dialog: false,
      types: ['Vertical', 'Horizontal'],
      orders: [1,2,3,4,5,6,7,8,9],
      smallF: 80,
      midF: 105,
      largeF: 120,
      showLogos: false,
      logoSize: 70
    };
  },
  methods: {
    save() {
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
    maxShown() {
      let shown = [];

      if (this.settings && this.settings.length > 0) {
        shown =  this.settings.filter((sc) => sc.shown == true)
      }

      return shown.length >= 4;
    }
  }
};
</script>

<style scoped>
  .settings-btn {
    position: absolute;
    bottom: 10px;
    right: 8px;
  }
  .events-to-show {
    font-weight: 500;
    color: #f27820;
  }
  .title-left {
    float: left;
  }
  .title-right {
    float: right;
    font-size: 12px;
    color: grey
  }

</style>
