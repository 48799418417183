<template>
  <div style="text-align: left" class="pa-6">
    <h2 class="fixed-header color-gray" style="text-align: center">
      Terms & Conditions
    </h2>
    <br />

    <p>
      These Terms & Conditions are made and entered into by and between you and
      Drew Klein. "<strong>Drew Klein</strong>," "<strong>we</strong>,"
      "<strong>us</strong>," or "<strong>our</strong>" refers to Drew Klein, the
      owner and creator of FieldEvents. "<strong>You</strong>," or
      "<strong>your</strong>," refers to you, a user of the Site/App or parent
      or legal guardian (over the age of 18) if such user is a minor in his or
      her state of residence. <strong>FieldEvents</strong>, refers to both the
      FieldEvents App and Site. By downloading or using the FieldEvents app,
      these terms will automatically apply to you. Therefore you should make
      sure that you read them carefully before using the app or site. You're not
      allowed to copy or modify FieldEvents or our trademarks in any way. You're
      not allowed to attempt to extract the source code of FieldEvents, or make
      derivative versions. FieldEvents itself, it's trademarks, copyrights,
      database rights and other intellectual property rights related to it,
      still belong to us.
    </p>
    <p>
      We are committed to ensuring that FieldEvents is as useful and efficient
      as possible. For that reason, we reserve the right to make changes to the
      app or to charge for its services, at any time and for any reason. We will
      never charge you for the app or its services without making it very clear
      to you exactly what you're paying for.
    </p>
    <p>
      You should be aware that there are certain things that we will not take
      responsibility for. Certain functions of FieldEvents will require the app
      to have an active internet connection. The connection can be Wi-Fi, or
      provided by your mobile network provider, but we cannot take
      responsibility for the app not working at full functionality if you don't
      have access to the internet, and you don't have any of your data allowance
      left.
    </p>
    <p>
      If you're using the FieldEvents outside of an area with Wi-Fi, you should
      remember that your terms of the agreement with your mobile network
      provider will still apply. As a result, you may be charged by your mobile
      provider for the cost of data for the duration of the connection while
      accessing the app, or other third party charges. By using FieldEvents,
      you're accepting responsibility for any such charges, including roaming
      data charges if you use FieldEvents outside of your home territory (i.e.
      region or country) without turning off data roaming. If you are not the
      bill payer for the device on which you're using the app, please be aware
      that we assume that you have received permission from the bill payer for
      using the app.
    </p>
    <p>
      Along the same lines, we cannot take responsibility for the condition of
      your device. You are responsible for ensuring that your device stays
      charged, has available space, and an active internet connection in order
      to use FieldEvents.
    </p>
    <p>
      With respect to our responsibility for your use of the app, while using
      FieldEvents, it's important to bear in mind that although we endeavor to
      ensure that it is updated and correct at all times, we do rely on third
      parties to provide information to us so that we can make it available to
      you. We accept no liability for any loss, direct or indirect, you
      experience as a result of relying wholly on this functionality of
      FieldEvents.
    </p>
    <p>
      You understand that the FieldEvents is susceptible to bugs, although we
      will do our best to eliminate bugs present in FieldEvents. You acknowledge
      and agree that we are not responsible for loss of data, or progress of a
      competition due to a potential bug.
    </p>
    <p>
      We have done our best to develop FieldEvents to follow the rules of track
      and field found on USATF.org. You acknowledge that these rules are
      susceptible to change and that the app logic and rules may not match the
      rules of your particular organization. You also acknowledge and accept
      that the logic and rules of the app may not match the rules set forth by
      <a class="link-orange" href="https://www.usatf.org/">USATF.org</a>.
    </p>
    <p>
      At some point, we may wish to update the FieldEvents app. The FieldEvents
      app is currently available on iOS version 15 and up. The requirements for
      the app (and for any additional versions of the app) may change. You may
      need to download the updates if you want to keep using the FieldEvents
      app. We do not promise that updates to the app will always be relevant to
      you and/or works with the iOS version that you have installed on your
      device. However, you promise to always accept updates to the application
      when offered to you. We may wish to stop providing the app, and may
      terminate use of it at any time without giving notice of termination to
      you. Unless we tell you otherwise, upon any termination, (a) the rights
      and licenses granted to you in these terms will end-- (b) you must stop
      using the app, and (if needed) delete it from your device.
    </p>
    <p style="text-align: center" class="pt-3">
      <strong>Changes to This Terms and Conditions</strong>
    </p>
    <p>
      We may update our Terms and Conditions from time to time. Thus, you are
      advised to review this page periodically for any changes. We will notify
      you of any changes by posting the new Terms and Conditions on this page.
    </p>
    <p style="text-align: center">
      These terms and conditions are effective as of 2021-01-09
    </p>
    <p style="text-align: center"><strong>Contact Us</strong></p>
    <p style="text-align: center">
      If you have any questions or suggestions about the Terms & Conditions, you
      can contact us by using this
      <a class="link-orange" href="/about">contact form</a>.
    </p>

    <br />
  </div>
</template>

<script>
export default {
  name: "Terms",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},

  methods: {},
};
</script>

<style>
.container-app {
  max-width: 40rem;
  margin: auto;
  padding-top: 10px;
}
</style>
