<template>
  <v-dialog v-model="competitorDetailsDialog" scrollable max-width="380px">
    <v-card>
      <v-card-title>
        <div>{{ competitorName }}</div>
        <v-spacer></v-spacer>
        <div>{{ competitorPlace }}</div>
      </v-card-title>
      <v-card-subtitle class="team-subtitle">{{
        competitorTeam
      }}</v-card-subtitle>

      <v-card-text>
        <div v-if="type == 0">
          <template v-for="(item, index) in competitorMarks">
            <v-divider :key="index"></v-divider>
            <v-list-item :key="item.height" two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="mark-row-head">
                    <div class="mark-row-head float-left">
                      {{ splitMark(item.height, 0) }}
                    </div>
                    <div class="mark-row-head result-mark float-right "  v-bind:style="marktext(item.result)">
                      {{ item.result }} 
                    </div>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  splitMark(item.height, 1)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>

        <div v-if="type == 1">
          <template v-for="(item, index) in competitorMarks">
            <v-divider :key="index"></v-divider>
            <v-list-item :key="item.attempt" two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="mark-row-head">
                    <div class="mark-row-head float-left">
                      {{ splitMark(item.result, 0) }}
                    </div>
                    <div class="mark-row-head result-mark2 float-right">
                      Attempt {{ item.attempt.split("_")[1] }}
                    </div>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  splitMark(item.result, 1)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="competitorDetailsDialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";

export default {
  name: "CompetitorDetails",
  props: {
    details: Object,
    selectedCompetitor: String,
    tableData: Array,
    type: Number,
  },
  data() {
    return {
      competitorDetailsDialog: false,
    };
  },
  computed: {
    competitorDetails() {
      if (this.tableData.length) {
        return this.tableData.find(
          (element) => element.name == this.selectedCompetitor
        );
      } else {
        return null;
      }
    },
    competitorName() {
      return this.selectedCompetitor;
    },
    competitorTeam() {
      if (!_.isNil(this.competitorDetails)) {
        return this.competitorDetails.team;
      } else {
        return "";
      }
    },
    competitorPlace() {
      if (!_.isNil(this.competitorDetails)) {
        return this.$options.filters.ordinalSuffix(
          this.competitorDetails.place
        );
      } else {
        return "";
      }
    },
    competitorMarks() {
      var arr = [];
      var ob = {};
      if (!_.isNil(this.competitorDetails) && this.type == 0) {
        for (ob in this.competitorDetails) {
          if (
            !["name", "team", "place", "_cellVariants", "status"].includes(
              ob
            ) &&
            this.competitorDetails[ob] != ""
          ) {
            let item = {
              height: ob,
              result: this.competitorDetails[ob],
            };
            arr.push(item);
          }
        }

        arr = _.orderBy(arr, ["height"], ["desc"]);
        return arr;
      } else if (!_.isNil(this.competitorDetails) && this.type == 1) {
        for (ob in this.competitorDetails) {
          if (
            ![
              "name",
              "team",
              "place",
              "_cellVariants",
              "status",
              "flight",
            ].includes(ob) &&
            this.competitorDetails[ob] != ""
          ) {
            let item = {
              attempt: ob,
              result: this.competitorDetails[ob],
            };
            arr.push(item);
          }
        }

        arr = _.orderBy(arr, ["height"], ["desc"]);
        return arr;
      } else {
        return [];
      }
    },
  },
  updated() {},
  methods: {
    open() {
      this.competitorDetailsDialog = true;
    },
    splitMark(mark, index) {
      return mark.split("--")[index];
    },
    marktext(value) {
      let markColor = "#00000";

      switch (value) {
        case "O":
        case "PPO":
        case "XO":
        case "XXO":
        case "PXO":
        case "PO":
          markColor = "#1dad38";
          break;
        case "PPX":
        case "PX":
        case "XXX":
        case "XX":
        case "X":
        case "F":
          markColor = "#ad1d1d";
          break;
        case "XXP":
        case "XPP":
        case "PPP":
        case "P":
          markColor = "#1d63ad";
          break;
        default:
          markColor = "#1dad38";
          break;
      }

      return {
        color: markColor,
      };
    },
  },
};
</script>

<style>
.team-subtitle {
  padding-bottom: 2px !important;
}
.mark-row-head {
  display: inline;
}
.result-mark {
  margin-left: 120px;
  font-size: 20px;
}

.result-mark2 {
  margin-left: 86px;
  font-size: 16px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
</style>
