var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canSeeAllCompetitions)?_c('v-switch',{staticClass:"show-all",attrs:{"label":"Show All"},on:{"change":_vm.showAllToggled},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}):_vm._e(),_c('div',{staticClass:"import"},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.$refs.uploadDialog.open()}}},[_vm._v(" Import ")])],1),_c('div',{staticClass:"create"},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":_vm.createCompetition}},[_vm._v(" Create ")])],1),_c('v-layout',{staticStyle:{"width":"100%"},attrs:{"column":"","fill-height":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.competitions,"loading":!_vm.hasFetched,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":true,"disable-pagination":"","must-sort":"","item-key":"id","no-data-text":"No competitions added"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":function (item) { return _vm.clickLink(item); }},scopedSlots:_vm._u([{key:"item.competitionDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateNew")(item.competitionDate))+" ")])]}},{key:"item.meetName",fn:function(ref){
var item = ref.item;
return [(item.meetName)?_c('div',[_vm._v(" "+_vm._s(item.meetName)+" ")]):_c('div',[_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.associateMeet(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}},{key:"item.compCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"100px"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.competitorCount(item))+" "),(_vm.status(item) == 'Not started')?_c('v-icon',{staticClass:"ml-4 mb-1",attrs:{"color":"secondary","size":"24"},on:{"click":function($event){$event.stopPropagation();return _vm.editCompetitors(item)}}},[_vm._v("mdi-pencil")]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.status(item))+" ")])]}},{key:"item.results",fn:function(ref){
var item = ref.item;
return [(_vm.status(item) == 'Finished' && _vm.hasResults(item))?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadResults(item, 'pdf')}}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1):_vm._e(),(_vm.status(item) == 'Finished' && _vm.hasResults(item))?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadResults(item, 'csv')}}},[_c('csv-icon')],1):_vm._e()]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":item.isActive == 1 ? 'secondary' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisibility(item)}}},[(item.isActive == 1)?_c('v-icon',[_vm._v("mdi-eye")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-off")])],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.isActive == 0,"outlined":"","x-small":"","fab":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.clickLink(item)}}},[_c('v-icon',[_vm._v("mdi-link")])],1)]}},{key:"item.qr",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.isActive == 0,"outlined":"","x-small":"","fab":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.getQrCode(item)}}},[_c('v-icon',[_vm._v("mdi-qrcode")])],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"multi-line":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackbarText)}})])],1)],1)],1),_c('CreateCompetition',{ref:"createCompetition",attrs:{"userObject":_vm.userObject,"isImporting":_vm.isImporting,"importedData":_vm.importedData},on:{"updateCompetitions":_vm.fetchData,"resetCreate":_vm.resetCreate}}),_c('upload-dialog',{ref:"uploadDialog",on:{"load":function($event){_vm.importedText = $event},"fileName":_vm.fileName}}),_c('associate-meet',{ref:"associateMeet",attrs:{"userObject":_vm.userObject,"competitionId":_vm.selectedCompetitionId},on:{"associatedMeet":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }