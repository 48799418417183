var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-table"},[_c('competitor-details',{ref:"competitorDetailsModal",attrs:{"tableData":_vm.tableData,"selectedCompetitor":_vm.selectedCompetitor,"type":_vm.type}}),_c('v-row',[(_vm.$vssWidth > 900)?_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:['competitor-up-container', {'mb-8': _vm.isPrinting}]},[_c('div',{staticClass:"small-body"},[_vm._v("Event details:")]),_c('div',{staticClass:"competitor-team",style:([
            { 'background-color': '#343a40' },
            { color: 'white' } ])},[_c('span',{staticClass:"competitor-name"},[_vm._v(_vm._s(_vm.competitionName))]),_c('span',[_vm._v(_vm._s(_vm.meetName))])])])]):_vm._e(),_c('v-col',[((_vm.attemptsRemaining > 0 && _vm.competitorUp != 'Competition Ended') && !_vm.isPrinting)?_c('v-row',{staticClass:"mb-1"},[_c('v-col',[_c('div',{staticClass:"competitor-up-container"},[_c('div',{staticClass:"small-body"},[_vm._v("Competitor up:")]),_c('div',{staticClass:"competitor-team",style:([
                { 'background-color': _vm.teamColor },
                { color: _vm.foregroundColor } ])},[_c('span',{staticClass:"competitor-name"},[_vm._v(_vm._s(_vm.competitorUp))]),_c('span',[_vm._v(_vm._s(_vm.competitorTeam))])]),_c('v-alert',{staticClass:"attempts-remaining-alert",attrs:{"dense":"","text":"","color":_vm.attemptsRemainingColor}},[_c('strong',[_vm._v(_vm._s(_vm.attemptsRemaining))]),_vm._v(" "+_vm._s(_vm.attemptsRemaining > 1 ? "attempts" : "attempt")+" remaining ")]),_c('div',{staticClass:"small-body"})],1)])],1):(!_vm.isPrinting)?_c('v-row',{staticClass:"mb-1"},[_c('v-col',[_c('div',{staticClass:"competition-ended-container"},[_c('span',{staticClass:"competitor-name"},[_vm._v(_vm._s(_vm.msg == "not_started" ? "Waiting for competition to start" : "Competition Ended"))])])])],1):_vm._e()],1)],1),_c('v-layout',{staticStyle:{"width":"98vw"},attrs:{"column":""}},[_c('v-flex',[_c('v-data-table',{class:[
          'elevation-1',
          {'event-table-hide-place': _vm.hidePlace},
          { verticalTable: _vm.type == 0 },
          { horizontalTable: _vm.type == 1 } ],attrs:{"id":"event-table","headers":_vm.headers,"items":_vm.tableData,"fixed-header":"","mobile-breakpoint":"1","disable-pagination":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","must-sort":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,class:['nowrap', header.class],attrs:{"scope":"col"}},[(header.isPrelim || header.isFinal)?[_c('div',{staticClass:"align-center",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(header.isPrelim ? 'Prelims' : 'Finals'))]),_c('div',{staticClass:"align-center"},[_vm._v(_vm._s(header.text))])]:(header.isHeight)?[_c('div',{staticClass:"align-center"},[_vm._v(_vm._s(header.text))]),_c('div',{staticClass:"align-center",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(header.secondaryText))])]:[_vm._v(" "+_vm._s(header.text)+" ")]],2)}),0)])]}},{key:"item",fn:function(props){return [_c('tr',{class:{ 'current-competitor': _vm.isCompUp(props.item) },on:{"click":function($event){return _vm.clickPerson(props.item)}}},_vm._l((_vm.headers),function(header){return _c('td',{key:header.value,class:[{'start-of-finals-header': header.class == 'start-of-finals-header'}]},[(header.value == 'place')?_c('span',{staticClass:"color-orange cursor-important"},[_vm._v(" "+_vm._s(props.item.place)+" ")]):(header.value == 'name')?_c('div',{staticClass:"cursor-important pt-2 pb-2"},[_c('div',{staticClass:"competitor-name-text"},[_vm._v(" "+_vm._s(props.item.name)+" "),(!_vm.isPrinting)?_c('v-icon',{attrs:{"x-small":"","color":"accent"}},[_vm._v("mdi-information-outline")]):_vm._e()],1),(props.item.team != '')?_c('div',{staticClass:"team-text",style:([
                    {
                      'background-color': _vm.getCompetitorColor(props.item.name),
                    },
                    { color: _vm.getCompetitorForegroundColor(props.item.name) } ])},[_vm._v(" "+_vm._s(props.item.team)+" ")]):_vm._e()]):(
                  header.value.includes('_') && props.item[header.value]
                )?_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mark-primary-unit align-center",class:_vm.horizontalMarkClass(props.item[header.value])},[_vm._v(" "+_vm._s(props.item[header.value].split("--")[0])+" ")]),_c('div',{staticClass:"mark-secondary-unit align-center"},[_vm._v(" "+_vm._s(props.item[header.value].split("--")[1])+" ")])]):(header.value == 'flight')?_c('span',[_vm._v(" "+_vm._s(props.item[header.value])+" ")]):_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{class:_vm.verticalMarkClass(props.item[header.value])},[_vm._v(" "+_vm._s(props.item[header.value])+" ")])])])}),0)]}},{key:"no-data",fn:function(){return [_vm._v(" No results found ")]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }