<template>
    <div class="faq-container">
      <h1>Frequently Asked Questions</h1>
      <ul>
        <li>
            <h3>General Information</h3>
            <p class="ml-5"><strong>What is FieldEvents?</strong><br>
            FieldEvents is an iOS app designed for managing track and field events. It offers features like importing competitions, live results, and managing athlete groups.</p>
        </li>
        
        <li>
            <h3>Compatibility</h3>
            <p class="ml-5"><strong>Is FieldEvents compatible with Hy-tek Meet Manager 6.0?</strong><br>
            FieldEvents does support <a color="primary" href="https://fieldevents.net/Exporting%20Entries%20from%20Hy-Tek.pdf" target="blank">importing competitions from Hy-tek Meet Manager</a>. However, there's currently no direct way to export results back to Meet Manager.</p>

            <p class="ml-5"><strong>What other files can be imported?</strong><br>
            FieldEvents supports importing lynx.evt files, or <a href="https://fieldevents.net/" target="blank">CSV files like this</a> for quickly creating events and athletes.</p>

            <p class="ml-5"><strong>What if I have a different file to import?</strong><br>
            If you have a different file format, please send us a message from the <a href="about">About</a> page and we will work to get it supported!</p>

            <p class="ml-5"><strong>Can I use FieldEvents on Android?</strong><br>
            FieldEvents is currently only available on iOS devices. However, we are working on an Android version. Live results for events can be accessed on any browser from the <a href="events">Events</a> page</p>
        </li>

        <li>
            <h3>Internet Connection</h3>
            <p class="ml-5"><strong>Is FieldEvents a cloud service? Does each device require an internet connection?</strong><br>
            FieldEvents requires an internet connection for the live results feature. However, basic functionality, including event management, can be accessed offline.</p>
        </li>

        <li>
            <h3>Pricing and Subscriptions</h3>
            <p class="ml-5"><strong>What is the cost of using FieldEvents?</strong><br>
            FieldEvents offers a subscription-based model. The FieldEvents Pro subscription costs $15 per year per account.</p>

            <p class="ml-5"><strong>Do I need a subscription for each device?</strong><br>
            No, you only need one subscription regardless of the number of devices. See the following scenarios for subscription management:</p>
            
            <ul>
                <li><strong>Scenario 1: Single Device Subscription</strong><br>
                <ul>
                    <li>Purchase a single subscription on one device.</li>
                    <li>Use this device to create meets and events.</li>
                    <li>Share the meet invite code with officials to run events on their devices.</li>
                    <li>Officials' devices do not require a subscription to participate.</li>
                </ul>
                </li>

                <li><strong>Scenario 2: Multiple Devices with Shared Apple ID</strong><br>
                <ul>
                    <li>Purchase the subscription on one device using your Apple ID.</li>
                    <li>On other devices, use the "restore purchases" feature to activate the subscription.</li>
                    <li>Ensure all devices are signed in with the same Apple ID.</li>
                    <li>Once activated, sign in to the same FieldEvents account on all devices to sync event data.</li>
                </ul>
                </li>
            </ul>
        </li>

        <li>
            <h3>Additional Information</h3>
            <p class="ml-5"><strong>Can FieldEvents be used offline?</strong><br>
            Yes, FieldEvents can be used offline for basic event management.</p>

            <p class="ml-5"><strong>Are there any trial options available?</strong><br>
            Yes, FieldEvents offers a free one-month trial period for the FieldEvents Pro subscription.</p>

            <p class="ml-5"><strong>How can I generate meet invite codes?</strong><br>
            Meet invite codes can be generated within the app from the meet details page. This code can then be shared with "officials" and ran on another device with the FieldEvents app.</p>

            <p class="ml-5"><strong>Can results be exported from FieldEvents?</strong><br>
            Yes, results can be exported as CSV or PDF files from both the app and the web application.</p>
        </li>
    </ul>
    </div>
   
  </template>
  
  <script>

  export default {
    name: "Faq",
  };
  </script>
  
  <style>
    .faq-container {
        max-width: 800px;
        margin: 0 auto;
        margin-top: 60px;
        padding: 20px;
        text-align: left;

        h1 {
            text-align: center;
        }

        h3 {
            margin-bottom: 10px;
            margin-top: 20px;
        }

        a {
            color: #ff7d14 !important;
            font-weight: 500;
            text-decoration: none;
        }
        
    }
  </style>
  