<template>
  <v-app id="componentId">
    <v-app-bar app color="primary" dark v-if="!onExcludedPage && !hideToolbar">
      <div class="d-flex align-center nav-bar-top">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo384.png"
          transition="scale-transition"
          width="300"
        />
        <template v-if="$vssWidth >= 820">
          <router-link
            :class="{ 'color-white-bold': page == '/' }"
            to="/"
            class="nav-link"
            >Events</router-link
          >
          <router-link
            :class="{ 'color-white-bold': page == '/app' }"
            to="/app"
            class="nav-link"
            >App</router-link
          >
          <router-link
            :class="{ 'color-white-bold': page == '/about' }"
            to="/about"
            class="nav-link"
            >About</router-link
          >
          <router-link
           :class="{ 'color-white-bold': page == '/faq' }"
            to="/faq"
            class="nav-link"
            >FAQ</router-link
          >
          <router-link
            :class="{ 'color-white-bold': page == '/account' }"
            to="/account"
            class="nav-link"
            >Account</router-link
          >
          <v-spacer></v-spacer>

          <span
            v-if="isSignedIn"
            to="/#"
            @click="signOut"
            class="nav-link"
            style="cursor: pointer"
            >Sign out</span
          >
        </template>
        <template v-else>
          <v-spacer></v-spacer>
          <v-menu bottom class="menu-nav" :offset-y="true" v-if="!hideToolbar">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item to="/">
                  <router-link
                    :class="[{ 'color-gray-bold': page == '/' }, 'color-gray']"
                    to="/"
                    >Events</router-link
                  >
              </v-list-item>
              <v-list-item to="/app">
                  <router-link
                    :class="[
                      { 'color-gray-bold': page == '/app' },
                      'color-gray',
                    ]"
                    to="/app"
                    >App</router-link
                  >
              </v-list-item>
              <v-list-item to="/about">
                  <router-link
                    :class="[
                      { 'color-gray-bold': page == '/about' },
                      'color-gray',
                    ]"
                    to="/about"
                    >About</router-link
                  >
              </v-list-item>
              <v-list-item to="/faq">
                  <router-link
                    :class="[
                      { 'color-gray-bold': page == '/faq' },
                      'color-gray',
                    ]"
                    to="/faq"
                    >FAQ</router-link
                  >
              </v-list-item>
              <v-list-item to="/account">
                  <router-link
                    :class="[
                      { 'color-gray-bold': page == '/account' },
                      'color-gray',
                    ]"
                    to="/account"
                    >Account</router-link
                  >
              </v-list-item>
              <v-list-item
                v-if="isSignedIn"
                style="cursor: pointer"
                @click="signOut"
              >
                <v-list-item-title style="cursor: pointer">
                  Sign Out
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <router-view :class="['view', {'no-toolbar': hideToolbar}]"></router-view>
  </v-app>
</template>
<script>
import _ from "lodash";
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";

export default {
  name: "app",
  components: {},

  data() {
    return {};
  },
  computed: {
    isSignedIn() {
      return this.$store.state.isSignedIn;
    },
    page() {
      return this.$route.path;
    },
    activePage() {
      return "/" + _.last(window.location.href.split("/"));
    },
    onExcludedPage() {
      return ["info", "doane"].includes(this.$route.name);
    },
    hideToolbar() {
      return window.location.href.includes("hideToolbar=1") || this.$store.state.isPrinting;
    },
  },
  methods: {
    async signOut() {
      await Auth.signOut();
      this.$store.commit("setIsSignedIn", false);
    },
  },
};
</script>

<style>
.nav-link {
  padding: 22px;
  color: white !important;
  text-decoration: none;
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.nav-bar-top {
  display: flex !important;
  /* display: inline-flex; */
  height: 70px;
}

.view {
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  margin-top: 70px;
  padding: 8px 0 8px 0;
}
.no-toolbar {
  margin-top: 8px !important;
}

.menu-nav {
}

tr {
  text-align: left;
}

.color-white {
  color: white;
}

.color-orange {
  color: #eb740e !important;
  
  text-decoration: none !important;
}

.link-orange {
  color: #eb740e !important;
  text-decoration: none !important;
  font-weight: 700;
  cursor: pointer;
}

.cursor-important {
  cursor: pointer !important;
}

.color-white-bold {
  color: white;
  font-weight: 700;
}

.color-gray {
  color: #515457;
  text-decoration: none !important;
}

.color-light-gray {
  color: rgba(0, 0, 0, 0.38);
}

.color-gray-bold {
  color: #515457;
  font-weight: 700;
}

.small-body {
  font-size: 13px;
}

#app > div.v-application--wrap > header > div {
  display: block;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  margin-top: -8px;
}
#app > div > header {
  height: 62px !important;
}

#app > div > header > div > div.d-flex.align-center.nav-bar-top > div {
  margin-top: -10px;
}

.Input__input___2Sh1s {
  /* width: 95%!important; */
}

/* body {
  margin: 0;
}
.sign-in {
  margin-top: 68px;
}
.mobile-message {
  margin-top: 100px;
  display: none;
}

@media only screen and (max-width: 600px) {
  .sign-in {
    display: none;
  }
  .mobile-message {
    display: block;
  }
}


tr {
  text-align: left;
}
#app {
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.signout {
  background-color: #ededed;
  margin: 0;
  padding: 11px 0px 1px;
}

.nav-link {
  color: white !important;
  text-decoration: none;
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 8px;
}
.inActive {
  color: rgba(255, 255, 255, 0.5) !important;
}

#app > div > div.sign-in > div > div > div.error {
  background-color: white !important;
  color: red;
} */

/* Sign in button */
#app
  > div
  > div
  > div.sign-in
  > div
  > div
  > div.Section__sectionFooter___1QlDn
  > span {
  margin: auto;
}

/* Sign in error div */
#app > div > div > div.container > div > div > div.error {
  margin-top: 20px;
  background-color: white !important;
  text-align: center;
  color: red;
}
</style>
